import { FC, Fragment, useState, useEffect } from "react"
import styled from "styled-components"
import { Menu } from "@lib/types"

import { useUI, useTracking } from "@hooks/index"
import { MenuButton, Submenu, MenuItem, Link } from "@components/common"
import Chevron from "@svg/chevron.svg"
import { useRouter } from "next/router"


interface Props {
  menus: Menu[]
}

export const MenuNav: FC<Props> = ({ menus }) => {
  const { openNavSidebar } = useUI()
  const { track } = useTracking()
  const [hovered, setHovered] = useState(false)

  const router = useRouter()

  useEffect(() => {
    if (hovered) {
      track("Nav Hovered")
    }
  }, [hovered])

  useEffect(() => {
    setHovered(false)
  }, [router.asPath])

  return (
    <StyledMenuNav>
      <MenuButton
        type="menu"
        onClick={() => {
          openNavSidebar()
          track("Nav Opened", { category: "Menu level 1" })
        }}
      />
      <MenuList>
        {menus.map(({ link, menuItemsCollection, image, button }) =>
          menuItemsCollection.items.length > 0 ? (
            <Fragment key={link.sys.id}>
              <MenuItemWithSubmenu
                onMouseEnter={() => {
                  setHovered(true)
                }}
                onMouseLeave={() => setHovered(false)}
                $hovered={hovered}
              >
                <Link
                  link={link}
                  trackingProperties={{ category: "Menu level 1" }}
                />
                <ChevronIcon />
              </MenuItemWithSubmenu>
              <SubmenuWrapper onMouseEnter={() => {
                  setHovered(true)
                }}
                onMouseLeave={() => setHovered(false)}
                $hovered={hovered}>
                <Submenu
                  menuItems={menuItemsCollection.items}
                  image={image}
                  button={button}
                />
              </SubmenuWrapper>
            </Fragment>
          ) : (
            <MenuItem key={link.sys.id}>
              <Link link={link} />
            </MenuItem>
          )
        )}
      </MenuList>
    </StyledMenuNav>
  )
}

interface StyleProps {
  $hovered?: boolean
}

const StyledMenuNav = styled.nav`
  display: flex;
  align-items: center;
  flex: 1 1;
  height: 100%;
`

const MenuList = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`

const MenuItemWithSubmenu = styled(MenuItem)<StyleProps>`
  ${({ $hovered }) => ($hovered ? "+div { display: block };" : "")}
`

const ChevronIcon = styled(Chevron)`
  margin: 0 0 0.1rem 1.1rem;
`

const SubmenuWrapper = styled.div<StyleProps>`
  display: none;
  ${({ $hovered }) => ($hovered ? "display: block;" : "")}
`
