export * from "./localStorage"
export * from "./sessionStorage"
export * from "./debounce"
export * from './cache'

import { ContentfulProduct, ContentfulLink } from "@lib/types"
import type { SelectedOption } from "shopify-storefront-api-typings"
import PasswordValidator from "password-validator"

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const camelize = (str: string) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, "")
}

export const setActualLocaleCookie = (locale: string | undefined) => {
  if (!locale) return
  document.cookie = `VISITOR_LOCALE=${locale};max-age=${60*60*24*90}`
}

// use inside useEffect as document may not be loaded yet
export const getActualLocaleCookie = () => document?.cookie?.split('; ').find(row => row.startsWith(`VISITOR_LOCALE=`))?.split('=')[1]

export const setChosenLocaleCookie = (locale: string | undefined) => {
  if (!locale) return
  document.cookie = `CHOSEN_LOCALE=${locale};max-age=${60*60*24*90}`
}

export const setAnnouncementSeenCookie = () => {
  document.cookie = `ANNOUNCEMENT_SEEN=true;max-age=${60*60*24*90}`
}

// use inside useEffect as document may not be loaded yet
export const getChosenLocaleCookie = () => document?.cookie?.split('; ').find(row => row.startsWith(`CHOSEN_LOCALE=`))?.split('=')[1]
export const getAnnouncementSeenCookie = () => document?.cookie?.split('; ').find(row => row.startsWith(`ANNOUNCEMENT_SEEN=`))?.split('=')[1]

export const getColorValue = (selectedOptions: SelectedOption[]) =>
  selectedOptions.filter(({ name }) => name === "Color")[0]?.value

export const getOptionValue = (selectedOptions: SelectedOption[]) =>
  selectedOptions?.filter(({ name }) => name === "Size")[0]?.value ||
  selectedOptions?.filter(({ name }) => name === "Sizes")[0]?.value ||
  selectedOptions?.filter(({ name }) => name === "Color")[0]?.value ||
  selectedOptions?.[0].value

export const getProductFilters = (products: ContentfulProduct[]) => {
  const allColors = products.flatMap(({ color }) => (color?.name ? color : []))

  const uniqColors = allColors.filter(
    (v, i, a) => a.findIndex((t: { name: string }) => t.name === v.name) === i
  )

  const allThickness = products.flatMap(
    ({ fabricThickness }) => fabricThickness?.name || []
  )

  const allTextures = products.flatMap(
    ({ fabricTexture }) => fabricTexture?.name || []
  )

  const allSizes = products
    .flatMap(({ sizesCollection }) => [...sizesCollection.items])
    .map(({ name }) => name)

  const getUniqFilters = (arr: string[]) =>
    arr.filter((v, i, a) => a.findIndex(t => t === v) === i)

  const filters = {
    color: uniqColors,
    fabricThickness: getUniqFilters(allThickness),
    fabricTexture: getUniqFilters(allTextures),
    size: getUniqFilters(allSizes),
  }

  return filters
}

export const passwordSchema = new PasswordValidator()
passwordSchema.is().min(8).is().max(100).has().lowercase().has().uppercase()

export function localize(root: any, languages: string[]): any {
  if (Array.isArray(root)) {
    return root.map(v => localize(v, languages))
  } else if (typeof root == "object") {
    return Object.keys(root).reduce((result: any, key: string) => {
      const lang: any = languages.find(lang => root[key][lang])
      result[key] = root[key][lang]
      return result
    }, {})
  }
  return root
}

export const getSlugFromLink = (link: ContentfulLink) => {
  if (link.href) {
    return link.href
  }

  if (link.entry) {
    if (link.entry?.__typename === "InfoPage") {
      return `/pages/${link.entry.slug}`
    }
    if (link.entry?.__typename === "Product") {
      return `/products/${link.entry.slug}`
    }

    return `/${link.entry.slug}`
  }

  return "/"
}
