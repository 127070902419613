import React, {useState} from 'react';
import styled from 'styled-components';
import { SpinningLoader } from "@components/ui"
import { useReturnItems } from '@hooks/account';
import { useRouter } from 'next/router';
import { decode } from "shopify-gid"

export const ReturnItemLink = ({node, loading, setLoading}: any) => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState("");
    const returnItems = useReturnItems();
    const { locale } = useRouter();
    //console.log(node);
    const handleReturnClick = async (node: any) => {
        setLoader(true);
        setLoading(true);
        const getOrderLocation = await fetch("/api/get-order-location-id", {
          method: "POST",
          body: JSON.stringify({
            orderId: decode(node.id).id,
          })
        })
        if(getOrderLocation?.status !== 200) {
          const res = await returnItems(node);
          if(typeof res === "string") {
              setError(res);
          }
          setLoader(false);
          setLoading(false);
          return;
        }
        const locationRes = await getOrderLocation.json();
        const fullLocationId = locationRes?.locationId ? locationRes.locationId : "";
        const locationId = fullLocationId.split("/").pop();
        if(locationId == "60894117984") {
          window.open("https://tracking.controlport.co.uk/e9ab6870/1391/return/099bcd", "_blank");
        } else {
          const res = await returnItems(node);
          if(typeof res === "string") {
              setError(res);
          }
        }
        setLoader(false);
        setLoading(false);
      }

    const isOnlyGiftCardsOrder = (node: any) => node.lineItems.edges.map((edge: any) => edge.node.title).every((value: string) => value == "Gift e-Card");

    return (
        <>
          <AnchorWrapper>
          <Anchor
            as="button"
            onClick={() => handleReturnClick(node)}
            disabled={!!node.cancelReason || isOnlyGiftCardsOrder(node) || loading}
          >
          Return items
          </Anchor>
          {loader && <SpinningLoaderWrapper><SpinningLoader color={"#555"}/></SpinningLoaderWrapper>}
          </AnchorWrapper>
          {error && <Error>{error}</Error>}
        </>
    );
};

const AnchorWrapper = styled.div`
    position: relative;
    display: flex;
    width: 130px;
    justify-content: flex-start;
`

const Error = styled.div`
    color: red;
    font-size: 13px;
    margin-top: 5px;
    white-space: pre-wrap;
`

const SpinningLoaderWrapper = styled.div`
    position: relative;
    *{
        top: 50%;
        right: 50%;
        transform: translate(-50%, -50%);
    }
    margin-left: 5px;
`

const Anchor = styled.a`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.5rem;
  }
`