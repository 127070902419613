import styled from "styled-components"

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding-right: 3.9rem;
  height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  line-height: 2.4rem;
  font-weight: 500;
  cursor: pointer;

  > button {
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-family: ${({ theme }) => theme.font.primary};
    color: ${({ theme }) => theme.color.white};
    font-size: 1.2rem;
    line-height: 2.4rem;
    font-weight: 500;
    cursor: pointer;
  }

  &:last-child {
    padding: 0;
  }
`
