import { FC } from "react"
import styled from "styled-components"
import { LazyMotion, m } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

import { Portal } from "@components/common"

import CloseIcon from "@svg/cross-thin.svg"

interface Props {
  open: boolean
  onClose: () => void
}

export const Modal: FC<Props> = ({ children, open, onClose }) => {
  return (
    <Portal open={open} onClose={onClose}>
      <LazyMotion strict features={loadFeatures}>
        <ModalWrapper
          initial={{ opacity: 0.5 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          role="dialog"
          aria-modal="true"
        >
          <ModalCloseButton onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
          {children}
        </ModalWrapper>
      </LazyMotion>
    </Portal>
  )
}

const ModalWrapper = styled(m.div)`
  position: relative;
  z-index: 10000;
  display: inline-block;
  margin: 0 auto;
  padding: 3rem 2.5rem;
  width: calc(100% - 4rem);
  border-radius: 0.5rem;
  background: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.font.heading};
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1.7rem;
  letter-spacing: 0.05em;

  p {
    margin: 0 0 2.2rem;

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: auto;
    padding: 3.3rem 3.8rem;
  }
`

export const ModalCloseButton = styled.button`
  z-index: 1;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  &:hover {
    cursor: pointer;
  }
`
