import { useDictionary } from "@hooks/index"

export const useReturnItems = () => {
  
  const dictionary = useDictionary();

  const returnItems = async (order: any) => {
    const { orderNumber, shippingAddress } = order

    const body = JSON.stringify({
      designation: 818,
      name: `#${orderNumber}`,
      zip: shippingAddress?.zip,
    })

    try {
      const res = await fetch(
        "https://returns.hautehijab.com/api/v1/order/link",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-authorization": "8a1a0a9495a4c849f93544cf0aa4c86e4ab5da65",
          },
          body,
        }
      )
      const data = await res.json()

      if (data?.errors) {
        return dictionary.orderReturnError;
      }
      if (data?.url) {
        window.open(data.url, "_blank")
      }
      return true;
    } catch (e) {
      console.log(e)

    }
  }

  return returnItems
}
