import { FC, ChangeEvent } from "react"
import styled from "styled-components"
import ReactSelect, { components } from "react-select"
import DropdownSvg from "@svg/chevron-thin.svg"

interface Props {
  id: string
  options: { value: string; label: string }[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownIcon />
    </components.DropdownIndicator>
  )
}

const customStyles = {
  menu: (provided: {}) => ({
    ...provided,
    borderRadius: "3px",
    padding: 0,
  }),
  option: (provided: {}, state: any) => ({
    ...provided,
    borderBottom: "1px solid #EDEDED",
    background: state.isSelected ? "#F4F4F4" : "#FFF",
    color: "black",
    "&:hover": {
      background: "#F4F4F4",
    },
  }),
  control: (provided: {}) => ({
    ...provided,
    border: "none",
  }),
}

export const Select: FC<Props> = ({ id, options, onChange }) => (
  <StyledReactSelect
    instanceId={id}
    styles={customStyles}
    options={options}
    defaultValue={options[0]}
    isSearchable={false}
    onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e)}
    components={{ DropdownIndicator, IndicatorSeparator: () => null }}
  />
)

const StyledReactSelect = styled(ReactSelect)`
  font-size: 1.4rem;
  line-height: 1.8rem;
`

const DropdownIcon = styled(DropdownSvg)`
  width: 1rem;

  path {
    opacity: 1;
  }
`
