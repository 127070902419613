import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { decode } from 'shopify-gid';
import { useDictionary, useTracking } from '@hooks/index';

import { customerCreate } from '@lib/account';
import { passwordSchema } from '@lib/utils';
import { Input, SubmitButton } from '@components/ui';

interface Props {
  showSuccess: () => void;
}
export const SignUpForm: FC<Props> = ({ showSuccess }) => {
  const dictionary = useDictionary();
  const { identify, track } = useTracking();
  const { register, handleSubmit, errors, clearErrors } = useForm({
    criteriaMode: 'firstError',
    mode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    const { firstName, lastName, email, password } = data;

    setLoading(true);
    setError('');

    if (!passwordSchema.validate(password)) {
      setLoading(false);
      setError(
        'Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter.'
      );
      return;
    }

    const res = await customerCreate({
      firstName,
      lastName,
      email,
      password,
    });

    setLoading(false);

    if (res?.customerUserErrors?.length > 0) {
      setError(res.customerUserErrors[0].message);
      return;
    }

    if (res?.customer?.id) {
      track("Account Created", {
        userLogin: res.customer.id,
        email,
      })
      identify(decode(res.customer.id).id, {
        firstName,
        lastName,
        email,
      });
      showSuccess();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="firstName"
        type="text"
        ref={register({ required: 'First name is required' })}
        placeholder="First Name"
        errors={errors}
        clearErrors={clearErrors}
      />

      <Input
        name="lastName"
        type="text"
        ref={register({ required: 'Last name is required' })}
        placeholder="Last Name"
        errors={errors}
        clearErrors={clearErrors}
      />
      <Input
        name="email"
        type="email"
        ref={register({
          required: 'Email is required',
          pattern: {
            value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
            message: 'Please enter a valid email',
          },
        })}
        placeholder="Email address"
        errors={errors}
        clearErrors={clearErrors}
      />
      <Input
        name="password"
        type="password"
        ref={register({
          required: 'Password is required',
          validate: (value: string) =>
            passwordSchema.validate(value) ||
            'Your password should be between 8 and 100 characters, and have at least one lowercase and one uppercase letter.',
        })}
        placeholder="Password"
        errors={errors}
        clearErrors={clearErrors}
      />
      <SubmitButton title={dictionary.signUp} loading={loading} error={error} />
    </form>
  );
};
