import { FC, forwardRef } from "react"
import styled from "styled-components"
import { ErrorMessage } from "@hookform/error-message"

import { Message } from "@components/ui"

interface Props {
  name: string
  type: string
  placeholder: string
  errors: any
  clearErrors: (name: string) => void
  ref: any
}

export const Input: FC<Props> = forwardRef((props, ref) => {
  const { name, type, placeholder, errors, clearErrors } = props

  const error = errors[name]
  return (
    <InputContainer>
      <InputWrapper>
        <StyledInput
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={() => clearErrors(name)}
          ref={ref}
          aria-invalid={error ? "true" : "false"}
          $error={!!error}
        />
        {!!error && (
          <ClearErrorButton type="button" onClick={() => clearErrors(name)}>
            <svg
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
            >
              <path
                d="M9 17.333A8.333 8.333 0 109 .667a8.333 8.333 0 000 16.666zM11.5 6.5l-5 5M6.5 6.5l5 5"
                stroke="#FB7272"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </ClearErrorButton>
        )}
      </InputWrapper>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }: { message: string }) => {
          return <Message $error>{message}</Message>
        }}
      />
    </InputContainer>
  )
})

const InputContainer = styled.div`
  flex: 1;
`

export const StyledInput = styled.input<{ ref: any; $error: boolean }>`
  width: 100%;
  border: 1px solid;
  border-color: ${({ theme, $error }) =>
    $error ? theme.color.error : theme.color.greyLight};
  border-radius: 4px;
  padding: 1.4rem 1.5rem;
`

const InputWrapper = styled.div`
  position: relative;
  margin: 0 0 1.1rem;
`

const ClearErrorButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.4rem;

  svg {
    width: 1.67rem;
    height: 1.67rem;
  }
`

export const FieldContainer = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    margin: 0 -1.6rem;

    ${InputContainer} {
      width: 50%;
      padding: 0 1.6rem;
    }
  }
`
