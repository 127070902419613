import { FC, useState } from 'react';
import styled from 'styled-components';

import { customerRecover } from '@lib/account';
import { Input, FieldContainer, SubmitButton, Message } from '@components/ui';
import { useForm } from 'react-hook-form';
import { Title } from '../Title';

export const ChangePasswordForm: FC = () => {
  const { register, handleSubmit, errors, clearErrors, formState } = useForm({
    criteriaMode: 'firstError',
    mode: 'onBlur',
  });
  const { isDirty } = formState;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (data: { email: string }) => {
    const { email } = data;
    setLoading(true);
    setSuccess('');
    setError('');

    const res = await customerRecover({
      email,
    });

    setLoading(false);

    if (res?.customerUserErrors?.length > 0) {
      setError(res.customerUserErrors[0].message);
      return;
    }

    setSuccess('We have sent password recovery instructions to your email. If you did not receive the email, please check your spam folder or contact customer support.');
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Title $marginBottom>Reset password</Title>
      <Info>Enter the email associated with your account and we'll send you an email with instructions to reset your password.</Info>
      <FieldContainer>
        <Input
          name="email"
          type="email"
          ref={register({
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
              message: 'Please enter a valid email',
            },
          })}
          placeholder="Email address"
          errors={errors}
          clearErrors={clearErrors}
        />
      </FieldContainer>
      <ButtonWrapper>
        <SubmitButton title="Send instructions" loading={loading} disabled={!isDirty} />
      </ButtonWrapper>
      <MessageWrapper>
        {error && <Message $error>{error}</Message>}
        {success && <Message $success>{success}</Message>}
      </MessageWrapper>
    </StyledForm>
  );
};

const Info = styled.p`
  color: rgb(5, 21, 51);
  opacity: 0.7;
  font-size: 1.3rem;
  line-height: 1.95rem;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  max-width: 22rem;
  margin: 0 auto;
`;

const MessageWrapper = styled.div`
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
`;
