import { FC } from "react"
import styled from "styled-components"

import { Message } from "@components/ui"

// Circular import issue https://github.com/styled-components/styled-components/issues/1449
import { Button } from "../Button"

interface Props {
  title: string
  error?: string
  success?: string
  loading?: boolean
  disabled?: boolean
  fullWidth?: boolean
}

export const SubmitButton: FC<Props> = ({
  title,
  error,
  success,
  loading,
  fullWidth = true,
  ...props
}) => {
  return (
    <ButtonWrapper>
      <StyledButton
        $fullWidth={fullWidth}
        type="submit"
        buttonTheme="ghostBlackReverse"
        boldTitle
        title={title}
        loading={loading}
        {...props}
      />
      {error && <Message $error>{error}</Message>}
      {success && <Message $success>{success}</Message>}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.div`
  margin: 2rem 0;
  text-align: center;
`

const StyledButton = styled(Button)<any>`
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "auto")};
  letter-spacing: 2px;
`
