import { FC } from "react"
import Link from "next/link"
import styled from "styled-components"

import { useSite } from "@hooks/index"
import { MenuNav, UserNav } from "@components/common"
import Logo from "@svg/logo.svg"

export const Navbar: FC = () => {
  const site = useSite()

  return (
    <StyledNavbar>
      <NavbarInner>
        <MenuNav menus={site.menusCollection.items} />
        <LogoWrapper>
          <Link href="/" passHref>
            <a>
              <Logo />
            </a>
          </Link>
        </LogoWrapper>
        <UserNav />
      </NavbarInner>
    </StyledNavbar>
  )
}

const StyledNavbar = styled.header`
  z-index: ${({ theme }) => theme.zIndex.navbar};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.color.greyBlack};
  height: 4.4rem;
  width: 100%;
  color: ${({ theme }) => theme.color.white};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: ${({ theme }) => theme.navbar.md.height}rem;
  }
`

const NavbarInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 1.2rem;
  max-width: 144rem;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0 3rem;
  }
`

const LogoWrapper = styled.div`
  flex: 0 0 12.3rem;
  display: flex;
  align-items: center;

  svg {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex: 0 0 18.6rem;
    max-width: 18.5rem;
  }
`
