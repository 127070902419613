import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import { LazyMotion, m } from 'framer-motion';

import { useUI, useDictionary, useSite } from '@hooks/index';
import { useRouter } from 'next/router';

import { Portal, FluidImage, RichText } from '@components/common';
import { Modal } from '@components/ui';
import CrossThin from '@svg/cross-thin.svg';
import { LoginForm, SignUpForm, ResetPasswordModal } from './index';

const loadFeatures = () => import('@lib/framer-motion').then(res => res.default);

enum Stage {
  Login,
  SignUp,
  SignUpSuccess,
  ResetPassword,
  CheckEmail,
}

export const LoginModal: FC = () => {
  const [stage, setStage] = useState(Stage.Login);
  const [multipassCheckoutUrl, setMultipassCheckoutUrl] = useState('');

  const { displayAltLoginHeader, displayLoginModal, openLoginModal, closeLoginModal } = useUI();

  const dictionary = useDictionary();
  const site = useSite();
  const router = useRouter();

  useEffect(() => {
    if (router.query?.checkout_url) {
      const checkoutUrl = router.query?.checkout_url.toString();
      openLoginModal();
      setMultipassCheckoutUrl(checkoutUrl);
    }
  }, [router.query]);

  return (
    <>
      <Portal
        open={
          (displayLoginModal && stage === Stage.Login) ||
          (displayLoginModal && stage === Stage.SignUp)
        }
        onClose={closeLoginModal}
      >
        <LazyMotion strict features={loadFeatures}>
          <Login
            initial={{ opacity: 0.5 }}
            animate={{
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            role="dialog"
            aria-modal="true"
          >
            <FormContainer>
              {stage === Stage.Login && (
                <>
                  <Header>
                    {displayAltLoginHeader ? (
                      <RichText richText={site.loginHeaderWishlist} />
                    ) : (
                      <RichText richText={site.loginHeader} />
                    )}
                  </Header>
                  <LoginForm
                    showResetPassword={() => setStage(Stage.ResetPassword)}
                    closeLoginModal={closeLoginModal}
                    multipassCheckoutUrl={multipassCheckoutUrl}
                  />
                  <InlineMessage>
                    {dictionary.dontHaveAnAccountYet}{' '}
                    <InlineLink onClick={() => setStage(Stage.SignUp)}>
                      {dictionary.createAnAccount}
                    </InlineLink>
                  </InlineMessage>
                </>
              )}
              {stage === Stage.SignUp && (
                <>
                  <Header>
                    <RichText richText={site.signUpHeader} />
                  </Header>
                  <SignUpForm showSuccess={() => setStage(Stage.SignUpSuccess)} />
                  <InlineMessage>
                    <RichText richText={site.signUpFooter} />
                    <InlineLink onClick={() => setStage(Stage.Login)}>
                      {dictionary.alreadyHaveAnAccount}
                    </InlineLink>
                  </InlineMessage>
                </>
              )}
            </FormContainer>
            <ImageWrapper>
              <CloseButton onClick={closeLoginModal}>
                <CloseIcon />
              </CloseButton>
              <StyledFluidImage
                $hasMobile={!!site.loginImageMobile}
                src={site.loginImage.url}
                alt={site.loginImage.description}
              />
              {site.loginImageMobile && (
                <StyledFluidImageMobile
                  src={site.loginImageMobile.url}
                  alt={site.loginImageMobile.description}
                />
              )}
            </ImageWrapper>
          </Login>
        </LazyMotion>
      </Portal>
      <Modal open={displayLoginModal && stage === Stage.SignUpSuccess} onClose={closeLoginModal}>
        <Prompt>
          <PromptHeader>
            <RichText richText={site.signUpSuccessHeader} />
          </PromptHeader>
          <SkipLink onClick={() => setStage(Stage.Login)}>
            {dictionary.skipIllConfirmLater}
          </SkipLink>
          <PromptFooter>
            {dictionary.signUpSuccessFooter}{' '}
            <InlineLink onClick={() => setStage(Stage.SignUp)}>
              {dictionary.tryAnotherEmailAddress}
            </InlineLink>
          </PromptFooter>
        </Prompt>
      </Modal>
      <Modal
        open={displayLoginModal && stage === Stage.ResetPassword}
        onClose={() => {
          setStage(Stage.Login);
          closeLoginModal();
        }}
      >
        <ResetPassword>
          <AltHeader>
            <RichText richText={site.resetPasswordHeader} />
          </AltHeader>
          <ResetPasswordModal showCheckEmail={() => setStage(Stage.CheckEmail)} />
        </ResetPassword>
      </Modal>
      <Modal open={displayLoginModal && stage === Stage.CheckEmail} onClose={closeLoginModal}>
        <Prompt>
          <PromptHeader>
            <RichText richText={site.checkEmailHeader} />
          </PromptHeader>
          <SkipLink onClick={() => setStage(Stage.Login)}>
            {dictionary.skipIllConfirmLater}
          </SkipLink>
          <PromptFooter>
            {dictionary.signUpSuccessFooter}{' '}
            <InlineLink onClick={() => setStage(Stage.ResetPassword)}>
              {dictionary.tryAnotherEmailAddress}
            </InlineLink>
          </PromptFooter>
        </Prompt>
      </Modal>
    </>
  );
};

const Login = styled(m.div)`
  position: relative;
  z-index: 10000;
  display: flex;
  flex-direction: column-reverse;
  background: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  overflow-y: scroll;
  min-width: 33.5rem;
  max-width: 76.8rem;
  width: calc(100% - 4rem);

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: row;
  }
`;

const Header = styled.div`
  text-align: center;
  margin: 0 0 1.6rem;

  h1 {
    margin: 0 0 0.8rem;
    font-size: 2.6rem;
    line-height: 3.1rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
`;

const ResetPassword = styled.div`
  max-width: 39rem;
`;

const AltHeader = styled(Header)`
  p {
    color: #051533;
    opacity: 0.7;
    font-size: 1.3rem;
    line-height: 1.95rem;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 4rem 1.4rem 3rem;
`;

const ImageWrapper = styled.div`
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    min-height: 50rem;
    min-width: 32.7rem;
  }
`;

const StyledFluidImage = styled(FluidImage)<{ $hasMobile: boolean }>`
  position: relative;
  min-height: 17rem;
  width: 100%;
  height: 100%;
  display: ${({ $hasMobile }) => $hasMobile && 'none'};
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
  }
`;

const StyledFluidImageMobile = styled(FluidImage)`
  position: relative;
  min-height: 17rem;
  width: 100%;
  height: 100%;
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`;

const InlineLink = styled.span`
  font-size: 1.3rem;
  line-height: 3rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
`;

const InlineMessage = styled.div`
  text-align: center;
  font-size: 1.3rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.color.grey};

  p {
    margin: 0;
  }
`;

const CloseIcon = styled(CrossThin)`
  path {
    stroke: white;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 2.2rem;
  right: 2.2rem;
`;

const Prompt = styled.div`
  max-width: 32.7rem;
  padding: 1rem 0;
  margin: 0 auto;
  text-align: center;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.3rem;
  font-weight: 400;
`;
const PromptHeader = styled.div`
  color: ${({ theme }) => theme.color.black};
  line-height: 2rem;

  p:last-child {
    margin: 0;
  }
`;

const SkipLink = styled.button`
  margin: 3.6rem 0;
  font-size: 1.8rem;
  line-height: 3rem;
  font-family: ${({ theme }) => theme.font.primary};
  color: ${({ theme }) => theme.color.grey};
  text-decoration: underline;
`;

const PromptFooter = styled.div`
  color: ${({ theme }) => theme.color.grey};
`;
