import { FC } from "react"
import styled from "styled-components"
import { ContentBlock, Color } from "@lib/types"

import { Container, EditorialThumbnail } from "@components/ui"
import Slider from '@components/ui/Slider'

interface Props {
  data: {
    __typename: string
    backgroundColor: Color
    blocksCollection: {
      items: ContentBlock[]
    }
  }
}

export const Editorial: FC<Props> = ({ data }) => {
  const {
    backgroundColor,
    blocksCollection: { items },
  } = data
  return (
    <StyledEditorial $backgroundColor={backgroundColor.value}>
      <Container>
        <EditorialInner>
          <Mobile>
            <Slider
              slides={items}
              displayNavigation
              SlideComponent={EditorialThumbnail}
            />
          </Mobile>
          <ThumbnailList>
            {items.map((block, i) => {
              return (
                <li key={`${block.sys.id}${i}`}>
                  <EditorialThumbnail data={block} />
                </li>
              )
            })}
          </ThumbnailList>
        </EditorialInner>
      </Container>
    </StyledEditorial>
  )
}

interface ContainerProps {
  $backgroundColor: string
}

const StyledEditorial = styled.section<ContainerProps>`
  background: ${({ $backgroundColor }) => $backgroundColor};
`

const Mobile = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const EditorialInner = styled.div`
  padding: 3.6rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 auto;
    padding: 8.1rem 2rem 7.6rem;
    max-width: 102rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    padding: 8.1rem 0 7.6rem;
  }
`

const ThumbnailList = styled.ul`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 3rem;
  }
`
