

const MULTIPASS_URL = `https://secure.${process.env.NEXT_PUBLIC_SHOPIFY_STORE}.com/account/login/multipass/`

export function getMultipassToken (email: string, return_to: string): Promise<string> {
  const path = `/api/multipass?email=${email}&return_to=${return_to}`
  return fetch(path)
    .then(res => res.json())
    .then(({ token }: any) => {
      return token
    })
}

export function getMultipassUrl(email: string, return_to: string) {
  return getMultipassToken(email, return_to).then(token => ([MULTIPASS_URL, token].join('')))
}