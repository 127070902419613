import { FC, ChangeEvent } from "react"
import Image from "next/image"
import styled from "styled-components"
import ReactSelect, { components } from "react-select"
import { ContentfulImage } from "@lib/types"

const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <Label>
        <Image
          key={props.data.image.url}
          src={props.data.image.url}
          width={22}
          height={22}
          layout="fixed"
        />
        <span>{props.data.label}</span>
      </Label>
    </components.SingleValue>
  )
}

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <Label>
        <Image
          src={props.data.image.url}
          width={22}
          height={22}
          layout="fixed"
        />
        <span>{props.data.label}</span>
      </Label>
    </components.Option>
  )
}

const Label = styled.div`
  display: flex;
  align-items: center;

  span {
    margin: 0 0 0 1.2rem;
  }
`

const customStyles = {
  menu: (provided: {}) => ({
    ...provided,
    borderRadius: "3px",
    padding: 0,
  }),
  option: (provided: {}, state: any) => ({
    ...provided,
    borderBottom: "1px solid #EDEDED",
    background: state.isSelected ? "#F4F4F4" : "#FFF",
    color: "black",
    "&:hover": {
      background: "#F4F4F4",
    },
  }),
  control: (provided: {}) => ({
    ...provided,
    borderColor: "#E6E6E6",
    padding: "1.3rem 0",
  }),
  singleValue: (provided: {}) => ({
    ...provided,
    width: "100%",
  }),
}

interface Props {
  id: string
  options: { value: string; label: string; image: ContentfulImage }[]
  defaultValue?: string
  value?: string
  disabled?: boolean
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const ImageDropdown: FC<Props> = ({
  id,
  options,
  defaultValue,
  value,
  onChange,
  disabled,
}) => {
  return (
    <StyledReactSelect
      instanceId={id}
      styles={customStyles}
      options={options}
      value={value}
      defaultValue={defaultValue || options[0]}
      isSearchable={false}
      isDisabled={disabled}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => onChange(e)}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Option,
        SingleValue,
      }}
    />
  )
}

const StyledReactSelect = styled(ReactSelect)`
  font-size: 1.5rem;
  line-height: 1.8rem;
`
