import { FC } from "react"
import {
  Editorial,
  Community,
  Reviews,
  Products,
  Usp,
  Instagram,
} from "./index"

interface Props {
  data: {
    __typename: string
    layout: string
  }
}

type MapLayoutToComponent = {
  [key: string]: FC<any>
}

const components: MapLayoutToComponent = {
  Editorial,
  Community,
  Reviews,
  Products,
  Usp,
  Instagram,
}

export const PageSection: FC<Props> = ({ data }) => {
  if (typeof components[data.layout] !== "undefined") {
    const Component = components[data.layout]

    return <Component data={data} />
  }

  return <div>{`Can't find ${data.__typename}: ${data.layout}`}</div>
}
