import { useState, useReducer, useEffect, ChangeEvent } from "react"
import { ContentfulProductWithShopifyData } from "@lib/types"

const initialState = {
  filters: {
    color: [],
    fabricThickness: [],
    fabricTexture: [],
    size: [],
  },
  isActive: false,
}

type Action =
  | {
      type: "ADD_FILTER"
      payload: { name: string; value: string }
    }
  | {
      type: "REMOVE_FILTER"
      payload: { name: string; value: string }
    }
  | {
      type: "CLEAR_FILTERS"
    }

function reducer(state: any, action: Action) {
  switch (action.type) {
    case "ADD_FILTER": {
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: [
            ...state.filters[action.payload.name],
            action.payload.value,
          ],
        },
        isActive: true,
      }
    }
    case "REMOVE_FILTER": {
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: state.filters[action.payload.name].filter(
            (v: string) => v !== action.payload.value
          ),
        },
      }

      const values = Object.values(newState.filters)

      const isActive = values.some((arr: any) => arr.length > 0)

      return {
        ...newState,
        isActive,
      }
    }
    case "CLEAR_FILTERS": {
      return initialState
    }
  }
}

export const useFilter = (products: ContentfulProductWithShopifyData[]) => {

  const [state, dispatch] = useReducer(reducer, initialState)

  const filterProducts = (p: ContentfulProductWithShopifyData[]) =>
    p
      .filter(({ color }) =>
        state.filters.color.length > 0
          ? state.filters.color.includes(color?.name)
          : true
      )
      .filter(({ fabricThickness }) =>
        state.filters.fabricThickness.length > 0
          ? state.filters.fabricThickness.includes(fabricThickness?.name)
          : true
      )
      .filter(({ fabricTexture }) =>
        state.filters.fabricTexture.length > 0
          ? state.filters.fabricTexture.includes(fabricTexture?.name)
          : true
      )
      .filter(({ sizesCollection }) =>
        state.filters.size.length > 0
          ? state.filters.size.some((s: string) =>
              sizesCollection.items.some(({ name }) => name === s)
            )
          : true
      )

  const addFilter = (filter: { name: string; value: string }) =>
    dispatch({ type: "ADD_FILTER", payload: filter })

  const removeFilter = (filter: { name: string; value: string }) =>
    dispatch({ type: "REMOVE_FILTER", payload: filter })

  const clearFilters = () => dispatch({ type: "CLEAR_FILTERS" })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target

    const { checked, name, value } = target

    if (checked) {
      addFilter({
        name,
        value,
      })
    } else {
      removeFilter({
        name,
        value,
      })
    }
  }

  return {
    state,
    addFilter,
    removeFilter,
    clearFilters,
    filteredProducts: filterProducts(products),
    handleChange,
  }
}
