import { FC } from "react"
import styled from "styled-components"
import { ContentfulLink } from "@lib/types"
import { Link } from "@components/common"

interface Props {
  links: ContentfulLink[]
  copyright: string
}

export const FooterBottom: FC<Props> = ({ links, copyright }) => {
  return (
    <StyledFooterBottom>
      <Links>
        {links.map(link => (
          <LinkItem key={link.sys.id}>
            <Link link={link} />
          </LinkItem>
        ))}
      </Links>
      <Copyright>
        <small>{copyright}</small>
      </Copyright>
    </StyledFooterBottom>
  )
}

const StyledFooterBottom = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    height: 6.5rem;
  }
`

const Links = styled.ul`
  padding: 1.8rem 0;
  line-height: 3.4rem;
  margin: 0 auto;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    margin: 0;
    padding: 0;
  }
`

const LinkItem = styled.li`
  font-size: 1.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    color: ${({ theme }) => theme.color.grey};
    margin-right: 1.2rem;
    font-size: 1.2rem;

    &:last-child {
      margin: 0;
    }
  }
`

const Copyright = styled.div`
  padding: 0 0 1.3rem;
  text-align: center;
  color: ${({ theme }) => theme.color.grey};

  small {
    font-size: 1.2rem;
    line-height: 2.1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0;
  }
`
