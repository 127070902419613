import { useState, useEffect } from "react"
import styled from "styled-components"

import { useTracking } from "@hooks/index"

const Container = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  overflow: hidden;
`

interface Props {
  wistia_id: string
  autoPlay?: boolean
  displayThumbnail?: boolean
  muted?: boolean
  popover?: boolean
  displayImagePlayButton?: boolean
  resizeImage?: string
}

export function WistiaHeroVideo({
  wistia_id,
  autoPlay = true,
  displayThumbnail = false,
  muted = false,
  popover = false,
  displayImagePlayButton = true,
  resizeImage,
}: Props) {
  const [thumbnail, set_thumbnail] = useState("")

  useEffect(() => {
    if (!wistia_id) return
    const script_1 = document.createElement("script")
    const script_2 = document.createElement("script")

    setTimeout(() => {
      script_1.src = `https://fast.wistia.com/embed/medias/${wistia_id}.jsonp`
      script_1.async = true

      document.head.appendChild(script_1)

      script_2.src = "https://fast.wistia.com/assets/external/E-v1.js"
      script_2.async = true
      document.head.appendChild(script_2)
    }, 3000)

    if (!displayThumbnail) return
    fetch(`https://fast.wistia.com/embed/medias/${wistia_id}.json`).then(
      async data => {
        const json = await data.json()

        const assets = json?.media?.assets
        const img = assets?.reduce(
          (acc: any, e: any) =>
            e.type === "still_image" ? e.url.replace(".bin", ".jpg") : acc,
          ""
        )

        const resizeParam = resizeImage ? resizeImage : ""
        const newImg = `${img}?image_crop_resized=${resizeParam}&image_play_button=${displayImagePlayButton}`

        set_thumbnail(newImg)
      }
    )

    return () => {
      document.head.removeChild(script_1)
      document.head.removeChild(script_2)
    }
  }, [wistia_id])

  const { track } = useTracking()

  return (
    <Container
      className="wistia-container"
      onClick={() => {
        track("Wistia Video Clicked", { wistia_id })
      }}
    >
      {thumbnail && (
        <img style={{ position: "relative", zIndex: 1 }} src={thumbnail} />
      )}
      <div
        className="wistia_responsive_wrapper"
        style={{
          height: "100%",
          left: 0,
          position: "absolute",
          top: 0,
          width: "100%",
        }}
      >
        <div
          className={`wistia_embed wistia_async_${wistia_id} settingsControl=false muted=${muted} playbar=false playbackRateControl=false controlsVisibleOnLoad=false endVideoBehavior=loop autoPlay=${autoPlay} popover=${popover}`}
          style={{
            width: "100%",
            display: "inline-block",
            position: "relative",
            height: "100%",
          }}
        />
      </div>
    </Container>
  )
}
