import { FC } from "react"
import styled from "styled-components"
import { Portal, FluidImage, RichText } from "@components/common"
import CrossThin from "@svg/cross-thin.svg"
import { ContentfulButton, ContentfulImage, RichText as RT } from "@lib/types"
import { Button } from "@components/ui"

interface Props {
  richText: RT
  modalOpen: boolean
  closeModal: () => void
  handleBtnClick?: () => void
  handleLeftBtnClick?: () => void
  handleRightBtnClick?: () => void
  image: ContentfulImage
  imageMobile?: ContentfulImage
  button?: ContentfulButton
  buttons?: Array<ContentfulButton>
}

export const ModalWithImage: FC<Props> = ({
  richText,
  modalOpen,
  closeModal,
  handleBtnClick,
  handleLeftBtnClick,
  handleRightBtnClick,
  image,
  imageMobile,
  button,
  buttons,
}) => {
  return (
    <Portal open={modalOpen} onClose={closeModal}>
      <ModalWrapper>
        <Content>
          {richText && <RichText richText={richText} />}
          {button && <Button button={button} onClick={handleBtnClick} />}
          {buttons && 
            <ButtonsWrapper>
              {buttons.map((button, index) => <Button key={button.sys.id} button={button} onClick={index === 0 ? handleLeftBtnClick : handleRightBtnClick} />)}
            </ButtonsWrapper>
          }
        </Content>
        <ImageWrapper>
          <CloseButton onClick={closeModal}>
            <CloseIcon />
          </CloseButton>
          {image && <StyledFluidImage $hasMobile={!!imageMobile} src={image.url} alt={image.description} />}
          {imageMobile && <StyledFluidImageMobile src={imageMobile.url} alt={imageMobile.description} />}
        </ImageWrapper>
      </ModalWrapper>
    </Portal>
  )
}
const ModalWrapper = styled.div`
  z-index: 9999;
  background: ${({ theme }) => theme.color.white};
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  min-width: 33.5rem;
  max-width: 76.8rem;
  width: calc(100% - 4rem);
  flex-direction: column-reverse;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: row;
  }

`

const Content = styled.div`
  text-align: center;
  padding: 4rem 3.6rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 4rem 3.6rem 6.3rem;
  }

  h1 {
    margin: 0 0 2.5rem;
    font-size: 2.9rem;
    font-weight: 300;
    line-height: 3.1rem;
  }

  p {
    margin-bottom: 4rem;
    font-size: 1.6rem;
    line-height: 2.5rem;
  }

  button {
    display: block;
    width: 100%;
  }
`

const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  grid-gap: 1rem;
  div {
    button {
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    min-width: 32.7rem;
  }
`

const StyledFluidImage = styled(FluidImage)<{ $hasMobile: boolean }>`
  position: relative;
  min-height: 17rem;
  width: 100%;
  height: 100%;
  display: ${({ $hasMobile }) => $hasMobile && "none"};
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
  }
`
const StyledFluidImageMobile = styled(FluidImage)`
  position: relative;
  min-height: 17rem;
  width: 100%;
  height: 100%;
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`
  
const CloseIcon = styled(CrossThin)`
  path {
    stroke: white;
  }
`

const CloseButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 2.2rem;
  right: 2.2rem;

  &:hover {
    cursor: pointer;
  }
`
