import { FC } from "react"
import Link from "next/link"
import styled from "styled-components"

import { FluidImage } from "@components/common"
import { ContentfulImage } from "@lib/types"

interface Props {
  data: {
    featuredImage: ContentfulImage
    slug: string
    title: string
  }
}

export const PlaceholderThumbnail: FC<Props> = ({ data }) => {
  const { featuredImage, slug, title } = data
  return (
    <StyledPlaceholderThumbnail className="placeholder-thumbnail">
      <Link href={`/${slug}`} passHref>
        <a>
          <StyledFluidImage src={featuredImage.url} />
          <Title className="placeholder__title">{title}</Title>
        </a>
      </Link>
    </StyledPlaceholderThumbnail>
  )
}

const StyledPlaceholderThumbnail = styled.article`
  display: inline-block;
  vertical-align: top;
  width: 16rem;
  margin: 0 1.5rem 5.5rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: calc((100% / 4) - (6rem / 4));
    max-width: 25.4rem;
    margin: 0 2rem 5.5rem 0;
  }
`

const StyledFluidImage = styled(FluidImage)`
  width: 100%;
  height: 25rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 30rem;
  }
`

const Title = styled.h1`
  margin-top: 1.1rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.primary};
  text-align: center;
  line-height: 2.4rem;
  letter-spacing: 2px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.grey};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin-top: 2.4rem;
    font-size: 1.2rem;
  }
`
