import { FC } from "react"
import Image from "next/image"
import styled from "styled-components"
import { ContentSection as CS } from "@lib/types"

import { TextBlock } from "@components/common"
import { Container, PreText, Button } from "@components/ui"

interface Props {
  data: CS
}

export const ContentSection: FC<Props> = ({ data }) => {
  const {
    alignImage,
    backgroundColor,
    image,
    preText,
    textBlock,
    button,
  } = data

  const textAlign = textBlock.textAlign.toLowerCase()

  return (
    <StyledContentSection $backgroundColor={backgroundColor.value}>
      <Container>
        <ContentSectionInner $alignImage={alignImage}>
          <ImageWrapper>
            <Image src={image.url} alt={image.description} layout="fill" />
          </ImageWrapper>
          <TextContainer>
            <TextWrapper>
              <PreText preText={preText} textAlign={textAlign} />
              <TextBlock textBlock={textBlock} />
              {button && (
                <ButtonWrapper>
                  <Button button={button} buttonAlign={textAlign} />
                </ButtonWrapper>
              )}
            </TextWrapper>
          </TextContainer>
        </ContentSectionInner>
      </Container>
    </StyledContentSection>
  )
}

interface SectionProps {
  $backgroundColor: string
}

interface SectionInnerProps {
  $alignImage: string
}

const StyledContentSection = styled.section<SectionProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

const ContentSectionInner = styled.div<SectionInnerProps>`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-direction: ${({ $alignImage }) =>
      $alignImage === "Left" ? "row" : "row-reverse"};
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: clamp(36rem, 36vw, 51rem);

  img {
    object-fit: cover;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 50%;
  }
`

const TextContainer = styled.div`
  padding: 2.8rem 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    align-items: center;
    padding: 0 8rem;
  }
`

const TextWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 1.8rem;
  line-height: 2.7rem;

  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 1.1rem;
    font-family: ${({ theme }) => theme.font.heading};

    text-transform: uppercase;
    font-size: 2.6rem;
    line-height: 3.3rem;
    letter-spacing: 0.12em;

    i {
      font-family: ${({ theme }) => theme.font.headingSerif};
      letter-spacing: 0.15em;
    }
  }

  p {
    font-weight: 300;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    max-width: 48rem;

    h1,
    h2,
    h3,
    h4 {
      font-size: 3rem;
      line-height: 4.3rem;
    }
  }
`

const ButtonWrapper = styled.div`
  margin: 2.1rem 0 0;
`
