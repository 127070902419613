export const loadSession = (key: string) => {
  try {
    const serializedState = sessionStorage.getItem(key)

    if (serializedState === null) {
      return null
    }

    return JSON.parse(serializedState)
  } catch (error) {
    return null
  }
}

export const saveSession = (key: string) => (state: {}) => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem(key, serializedState)
  } catch (error) {
    console.error(error)
  }
}

export const deleteSession = (key: string) => {
  try {
    sessionStorage.removeItem(key)
  } catch (error) {
    console.error(error)
  }
}
