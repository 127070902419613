import { FC, ChangeEvent } from "react"
import styled from "styled-components"

import Checkmark from "@svg/checkmark.svg"

interface Props {
  name: string
  label: string
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const Checkbox: FC<Props> = ({ name, label, checked, onChange }) => {
  return (
    <CheckboxContainer>
      <MockInput $checked={checked}>
        <Input
          id={name}
          name={name}
          checked={checked}
          type="checkbox"
          onChange={e => onChange(e)}
        />
        <CheckmarkIcon />
      </MockInput>
      <Label htmlFor={name}>{label}</Label>
    </CheckboxContainer>
  )
}

interface StyleProps {
  $checked: boolean
}

const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Label = styled.label`
  font-size: 1.2rem;
  line-height: 1.8rem;
`

const MockInput = styled.div<StyleProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.1rem;
  width: 1.8rem;
  height: 1.8rem;
  border: 1px solid
    ${({ theme, $checked }) =>
      $checked ? theme.color.black : theme.color.greyLight};
  background: ${({ theme, $checked }) =>
    $checked ? theme.color.black : theme.color.white};
  border-radius: 0.3rem;
`

const CheckmarkIcon = styled(Checkmark)``

const Input = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  width: 100%;
  height: 100%;

  &:checked {
    border-color: ${({ theme }) => theme.color.black};

    + ${CheckmarkIcon} {
      visibility: visible;
    }
  }
`
