import React, { useState, useEffect } from "react"
import Link from "next/link"
import styled from "styled-components"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useRouter } from "next/router"
dayjs.extend(advancedFormat)

import { useCustomer, useLogToSentry, useSite } from "@hooks/index"
import { Button, ButtonWrapper } from "@components/ui"
import { Placeholder, ReturnItemLink } from "@components/account"

const getStatus = (order: any) => {
  if (order.cancelReason) {
    return "Canceled"
  }

  if (order.fulfillmentStatus === "FULFILLED") {
    return "Shipped"
  }

  return order.fulfillmentStatus
}

export function OrderHistory() {
  const site = useSite()
  const router = useRouter()
  const [loading, setLoading] = useState(false);
  const logToSentry = useLogToSentry()
  
  // Fetch updated customer data
  const { refetchCustomer, customer, logoutCustomer } = useCustomer()
  const [orders, setOrders] = useState(customer?.orders?.edges)

  // Refetch customer data so that we aren't using cached customer data from localStorage
  useEffect(() => {
    if (customer) {
      (async () => {
        try {
          const refetchedCustomer = await refetchCustomer(customer.customerAccessToken)
          setOrders(refetchedCustomer?.orders?.edges)
        } catch (errors) {
          await router.push("/")
          logoutCustomer()
          logToSentry("Error occurred refetching order history", "refetchOrderHistory", {"customer": customer})
        }
      })()
    }
  }, [])

  // If someone is on /account and the login modal triggers, and they login
  useEffect(() => {
    setOrders(customer?.orders?.edges)
  }, [customer])

  return (
    <StyledOrderHistory>
      <Title>Order history</Title>
      {orders?.length > 0 ? (
        <>
          <MobileView>
            {orders?.map(({ node }: { node: any }) => {
              return (
                <OrderItem key={node.orderNumber}>
                  <Details>
                    <OrderNumber>{`${node.name || `#${node.orderNumber}`}`}</OrderNumber>
                    <DateSpan>{dayjs(node.processedAt).format("MMMM Do, YYYY")}</DateSpan>
                    <Link href={`/account/orders/${node.orderNumber}`} passHref>
                      <Anchor>Order details</Anchor>
                    </Link>
                  </Details>
                  <StatusContainer>
                    Status: <Status>{getStatus(node)}</Status>
                    <TrackOrderBtn
                      buttonTheme="ghostBlack"
                      title="Track order"
                      href={node.statusUrl}
                      buttonAlign="right"
                    />
                  </StatusContainer>
                </OrderItem>
              )
            })}
          </MobileView>
          <DesktopTable>
            <TableHead>
              <Tr>
                <Th>Order</Th>
                <Th>Date</Th>
                <Th>Status</Th>
                <Th>Tracking</Th>
                <Th>Returns</Th>
              </Tr>
            </TableHead>
            <TableBody>
              {orders?.map(({ node }: { node: any }) => {
                return (
                  <Tr key={node.orderNumber}>
                    <Data>
                      <OrderNumber>
                        <Link href={`/account/orders/${node.orderNumber}`}>
                          {`${node.name || `#${node.orderNumber}`}`}
                        </Link>
                      </OrderNumber>
                    </Data>
                    <Data>{dayjs(node.processedAt).format("MMMM Do, YYYY")}</Data>
                    <Data>
                      <Status>{getStatus(node)}</Status>
                    </Data>
                    <Data>
                      <TrackOrderBtn
                        buttonTheme="ghostBlack"
                        title="Track order"
                        href={node.statusUrl}
                      />
                    </Data>
                    <Data>
                      <ReturnItemLink node={node} loading={loading} setLoading={setLoading}/>
                    </Data>
                  </Tr>
                )
              })}
            </TableBody>
          </DesktopTable>
        </>
      ) : (
        <Placeholder richText={site.orderHistoryPlaceholder} />
      )}
    </StyledOrderHistory>
  )
}

const StyledOrderHistory = styled.div`
  padding: 2.7rem 2rem;
  border-top: 1px solid ${({ theme }) => theme.color.greyLight};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    border: none;
    padding: 0;
    width: 100%;
    max-width: 87rem;
  }
`

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 300;
`

const MobileView = styled.ul`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const OrderItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0 0 1.8rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.black};
  letter-spacing: 0.04em;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 2rem;
`

const StatusContainer = styled.div`
  ${ButtonWrapper} {
    justify-content: flex-end;
  }
`

const Status = styled.span`
  display: inline-block;
  font-size: 1.5rem;
  line-height: 4.3rem;
  font-weight: 700;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-weight: 400;
  }
`

const DateSpan = styled.span``

const Anchor = styled.a`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
    cursor: auto;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.5rem;
  }
`

const OrderNumber = styled.span`
  font-weight: 700;
  line-height: 4.3rem;
  font-size: 1.8rem;
  text-decoration: underline;
`

const TrackOrderBtn = styled(Button)`
  padding: 0.9rem 1.4rem;
`

const DesktopTable = styled.table`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
    text-align: left;
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
  }
`

const TableHead = styled.thead``

const TableBody = styled.tbody``

const Tr = styled.tr``

const Th = styled.th`
  white-space: nowrap;
  padding: 10px 0;
  width: 100px;
  font-weight: 400;
  text-transform: uppercase;
`

const Data = styled.td`
  padding: 2rem 4.5vw 2rem 0;
  white-space: nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.color.black};

  &:last-child {
    padding-right: 0;
  }
`
