import { FC } from "react"
import styled from "styled-components"
import { ContentBlock, Color } from "@lib/types"

import { Container, UspThumbnail } from "@components/ui"
import dynamic from "next/dynamic"

const DynamicSlider = dynamic(() => import('@components/ui/Slider'))

interface Props {
  data: {
    __typename: string
    preTitle: string
    title: string
    backgroundColor: Color
    blocksCollection: {
      items: ContentBlock[]
    }
  }
}

export const Usp: FC<Props> = ({ data }) => {
  const {
    preTitle,
    title,
    backgroundColor,
    blocksCollection: { items },
  } = data
  return (
    <StyledUsp $backgroundColor={backgroundColor.value}>
      <Container>
        <UspInner>
          <TitleWrapper>
            {preTitle && <PreTitle>{preTitle}</PreTitle>}
            <Title>{title}</Title>
          </TitleWrapper>
          <Mobile>
            <DynamicSlider slides={items} SlideComponent={UspThumbnail} />
          </Mobile>
          <ThumbnailList>
            {items.map((block, i) => {
              return (
                <li key={`${block.sys.id}${i}`}>
                  <UspThumbnail data={block} />
                </li>
              )
            })}
          </ThumbnailList>
        </UspInner>
      </Container>
    </StyledUsp>
  )
}

interface ContainerProps {
  $backgroundColor: string
}

const StyledUsp = styled.section<ContainerProps>`
  background: ${({ $backgroundColor }) => $backgroundColor};
`

const Mobile = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const UspInner = styled.div`
  padding: 3.6rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 5.2rem 0 4.6rem;
    max-width: 109rem;
  }
`

const ThumbnailList = styled.ul`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 8rem;
  }
`

const TitleWrapper = styled.div`
  margin: 0 0 3.2rem;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 6.3rem;
  }
`

const PreTitle = styled.div`
  margin: 0 0 1.2rem;
  letter-spacing: 0.167rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.color.grey};
`

const Title = styled.h2`
  margin: 0;
  font-size: 3rem;
  line-height: 3.9rem;
  letter-spacing: 0.12em;
`
