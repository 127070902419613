import { useContext } from "react"
import { GeoIpContext } from "@lib/contexts"

interface IPStackResponse {
  ip: string
  country_code: string
  country_name: string
  region_name: string
  region_code: string
  city: string
  time_zone: {
    id: string
    code: string
  }
  currency: {
    code: string
    symbol: string
  }
  location: {
    languages: [{ code: string }]
  }
  locale: string
}


export const useGeoIp = () => {
  const GeoIp = useContext(GeoIpContext)

  return GeoIp as IPStackResponse
}
