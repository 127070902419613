import { FC } from "react"
import styled from "styled-components"
import { ContentBlock, Color } from "@lib/types"

import { SectionWithTitle, CommunityThumbnail } from "@components/ui"
import Slider from '@components/ui/Slider'

interface Props {
  data: {
    __typename: string
    preTitle: string
    title: string
    backgroundColor: Color
    blocksCollection: {
      items: ContentBlock[]
    }
  }
}

export const Community: FC<Props> = ({ data }) => {
  const {
    preTitle,
    title,
    backgroundColor,
    blocksCollection: { items },
  } = data
  return (
    <SectionWithTitle
      title={title}
      preTitle={preTitle}
      backgroundColor={backgroundColor}
      containerMaxWidth="102.1rem"
    >
      <Mobile>
        <Slider
          slides={items}
          SlideComponent={CommunityThumbnail}
          displayNavigation
        />
      </Mobile>
      <ThumbnailList>
        {items.map((block, i) => {
          return (
            <li key={`${block.sys.id}${i}`}>
              <CommunityThumbnail data={block} />
            </li>
          )
        })}
      </ThumbnailList>
    </SectionWithTitle>
  )
}

const Mobile = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const ThumbnailList = styled.ul`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 3rem;
  }
`
