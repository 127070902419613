import { useEffect } from "react"

export const useBodyScrollLock = (enable = false) => {
  useEffect(() => {
    if (typeof window === "undefined") return

    const { body } = window.document

    if (enable) {
      body.style.overflow = "hidden"

      // Ios
      body.style.touchAction = "none"
    } else {
      body.style.overflow = "auto"
      body.style.touchAction = "auto"
    }
  }, [enable])
}
