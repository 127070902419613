import { FC } from "react"
import styled from "styled-components"
import { ContentBlock } from "@lib/types"
import Link from "next/link"
import Image from "next/image"
import { ThumbnailLink } from "../common/index"
import { useTracking } from "@hooks/index"


import { TextBlock } from "@components/common"
import { Button } from "@components/ui"

interface Props {
  data: ContentBlock
}

export const ImageThumbnail: FC<Props> = ({ data }) => {
  const { image, textBlock, button, linkForImage } = data

  const imageWrapper = (
    <ImageWrapper>
      <Image
        src={image.url}
        alt={image.description}
        width={260}
        height={390}
        layout="responsive"
      />
      <TextWrapper>
        <TextBlock textBlock={textBlock} />
        <Button button={button} buttonAlign={textBlock.textAlign} noAnchor={linkForImage ? true : false} /> {/* linkForImage will wrap ImageWrapper(and Button) with an Anchor, so the Button shouldn't have another Anchor */}
      </TextWrapper>
    </ImageWrapper>
  )


  return (
    <Article>
      {linkForImage ? <ThumbnailLink linkForImage={linkForImage} imageWrapper={imageWrapper}/> : imageWrapper}
    </Article>
  )
}

const Article = styled.article``

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
  }
`

const TextWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 1.5rem;
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    margin: 0 0 0.3rem;
    font-size: 1.6rem;
    line-height: 2.1rem;
    letter-spacing: 0.12em;
    font-weight: 700;
    text-transform: uppercase;
  }

  p {
    margin: 0 0 1.7rem;
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }

  button {
    font-size: 1.1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    h1,
    h2,
    h3,
    h4 {
      font-size: 2.6rem;
      line-height: 3.1rem;
    }
  }
`
