import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"
import Link from "next/link"
import { ContentfulImage, ContentfulButton, TextBlock as T } from "@lib/types"

import { getSlugFromLink } from "@lib/utils"
import { useTracking } from "@hooks/index"
import { TextBlock } from "@components/common"
import { Container, Button } from "@components/ui"

interface Props {
  className: string
  data: {
    __typename: string
    image: ContentfulImage
    imageDesktop: ContentfulImage
    fontSize: string
    textBlockAlignment: string
    textBlock: T
    layout: string
    cardLayout: boolean
    button: ContentfulButton
  }
}

export const Hero: FC<Props> = ({ className, data }) => {
  const { image, imageDesktop, layout, textBlockAlignment, textBlock, button } = data
  const { track } = useTracking()
  const textLayout = layout.replace(/ /g, "")
  const alignment = textBlockAlignment.toLowerCase() || "center"
  const is_image_video = image.url.includes(".mp4") || image.url.includes(".mov")
  const is_imageDesktop_video =
    imageDesktop.url.includes(".mp4") || imageDesktop.url.includes(".mov")
  return (
    <StyledHero $alignment={alignment}>
      <Container>
        <Content $mobileCenterText={textLayout === "NobelM"}>
          {button ? (
            <Link href={getSlugFromLink(button.link)} passHref>
              <a onClick={() => track("Hero Clicked")}>
                {is_image_video ? (
                  <StyledVideo
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    className={className}
                    src={image.url}
                    layout="fill"
                  />
                ) : (
                  <StyledImage
                    className={className}
                    src={image.url}
                    alt={image.description}
                    layout="fill"
                    priority={true}
                    quality="55"
                  />
                )}
                {is_imageDesktop_video ? (
                  <VideoDesktop
                    autoPlay={true}
                    muted={true}
                    loop={true}
                    className={className}
                    src={imageDesktop.url}
                    layout="fill"
                  />
                ) : (
                  <ImageDesktop
                    className={className}
                    src={imageDesktop.url}
                    alt={imageDesktop.description}
                    layout="fill"
                  />
                )}
              </a>
            </Link>
          ) : (
            <>
              {is_image_video ? (
                <StyledVideo
                  autoPlay={true}
                  loop={true}
                  className={className}
                  src={image.url}
                  layout="fill"
                />
              ) : (
                <StyledImage
                  className={className}
                  src={image.url}
                  alt={image.description}
                  layout="fill"
                  priority={true}
                  quality="55"
                />
              )}
              {is_imageDesktop_video ? (
                <VideoDesktop
                  autoPlay={true}
                  loop={true}
                  className={className}
                  src={imageDesktop.url}
                  layout="fill"
                />
              ) : (
                <ImageDesktop
                  className={className}
                  src={imageDesktop.url}
                  alt={imageDesktop.description}
                  layout="fill"
                />
              )}
            </>
          )}

          {textLayout === "NobelCard" ? (
            <TextWrapper $layout={textLayout}>
              <Card>
                <TextBlock textBlock={textBlock} />
                {button && button?.link?.title && <Button button={button} />}
              </Card>
            </TextWrapper>
          ) : (
            <TextWrapper $layout={textLayout}>
              <TextBlock textBlock={textBlock} />
              {button && button?.link?.title && <Button button={button} buttonAlign={alignment} />}
            </TextWrapper>
          )}
        </Content>
      </Container>
    </StyledHero>
  )
}

interface ImageProps {
  layout: string
}

interface StyleProps {
  $alignment?: string
}

interface TextProps {
  $layout: string
}

interface ContentProps {
  $mobileCenterText: boolean
}

const Content = styled.div<ContentProps>`
  position: relative;
  box-sizing: border-box;
  padding: 5.5rem 2rem;
  display: flex;
  justify-content: center;
  align-items: ${({ $mobileCenterText }) => ($mobileCenterText ? "center" : "flex-end")};
  height: 100%;
  background: ${({ theme }) => theme.color.greyLight};

  img,
  video {
    object-fit: cover;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 10rem;
    align-items: center;
  }
`

const StyledImage = styled(Image)<ImageProps>`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none !important;
  }
`

const ImageDesktop = styled(Image)<ImageProps>`
  display: none !important;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block !important;
  }
`

const Video = styled.video<ImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const StyledVideo = styled(Video)`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none !important;
  }
`

const VideoDesktop = styled(Video)`
  display: none !important;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block !important;
  }
`

const TextWrapper = styled.div<TextProps>`
  position: relative;
  z-index: 900;
  text-align: center;

  h1 {
    margin-bottom: ${({ theme, $layout }) => theme.hero[$layout].title.mobile.marginBottom}rem;
    font-family: ${({ theme, $layout }) => theme.hero[$layout].title.fontFamily};
    font-size: ${({ theme, $layout }) => theme.hero[$layout].title.mobile.fontSize}rem;
    line-height: ${({ theme, $layout }) => theme.hero[$layout].title.mobile.lineHeight}rem;
    letter-spacing: ${({ theme, $layout }) => theme.hero[$layout].title.letterSpacing};

    b {
      font-weight: 700;
    }
  }

  p {
    margin-bottom: ${({ theme, $layout }) => theme.hero[$layout].paragraph.mobile.marginBottom}rem;
    font-family: ${({ theme, $layout }) => theme.hero[$layout].paragraph.fontFamily};
    font-size: ${({ theme, $layout }) => theme.hero[$layout].paragraph.mobile.fontSize}rem;
    line-height: ${({ theme, $layout }) => theme.hero[$layout].paragraph.mobile.lineHeight}rem;
    font-weight: ${({ theme, $layout }) => theme.hero[$layout].paragraph.fontWeight};
    white-space: pre-line;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    h1 {
      margin-bottom: ${({ theme, $layout }) => theme.hero[$layout].title.desktop.marginBottom}rem;
      font-size: ${({ theme, $layout }) => theme.hero[$layout].title.desktop.fontSize}rem;
      line-height: ${({ theme, $layout }) => theme.hero[$layout].title.desktop.lineHeight}rem;
    }

    p {
      margin-bottom: ${({ theme, $layout }) =>
        theme.hero[$layout].paragraph.desktop.marginBottom}rem;
      font-family: ${({ theme, $layout }) => theme.hero[$layout].fontFamily};
      font-size: ${({ theme, $layout }) =>
        theme.hero[$layout].paragraph.desktop.fontSize}rem;
      line-height: ${({ theme, $layout }) =>
        theme.hero[$layout].paragraph.desktop.lineHeight}rem;
    }
  }
`

const Card = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: 3.5rem 1.7rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 41rem;
    box-sizing: border-box;
    padding: 6.8rem 3rem;
  }
`

const StyledHero = styled.section<StyleProps>`
  position: relative;
  width: 100%;

  ${Container} {
    height: ${({ theme }) =>
      `calc(100vh - ${
        theme.navbar.sm.height + theme.announcementBar.sm.height
      }rem)`};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    ${Container} {
      height: 60rem;
    }

    ${Content} {
      justify-content: ${({ $alignment }) =>
        $alignment === "left"
          ? "flex-start"
          : $alignment === "right"
          ? "flex-end"
          : "center"};
    }

    ${TextWrapper} {
      text-align: ${({ $alignment }) =>
        $alignment !== "right" ? $alignment : "left"};
    }
  }
`
