import { FC } from "react"
import styled from "styled-components"
import { Color } from "@lib/types"

import { Container } from "./Container"

interface Props {
  preTitle: string
  title: string
  backgroundColor: Color
  id?:string
  containerMaxWidth?: string
}
export const SectionWithTitle: FC<Props> = ({
  children,
  containerMaxWidth = "110rem",
  title,
  preTitle,
  id,
  backgroundColor,
}) => {
  return (
    <StyledSectionWithTitle id={id} $backgroundColor={backgroundColor.value}>
      <StyledContainer>
        <SectionInner $maxWidth={containerMaxWidth}>
          <TitleWrapper>
            <PreTitle>{preTitle}</PreTitle>
            <Title>{title}</Title>
          </TitleWrapper>
          {children}
        </SectionInner>
      </StyledContainer>
    </StyledSectionWithTitle>
  )
}

interface SectionProps {
  $backgroundColor: string
}

const StyledSectionWithTitle = styled.section<SectionProps>`
  background: ${({ $backgroundColor }) => $backgroundColor};
`

const StyledContainer = styled(Container)`
  margin: 0 auto;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    max-width: 144rem;
  }
`

const SectionInner = styled.div<{ $maxWidth?: string }>`
  padding: 3.6rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 6rem 0 6.9rem;
    max-width: ${({ $maxWidth }) => $maxWidth};
  }
`

const TitleWrapper = styled.div`
  margin: 0 0 1.7rem;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 5.2rem;
  }
`

const PreTitle = styled.div`
  font-size: 1rem;
  letter-spacing: 0.167rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.color.grey};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 1.2rem;
  }
`

const Title = styled.h2`
  margin: 0;
  font-size: 2.2rem;
  line-height: 4.3rem;
  letter-spacing: 0.12em;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 3rem;
    line-height: 4.3rem;
  }
`
