import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"
import { ContentBlock } from "@lib/types"

import { TextBlock } from "@components/common"
import { Button, PreText } from "@components/ui"

interface Props {
  data: ContentBlock
}

export const UspThumbnail: FC<Props> = ({ data }) => {
  const { image, preText, textBlock, button } = data

  const textAlign = textBlock.textAlign
  return (
    <Article>
      <ImageWrapper>
        <Image src={image.url} alt={image.description} layout="fill" />
      </ImageWrapper>
      <TextWrapper $textAlign={textAlign.toLowerCase()}>
        <PreText preText={preText} textAlign={textAlign} />
        <TextBlock textBlock={textBlock} />
      </TextWrapper>
      {button && (
        <ButtonWrapper $align={textAlign}>
          <Button button={button} />
        </ButtonWrapper>
      )}
    </Article>
  )
}

interface TextProps {
  $textAlign: string
}

interface ButtonProps {
  $align: string
}

const Article = styled.article`
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  margin: 0 auto 2.1rem;
  width: 6.8rem;
  height: 6.8rem;
  border-radius: 50%;
  overflow: hidden;
  background: ${({ theme }) => theme.color.greyLight};

  img {
    object-fit: cover;
  }
`

const TextWrapper = styled.div<TextProps>`
  white-space: pre-wrap;
  text-align: ${({ $textAlign }) => $textAlign};

  h1,
  h2,
  h3,
  h4 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 400;
  }

  p {
    font-family: ${({ theme }) => theme.font.heading};
    font-weight: 300;
    color: ${({ theme }) => theme.color.grey};
    margin: 0 0 2.4rem;
    font-size: 1.8rem;
    line-height: 2.7rem;
  }
`

const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  justify-content: ${({ $align }) =>
    $align === "left"
      ? "flex-start"
      : $align === "right"
      ? "flex-end"
      : "center"};
`
