import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"
import Link from "next/link"

import { ContentBlock } from "@lib/types"

import { TextBlock, ThumbnailLink, WistiaHeroVideo } from "@components/common"
import { Button } from "@components/ui"

interface Props {
  data: ContentBlock
}

export const EditorialThumbnail: FC<Props> = ({ data }) => {
  const { image, wistiaId, textBlock, button, linkForImage } = data
  const imageWrapper = (
    <ImageWrapper>
      <Image
        src={image?.url}
        alt={image?.description}
        width={320}
        height={480}
        layout="responsive"
      />
    </ImageWrapper>
  );

  return (
    <Article>
      {wistiaId ? (
        <ImageWrapper>
          <WistiaHeroVideo
            wistia_id={wistiaId}
            autoPlay={false}
            resizeImage="320x480"
            popover={true}
            displayImagePlayButton={true}
          />
        </ImageWrapper>
      ) : linkForImage ? (
        <ThumbnailLink linkForImage={linkForImage} imageWrapper={imageWrapper} /> 
      ) : imageWrapper}

      <TextWrapper>
        <TextBlock textBlock={textBlock} />
      </TextWrapper>
      <ButtonWrapper>
        <Button button={button} />
      </ButtonWrapper>
    </Article>
  )
}

const Article = styled.article`
  width: 100%;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0;
  }
`

const ImageWrapper = styled.div`
  margin: 0 auto 2.6rem;

  img {
    object-fit: cover;
  }

  .wistia-container {
    padding-top: 150%;
  }
`

const TextWrapper = styled.div`
  white-space: pre-wrap;
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    font-size: 2.4rem;
    line-height: 3.1rem;
    font-weight: 300;
  }

  p {
    margin: 0 0 2.4rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    text-align: left;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    justify-content: flex-start;
  }
`
