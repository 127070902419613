import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"

interface Props {
  className?: string
  src: string
  alt?: string | null
}

export const FluidImage: FC<Props> = ({ className, src, alt }) => {
  return (
    <ImageWrapper className={`${className && className} fluid-image`}>
      {src ? <Image src={src} alt={alt || ""} layout="fill" /> : <div></div>}
    </ImageWrapper>
  )
}

const ImageWrapper = styled.div`
  flex-shrink: 0;
  position: relative;
  background: ${({ theme }) => theme.color.greyLight};
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
  }
`
