import { useState, useEffect } from "react"
import { getProductByHandle } from "@lib/shopify"
import { ContentfulProductWithShopifyData } from "@lib/types"
import { updateInventoryByLocale } from "@lib/config/shopify"
import { useRouter } from "next/router"

export const useShopifyProduct = (product: ContentfulProductWithShopifyData) => {
  const [state, setState] = useState<any>({
    shopifyProduct: null,
    error: "",
    loading: false,
  })

  const { locale } = useRouter()

  useEffect(() => {
    if (product?.shopifyProduct) {
      /*@ts-ignore*/
      fetchProduct(product.shopifyProduct.slug || product.shopifyProduct.handle)
      /*       setState({
        shopifyProduct: updateInventoryByLocale(product.shopifyProduct, locale),
        error: "",
        loading: false,
      })
      return */
    } else {
      fetchProduct(product.slug)
    }
  }, [product])

  const fetchProduct = async (handle: string) => {
    const checkoutObj = localStorage.getItem(
      locale === "en-US" ? "shopify_checkout" : "shopify_checkout_UK"
    )
    const countryCode = checkoutObj ? JSON.parse(checkoutObj).buyerIdentity.countryCode : "US"
    const res = await getProductByHandle(handle, countryCode, locale)
    if (!res) return undefined
    setState({
      shopifyProduct: updateInventoryByLocale(res, locale),
      error: "",
      loading: false,
    })
  }

  return state
}
