import { FC, ChangeEvent, useState } from "react"
import { LazyMotion, m, AnimatePresence } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)
import styled from "styled-components"
import { useDictionary, useCustomer, useCheckout, useSite } from "@hooks/index"
import { Button, TextArea, Checkbox } from "@components/ui"
import { useRouter } from "next/router"

interface Props {
  onCheckout: () => void
  giftMessage: string
  addGiftWrapping: boolean
  handleChecked: (e: ChangeEvent<HTMLInputElement>) => void
  handleTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void
}

export const CartFooter: FC<Props> = ({
  children,
  giftMessage,
  addGiftWrapping,
  onCheckout,
  handleChecked,
  handleTextArea,
}) => {
  const { customer } = useCustomer()
  const dictionary = useDictionary()
  const { giftWrapperOptionAvailability } = useSite();
  const { locale } = useRouter();
  const giftOptionAvailable = giftWrapperOptionAvailability.includes(locale);

  const { updateCheckoutAttributes, webUrl, currencyCode, lineItems  } = useCheckout()

  const [loading, setLoading] = useState(false)

  const handleBlur = async () => {
    setLoading(true)
    await updateCheckoutAttributes({ note: giftMessage })
    setLoading(false)
  }

  const cartOnlyHasGiftCards = lineItems.edges.every((item: any) => item.node.variant.product.handle === "gift-card")

  return (
    <Footer>
      {!cartOnlyHasGiftCards && giftOptionAvailable &&(
        <>
          <InputWrapper>
            <Checkbox
              name="addGift"
              label={dictionary.cartGiftLabel}
              checked={addGiftWrapping}
              onChange={handleChecked}
            />
          </InputWrapper>
          <LazyMotion strict features={loadFeatures}>
            {addGiftWrapping && (
              <AnimatePresence>
                <InputWrapper
                  key="textWrapper"
                  initial={{ height: 0 }}
                  animate={{
                    height: "auto",
                  }}
                  exit={{ height: 0 }}
                >
                  <TextArea
                    name="giftMessage"
                    placeholder={dictionary.yourMessage}
                    value={giftMessage}
                    maxLength={300}
                    onBlur={handleBlur}
                    onChange={handleTextArea}
                  />
                </InputWrapper>
              </AnimatePresence>
            )}
          </LazyMotion>
        </>
      )}
      <CheckoutButton
        buttonTheme="ghostBlackReverse"
        title="checkout"
        boldTitle
        onClick={onCheckout}
        target={false}
        disabled={loading}
      />
      {children}
    </Footer>
  )
}

const Footer = styled.div`
  background: ${({ theme }) => theme.color.white};
  padding: 1.5rem 2rem 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 2rem;
  }
`

const InputWrapper = styled(m.div)`
  margin: 0 0 1rem;

  &:first-child {
    margin: 0 0 1.4rem;
  }
`

const CheckoutButton = styled(Button)`
  margin: 0 0 1.75rem;
  padding: 1.45rem;
  width: 100%;

  &:hover {
    color: ${({ theme }) => theme.color.black};
    background: transparent;
    border-color: ${({ theme }) => theme.color.black};
  }
`
