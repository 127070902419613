import { FC, useState } from 'react';
import styled from 'styled-components';
import { Input, FieldContainer, SubmitButton, Message, Modal } from '@components/ui';

import { Address } from '@lib/types';
import { useForm } from 'react-hook-form';
import { useCustomer } from '@hooks/index';

interface Props {
  open: boolean;
  onClose: () => void;
  address?: Address;
}

export const AddressForm: FC<Props> = ({
  open,
  onClose,
  address = {
    firstName: '',
    lastName: '',
    company: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    province: '',
    zip: '',
    phone: '',
  },
}) => {
  const { addAddress, updateAddress, loading } = useCustomer();

  const { register, handleSubmit, errors, clearErrors, formState } = useForm({
    defaultValues: { ...address },
    mode: 'onBlur',
  });
  const { isDirty } = formState;

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const onSubmit = async (data: Address) => {
    setSuccess('');
    setError('');
    let res;
    if (address?.id) {
      res = await updateAddress({
        id: address.id,
        address: {
          ...data,
        },
      });
    } else {
      res = await addAddress({
        ...data,
      });
    }

    if (res?.error) {
      setError(res.error);

      return;
    }

    setSuccess(address?.id ? 'Address updated' : 'Created address');

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>{address?.id ? 'Edit Address' : 'Create Address'}</FormTitle>
        <FieldContainer>
          <Input
            name="firstName"
            ref={register({ required: 'First name is required' })}
            placeholder="First name*"
            type="text"
            errors={errors}
            clearErrors={clearErrors}
          />
          <Input
            name="lastName"
            ref={register({ required: 'Last name is required' })}
            placeholder="Last name*"
            type="text"
            errors={errors}
            clearErrors={clearErrors}
          />
        </FieldContainer>
        <Input
          name="company"
          ref={register()}
          placeholder="Company"
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
        <Input
          name="address1"
          ref={register({ required: 'Address is required' })}
          placeholder="Address*"
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
        <Input
          name="address2"
          ref={register()}
          placeholder="Apartment, suite, etc."
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
        <Input
          name="city"
          ref={register({ required: 'City is required' })}
          placeholder="City*"
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
        <Input
          name="province"
          ref={register({ required: 'Province is required' })}
          placeholder="Province*"
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
        <FieldContainer>
          <Input
            name="country"
            ref={register({ required: 'Country is required' })}
            placeholder="Country/Region*"
            type="text"
            errors={errors}
            clearErrors={clearErrors}
          />
          <Input
            name="zip"
            ref={register({ required: 'Zip is required' })}
            placeholder="Postal code*"
            type="text"
            errors={errors}
            clearErrors={clearErrors}
          />
        </FieldContainer>
        <Input
          name="phone"
          ref={register()}
          placeholder="Phone"
          type="tel"
          errors={errors}
          clearErrors={clearErrors}
        />
        <ButtonWrapper>
          <SubmitButton title="Save" loading={loading} disabled={!isDirty} />
        </ButtonWrapper>
        <MessageWrapper>
          {error && <Message $error>{error}</Message>}
          {success && <Message $success>{success}</Message>}
        </MessageWrapper>
      </StyledForm>
    </Modal>
  );
};

const StyledForm = styled.form`
  width: 100%;
  margin: 0 0 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 2rem;
    width: 68rem;
  }
`;

const FormTitle = styled.h2`
  font-size: 2.6rem;
  line-height: 3.1rem;
  text-align: center;
`;

const ButtonWrapper = styled.div`
  max-width: 17.5rem;
  margin: 0 auto;
`;

const MessageWrapper = styled.div`
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
`;
