import { FC, ChangeEvent } from "react"
import styled from "styled-components"

import DropdownSvg from "@svg/chevron-thin.svg"

interface Props {
  className?: string
  name: string
  value: string | number
  disabled?: boolean
  options: {
    name: string | number
    value: string | number
  }[]
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void
}

export const Dropdown: FC<Props> = ({
  className,
  name,
  value,
  disabled,
  options,
  onChange,
}) => {
  return (
    <SelectWrapper className={className}>
      <StyledSelect
        name={name}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e)}
      >
        {options.map(({ name, value }) => (
          <Option key={value} value={value}>
            {name}
          </Option>
        ))}
      </StyledSelect>
      <DropdownIcon />
    </SelectWrapper>
  )
}

const SelectWrapper = styled.div`
  display: inline-block;
  position: relative;
  box-sizing: border-box;
`

const StyledSelect = styled.select`
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: none;
  width: 100%;
  padding: 1.1rem 3rem 1.1rem 1.3rem;
  background: ${({ theme }) => theme.color.white};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.font.primary};
  border-radius: 0.3rem;
  border: 1px solid ${({ theme }) => theme.color.greyLight};
`

const Option = styled.option``

const DropdownIcon = styled(DropdownSvg)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  pointer-events: none;
  width: 1rem;

  path {
    opacity: 1;
  }
`
