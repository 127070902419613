import { FC } from "react"
import styled from "styled-components"
import Link from "next/link"

import Chevron from "@svg/chevron.svg"

interface Props {
  className?: string
  href: string
  title: string
}
export const BackLink: FC<Props> = ({ className, href, title }) => {
  return (
    <BackLinkWrapper className={className}>
      <Link href={href} passHref>
        <Anchor>
          <ChevronIcon />
          {title}
        </Anchor>
      </Link>
    </BackLinkWrapper>
  )
}

const BackLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto 0.8rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    justify-content: flex-start;
    margin: 0 0 2rem;
  }
`

const Anchor = styled.a`
  font-size: 1.2rem;
  line-height: 2.4rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
`

const ChevronIcon = styled(Chevron)`
  margin-right: 1.2rem;
  transform: rotate(90deg);
  width: 1.2rem;

  path {
    opacity: 1;
    fill: black;
  }
`
