import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"

import {
  ContentfulButton,
  ContentfulImage,
  Color,
  ContentfulProductWithShopifyData,
} from "@lib/types"

import { ProductThumbnail } from "@components/ui"

interface Props {
  data: {
    alignImage: string
    backgroundColor: Color
    image: ContentfulImage
    imageSize: string
    preText: string
    button: ContentfulButton
    productsCollection: {
      items: ContentfulProductWithShopifyData[]
    }
  }
}

export const ProductsWithLargeImage: FC<Props> = ({ data }) => {
  const {
    alignImage,
    image,
    productsCollection: { items },
  } = data

  return (
    <StyledProductsWithLargeImage>
      <TopRow $alignImage={alignImage}>
        <ImageWrapper $alignImage={alignImage}>
          <Image src={image.url} alt={image.description} layout="fill" />
        </ImageWrapper>
        <ThumbnailsTop>
          {items.slice(0, 2).map((block, i) => {
            return (
              <ThumbnailItem key={`${block.sys.id}${i}`}>
                <ProductThumbnail product={block} />
              </ThumbnailItem>
            )
          })}
        </ThumbnailsTop>
      </TopRow>
      {items.length > 2 && (
        <BottomRow>
          {items.slice(2).map((block, i) => {
            return (
              <ThumbnailItem key={`${block.sys.id}${i}`}>
                <ProductThumbnail product={block} />
              </ThumbnailItem>
            )
          })}
        </BottomRow>
      )}
    </StyledProductsWithLargeImage>
  )
}

interface TopRowProps {
  $alignImage: string
}

interface ImageWrapperProps {
  $alignImage: string
}

const StyledProductsWithLargeImage = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 2rem 0 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 12rem 0 6rem;
    flex-direction: column;
  }
`

const ImageWrapper = styled.div<ImageWrapperProps>`
  flex-shrink: 0;
  position: relative;
  margin: 2rem 0 0;
  height: 22.3rem;
  ${({ $alignImage }) =>
    $alignImage === "Left" ? "margin-right: auto" : "margin-left: auto"};
  width: calc(100% - 2rem);

  img {
    object-fit: cover;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0;
    width: 20%;
    height: 38rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    width: clamp(35.5rem, 48vw, 70rem);
  }
`

const TopRow = styled.div<TopRowProps>`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 1.6rem;
    flex-direction: ${({ $alignImage }) =>
      $alignImage === "Left" ? "row" : "row-reverse"};
  }
`

const ThumbnailsTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.9rem 1.4rem;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    flex-shrink: 0;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3.2rem 2.6rem;
    margin: 0 3rem;
    padding: 0;
    flex-direction: row;
  }
`

const ThumbnailItem = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 25.4rem;
  }
`

const BottomRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.9rem 1.4rem;
  margin: 0 0 2.9rem;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 3.2rem 2.6rem;
    margin: 0 auto;
    padding: 0;
    width: 110rem;
  }
`
