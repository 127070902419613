import { FC, useState, useEffect } from "react"
import styled from "styled-components"

import { useUI, useDictionary, useSite, useShopifyProduct } from "@hooks/index"
import { Portal, FluidImage } from "@components/common"
import { Button, ProductThumbnail } from "@components/ui"
import CrossThin from "@svg/cross-thin.svg"
import Search from "@lib/search"
import { localize } from "@lib/utils"
import {
  Configure,
  SearchBox,
  InstantSearch,
  connectHits,
  connectAutoComplete,
  connectSearchBox,
} from "react-instantsearch-dom"

interface IProps {
  open: boolean
}

function Hit({ hit }: any) {
  const localized = localize(hit.fields, ["en-US"])
  return (
    <div key={hit.objectID} style={{ maxWidth: 250 }}>
      <ProductThumbnail product={localized} />
    </div>
  )
}

export const SearchModal: FC = (props: any) => {
  const [query, setQuery] = useState("")
  const { closeSearchModal, displaySearchModal } = useUI()
  const VirtualSearchBox = connectSearchBox(() => null)
  const Hits = ({ hits }: any) => {
    return (
      <Results>
        {hits.map((hit: any) => (
          <Hit hit={hit} />
        ))}
      </Results>
    )
  }
  const CustomHits = connectHits(Hits)
  return (
    <>
      <Portal open={displaySearchModal} onClose={closeSearchModal}>
        <SearchWrapper>
          <InstantSearch searchClient={Search} indexName="contentful_products">
            <SearchInput
              placeholder="What are you looking for?"
              onReset={() => setQuery("")}
              onChange={event => {
                event.preventDefault()
                setQuery(event.target.value)
              }}
            />
          </InstantSearch>
          <InstantSearch searchClient={Search} indexName="contentful_products">
            <Configure hitsPerPage={8} distinct />
            <VirtualSearchBox defaultRefinement={query} />
            {query !== "" && <CustomHits />}
            {query !== "" && <More>Show More</More>}
          </InstantSearch>
        </SearchWrapper>
      </Portal>
    </>
  )
}

const Results = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 1rem;
`

const More = styled(Button)``

const SearchWrapper = styled.div`
  position: relative;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.color.white};
  border-radius: 5px;
  height: 100%;
  padding: 2rem;
  overflow-y: scroll;
  max-height: 90rem;
  max-width: ${({ theme }) => theme.breakpoint.xl}px;
`

const SearchInput = styled.input`
  font-size: 4.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  text-transform: capitalize;
  &:focus {
    outline: none;
  }
`

const InlineLink = styled.span`
  font-size: 1.3rem;
  line-height: 3rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.grey};
  cursor: pointer;
`

const InlineMessage = styled.div`
  text-align: center;
  font-size: 1.3rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.color.grey};

  p {
    margin: 0;
  }
`

const CloseIcon = styled(CrossThin)`
  path {
    stroke: black;
  }
`

const CloseButton = styled.button`
  position: absolute;
  z-index: 1;
  top: 2.2rem;
  right: 2.2rem;
`
