export enum LocalStorageKeys {
  CHECKOUT = "shopify_checkout",
  CHECKOUT_UK = "shopify_checkout_UK",
  MIDDLELAYER = "checkout_middlelayer",
  MIDDLELAYER_UK = "checkout_middlelayer_UK",
  CUSTOMER = "shopify_customer",
  WISHLIST = "customer_wishlist",
  CURRENCY = "customer_currency",
  ANNOUNCEMENT_SEEN_EXPIRY = "announcement_seen_expiry"
}
