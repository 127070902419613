import { FC } from "react"
import styled from "styled-components"
import {
  ContentfulButton,
  ContentfulImage,
  Color,
  ContentfulProductWithShopifyData,
} from "@lib/types"

import { ProductsWithLargeImage, ProductsWithSmallImage } from "./index"
import { Container, ProductThumbnail } from "@components/ui"

interface Props {
  data: {
    alignImage: string
    backgroundColor: Color
    image: ContentfulImage
    imageSize: string
    preText: string
    button: ContentfulButton
    productsCollection: {
      items: ContentfulProductWithShopifyData[]
    }
  }
}

export const ProductsSection: FC<Props> = ({ data }) => {
  const {
    imageSize,
    backgroundColor,
    image,
    productsCollection: { items },
  } = data

  return (
    <StyledContentSection $backgroundColor={backgroundColor.value}>
      <Container>
        {image ? (
          <>
            {imageSize === "Large" && <ProductsWithLargeImage data={data} />}
            {imageSize === "Small" && <ProductsWithSmallImage data={data} />}
          </>
        ) : (
          <ProductsRow>
            {items.map((block, i) => {
              return (
                <ThumbnailItem key={`${block.sys.id}${i}`}>
                  <ProductThumbnail product={block} />
                </ThumbnailItem>
              )
            })}
          </ProductsRow>
        )}
      </Container>
    </StyledContentSection>
  )
}

export default ProductsSection

interface SectionProps {
  $backgroundColor: string
}

const StyledContentSection = styled.section<SectionProps>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

const ProductsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.9rem 1.4rem;
  padding: 2rem 2rem 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-template-columns: repeat(auto-fill, 22rem);
    grid-gap: 3.2rem 2.6rem;
    width: 100%;
    max-width: 110rem;
    margin: 0 auto;
    padding: 6rem 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    grid-template-columns: repeat(auto-fill, 25.4rem);
    padding: 12rem 0;
  }
`

const ThumbnailItem = styled.div`
  width: 100%;
`
