import "@styles/global.css"
import type { AppProps } from "next/app"
import Head from "next/head"
import { DefaultSeo } from "next-seo"
import { ThemeProvider } from "styled-components"
import theme from "@styles/theme"
import {
  DictionaryContext,
  CheckoutProvider,
  UIProvider,
  SiteContext,
  CustomerProvider,
  GeoIpProvider,
} from "@lib/contexts"
import { Layout } from "@components/common"
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

declare global {
  interface Window {
    analytics: any
    ttq: any
  }
}
if (process.env.IS_PRODUCTION === 'true') {
  Sentry.init({
    dsn: "https://2a73f7986faa4de0aae222d2531b1403@o228380.ingest.sentry.io/5702977",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    release: `hautehijab@${process.env.NEXT_PUBLIC_COMMIT_SHA}`
  });
}

const DISABLE_SEO = Boolean(process.env.DISABLE_SEO || (process.env.AWS_LAMBDA_FUNCTION_NAME && process.env.AWS_LAMBDA_FUNCTION_NAME.includes('dev')))

function MyApp({ Component, pageProps }: AppProps) {
  const { preview, site, dictionary, locale } = pageProps
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.png" />
      </Head>
      <DefaultSeo
        dangerouslyDisableGooglebot={DISABLE_SEO}
        dangerouslySetAllPagesToNoFollow={DISABLE_SEO}
        dangerouslySetAllPagesToNoIndex={DISABLE_SEO}
        defaultTitle="Online Hijab Store - "
        titleTemplate={`%s - ${site?.seoTitleTemplate}`}
        description="The world's best hijabs for the world's most powerful women. Shop Haute Hijabs in jersey, chiffon, silk & more. Free US shipping."
        openGraph={{
          type: "website",
          url: "https://www.hautehijab.com/",
          site_name: "Haute Hijab",
        }}
        twitter={{
          handle: "@hautehijab",
          site: "@hautehijab",
          cardType: "summary_large_image",
        }}
      />
      <ThemeProvider theme={theme}>
        <GeoIpProvider>
          <CustomerProvider locale={locale}>
            <UIProvider>
              <CheckoutProvider locale={locale}>
                <DictionaryContext.Provider value={dictionary}>
                  <SiteContext.Provider value={site}>
                    {site && (
                      <Layout
                        hideSubscribeFooter={pageProps?.isAccountPage}
                        preview={preview}
                      >
                        <Component {...pageProps} />
                      </Layout>
                    )}
                  </SiteContext.Provider>
                </DictionaryContext.Provider>
              </CheckoutProvider>
            </UIProvider>
          </CustomerProvider>
        </GeoIpProvider>
      </ThemeProvider>
    </>
  )
}

export default MyApp
