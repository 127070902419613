import { FC } from "react"
import styled from "styled-components"
import { LazyMotion, m } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

import { useBodyScrollLock } from "@hooks/index"

interface Props {
  isOpen: boolean
  fromRight?: boolean
  handleClose: () => void
}

export const Sidebar: FC<Props> = ({
  children,
  fromRight,
  isOpen,
  handleClose,
}) => {
  useBodyScrollLock(isOpen)

  let sidebarVariants = {
    open: {
      x: 0,
    },
    closed: {
      x: "-100%",
    },
  }

  if (fromRight) {
    sidebarVariants = {
      open: {
        x: 0,
      },
      closed: {
        x: "100%",
      },
    }
  }

  return (
    <LazyMotion strict features={loadFeatures}>
      <Overlay
        initial={false}
        animate={{
          opacity: isOpen ? 0.7 : 0,
          display: isOpen ? "block" : "none",
        }}
        onClick={handleClose}
      />
      <StyledSidebar
        $alignRight={fromRight}
        initial={false}
        animate={isOpen ? "open" : "closed"}
        variants={sidebarVariants}
        transition={{ bounce: 0 }}
      >
        {children}
      </StyledSidebar>
    </LazyMotion>
  )
}

interface StyleProps {
  $alignRight?: boolean
}

const StyledSidebar = styled(m.aside)<StyleProps>`
  z-index: ${({ theme }) => theme.zIndex.sidebar};
  position: fixed;
  top: 0;
  ${({ $alignRight }) => ($alignRight ? "right: -1px;" : "left: -1px;")}
  bottom: 0;
  width: 32.5rem;
  color: ${({ theme }) => theme.color.black};
  background: ${({ theme }) => theme.color.white};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 37.6rem;
  }
`

const Overlay = styled(m.div)`
  z-index: ${({ theme }) => theme.zIndex.overlay};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => theme.color.white};
  height: 100vh;
  width: 100%;
`
