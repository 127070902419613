import { FC } from "react"
import styled from "styled-components"
import { ContentBlock, Color } from "@lib/types"
import dynamic from "next/dynamic"

import { Container, Review } from "@components/ui"

const DynamicSlider = dynamic(() => import('@components/ui/Slider'))

interface Props {
  data: {
    __typename: string
    preTitle: string
    backgroundColor: Color
    blocksCollection: {
      items: ContentBlock[]
    }
  }
}

export const Reviews: FC<Props> = ({ data }) => {
  const {
    preTitle,
    backgroundColor,
    blocksCollection: { items },
  } = data

  const slides = items.map(item => ({ preTitle, ...item }))

  return (
    <StyledReviews $backgroundColor={backgroundColor.value}>
      <Container>
        <ReviewsInner>
          <PreTitle>{preTitle}</PreTitle>
          <SliderContainer>
            <DynamicSlider
              displayArrows
              slides={slides}
              SlideComponent={Review}
              displayNavigation
            />
          </SliderContainer>
        </ReviewsInner>
      </Container>
    </StyledReviews>
  )
}

interface ContainerProps {
  $backgroundColor: string
}

const StyledReviews = styled.section<ContainerProps>`
  background: ${({ $backgroundColor }) => $backgroundColor};
`

const ReviewsInner = styled.div`
  padding: 4rem 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 auto;
    padding: 6rem 0;
  }
`

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;

  .slider__wrapper {
    margin: 0 1rem;
  }
`

const PreTitle = styled.h2`
  margin: 0 0 2.9rem;
  font-size: 1.2rem;
  line-height: 2.4rem;
  letter-spacing: 0.167rem;
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.color.grey};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`
