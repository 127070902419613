import { CHECKOUT_QUERY } from "@lib/fragments"

export const CHECKOUT_LINE_ITEMS_ADD = `
  mutation checkoutLineItemsAdd($lineItems: [CheckoutLineItemInput!]!, $checkoutId: ID!) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ${CHECKOUT_QUERY}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
