import { FC, ChangeEvent } from "react"
import styled from "styled-components"

interface Props {
  name: string
  value: string
  minLength?: number
  maxLength?: number
  placeholder: string
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: () => void
}

export const TextArea: FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  placeholder,
  minLength,
  maxLength,
}) => {
  return (
    <TextAreaWrapper>
      <StyledTextArea
        id={name}
        name={name}
        value={value}
        onChange={e => onChange(e)}
        onBlur={() => onBlur && onBlur()}
        placeholder={placeholder}
        minLength={minLength}
        maxLength={maxLength}
      />
      {maxLength && <CharCount>{maxLength - value.length}</CharCount>}
    </TextAreaWrapper>
  )
}

const TextAreaWrapper = styled.div`
  position: relative;
`

const StyledTextArea = styled.textarea`
  padding: 1.3rem 1.5rem;
  box-sizing: border-box;
  width: 100%;
  min-height: 8.6rem;
  border: 1px solid ${({ theme }) => theme.color.greyLight};
  border-radius: 0.4rem;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.4rem;
  line-height: 1.8rem;
  resize: none;
`

const CharCount = styled.span`
  position: absolute;
  bottom: 1.1rem;
  right: 1.1rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.color.black};
`
