import { ImageWithRichText } from "@lib/types"
import { FC } from "react"
import styled from "styled-components"

import { RichText, FluidImage } from "@components/common"

interface Props {
  title: string
  thumbnails: ImageWithRichText[]
}

export const InfoPageMedia: FC<Props> = ({ title, thumbnails }) => {
  return (
    <StyledInfoPageMedia>
      {title && <Title>{title}</Title>}
      <Thumbnails>
        {thumbnails.map(({ sys, image, textColor, richText }) => {
          return (
            <ThumbnailItem key={sys.id}>
              <Thumbnail>
                <StyledFluidImage src={image.url} alt={image.description} />
                <TextWrapper $textColor={textColor?.value}>
                  <RichText richText={richText} />
                </TextWrapper>
              </Thumbnail>
            </ThumbnailItem>
          )
        })}
      </Thumbnails>
    </StyledInfoPageMedia>
  )
}

const TextWrapper = styled.div<{ $textColor: string }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 1.1rem 0.5rem;
  text-align: center;
  color: ${({ $textColor }) => $textColor};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 2.1rem 1.5rem;
  }
`

const StyledInfoPageMedia = styled.div`
  margin-bottom: 1rem;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin-bottom: 2rem;
  }
  ${TextWrapper} {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 1rem;
      font-size: 1.8rem;
      line-height: 2.3rem;
      letter-spacing: 0.04em;
    }

    p {
      margin: 0;
      font-family: ${({ theme }) => theme.font.primary};
      font-size: 1.5rem;
      line-height: 2rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.8rem;
        line-height: 2.3rem;
        letter-spacing: 0.04em;
      }
    }
  }
`

const Title = styled.h3`
  margin: 0 0 1.9rem;
  font-size: 2.4rem;
  line-height: 3.6rem;
`

const Thumbnails = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  // Override default richText styling
  list-style: none !important;
  margin-left: 0 !important;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
  }
`

const ThumbnailItem = styled.li``

const Thumbnail = styled.div`
  position: relative;
`

const StyledFluidImage = styled(FluidImage)`
  width: 16rem;
  height: 24.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 21rem;
    height: 32.2rem;
  }
`
