import { useState, ChangeEvent } from "react"
import { Color, ContentfulProductWithShopifyData } from "@lib/types"

const types = {
  DEFAULT: "Default",
  NEWEST: "Newest",
  OLDEST: "Oldest",
  PRICE_HIGH_TO_LOW: "Price high to low",
  PRICE_LOW_TO_HIGH: "Price low to high",
}

type Products = ContentfulProductWithShopifyData[]

const sortBy = (type: string) => {
  switch (type) {
    case types.DEFAULT:
      return (products: Products) => [...products]
    case types.NEWEST:
      return (products: Products) =>
        [...products].sort(
          (a, b) => new Date(b.sys.publishedAt).getTime() - new Date(a.sys.publishedAt).getTime()
        )
    case types.OLDEST:
      return (products: Products) =>
        [...products].sort(
          (a, b) => new Date(a.sys.publishedAt).getTime() - new Date(b.sys.publishedAt).getTime()
        )
    case types.PRICE_HIGH_TO_LOW:
      return (products: Products) =>
        [...products].sort(
          (a, b) =>
            parseInt(b.shopifyProduct?.variants?.edges[0]?.node?.priceV2?.amount) -
            parseInt(a.shopifyProduct?.variants?.edges[0]?.node?.priceV2?.amount)
        )
    case types.PRICE_LOW_TO_HIGH:
      return (products: Products) =>
        [...products].sort(
          (a, b) =>
            parseInt(a.shopifyProduct?.variants?.edges[0]?.node?.priceV2?.amount) -
            parseInt(b.shopifyProduct?.variants?.edges[0]?.node?.priceV2?.amount)
        )

    default:
      console.error(`Unknown sort param: ${type}.`)
      return (products: Products) => [...products]
  }
}

export const useSort = () => {
  const [sortKey, setSortKey] = useState(types.DEFAULT)

  const sortOptions = Object.values(types).map(v => ({
    name: v,
    value: v,
  })) as Color[]

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => setSortKey(e.target.value)

  const sortIsActive = sortKey !== types.DEFAULT

  return {
    sortKey,
    sortBy: sortBy(sortKey),
    sortOptions,
    handleChange,
    sortIsActive,
  }
}
