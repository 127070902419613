import { FC } from "react"
import styled from "styled-components"
import { MenuItem, ContentfulImage, ContentfulButton } from "@lib/types"

import { Container, Tile } from "@components/ui"
import { Link } from "@components/common"
interface Props {
  menuItems: MenuItem[]
  image: ContentfulImage
  button: ContentfulButton
}

export const Submenu: FC<Props> = ({ menuItems, image, button }) => {
  return (
    <StyledSubmenu>
      <Container>
        <SubmenuInner>
          <MenuItems>
            {menuItems.map(({ category, linksCollection }) => (
              <StyledMenuItem key={category}>
                <Category>{category}</Category>
                <MenuList>
                  {linksCollection.items.map(link => (
                    <MenuListItem key={link.sys.id}>
                      <Link
                        link={link}
                        trackingProperties={{ category: "Menu level 2" }}
                      />
                    </MenuListItem>
                  ))}
                </MenuList>
              </StyledMenuItem>
            ))}
          </MenuItems>
          <StyledTile image={image} button={button} />
        </SubmenuInner>
      </Container>
    </StyledSubmenu>
  )
}

const StyledSubmenu = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.navbar.md.height}rem;
  left: 0;
  right: 0;
  color: ${({ theme }) => theme.color.black};
  background: ${({ theme }) => theme.color.white};
`

const SubmenuInner = styled.div`
  display: flex;
  justify-content: center;
  padding: 4.5rem 0rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    padding: 4.5rem 0rem;
  }
`

const Category = styled.h3`
  line-height: 30px;
  margin: 0 0 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 2rem;
  }
`

const MenuItems = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    justify-content: initial;
  }
`

const StyledMenuItem = styled.div`
  margin-right: 4rem;
  &:last-of-type {
    margin-right: 0rem;
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    &:last-of-type {
      margin-right: 5rem;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    margin-right: 8rem;

    &:last-of-type {
      margin-right: 10rem;
    }
  }
  
  @media (min-width: ${({ theme }) => theme.breakpoint.xxl}px) {
    margin-right: 10rem;
  
    &:last-of-type {
      margin-right: 12rem;
    }
  }

`

const MenuList = styled.ul`
  line-height: 3rem;
  font-weight: 400;
`

const MenuListItem = styled.li`
  white-space: nowrap;
  font-size: 1.5rem;

  b {
    font-weight: 500;
  }
`

const StyledTile = styled(Tile)`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    display: block;
  }
`
