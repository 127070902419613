import { useCustomer, useGeoIp } from "@hooks/index"
import { loadState, LocalStorageKeys } from "@lib/utils"

const customer = loadState(LocalStorageKeys.CUSTOMER)
const isTrackingEnabled = process.env.TRACKING_HOOKS_ENABLED === "true"
const withWindow = (cb: () => void) =>
  typeof window !== "undefined" && window?.analytics?.ready && window.analytics.ready(cb)

type CoreOrdering =
  | "Product Clicked"
  | "Product Added"
  | "Product Removed"
  | "Product Viewed"
  | "Cart Viewed"

type Wishlisting =
  | "Product Added to Wishlist"
  | "Product Removed from Wishlist"
  | "Wishlist Product Added to Cart"

type Custom =
  | "Complete the Look Added"
  | "Nav Hovered"
  | "Nav Opened"
  | "Nav Closed"
  | "Nav Feature Clicked"
  | "Account Nav Hovered"
  | "Link Clicked"
  | "Button Clicked"
  | "Hero Clicked"
  | "Wistia Video Clicked"
  | "Complete the Look Viewed"
  | "Related Products Viewed"
  | "Reviews Viewed"
  | "Email Subscribed"
  | "Loyalty Lion"
  | "Search Clicked"

type Accounts = "Account Created" | "Logged In"

type Modal =
  | "Login Modal Open"
  | "Login Modal Closed"
  | "Country Modal Open"
  | "Country Modal Closed"
  | "Size Chart Open"
  | "Size Chart Closed"
  | "Text Modal Open"
  | "Text Modal Closed"

type Coupon = "Coupon Applied" | "Coupon Denied"

type Browsing = "Product List Viewed" | "Product List Filtered" | "Products Searched"

type Event = CoreOrdering | Wishlisting | Browsing | Accounts | Modal | Coupon | Custom

type TikTokEvent = "ViewContent" | "AddToCart" | "InitiateCheckout" | "SubmitForm" | "AddToWishlist"

export const useTracking = () => {
  const { currency } = useCustomer()
  const { country_name, country_code, city } = useGeoIp()

  const additonal_context = {
    location: {
      country: country_name,
      code: country_code,
      city,
    },
  }

  const identify = (customerId: string, traits?: object) => {
    if (isTrackingEnabled) {
      withWindow(() =>
        window.analytics.identify(
          customerId,
          {
            ...traits,
            is_logged_in: !!customer,
            currency,
          },
          { context: additonal_context }
        )
      )
    }
  }

  const track = (event: Event, properties?: object) => {
    if (isTrackingEnabled) {
      withWindow(() =>
        window.analytics.track(
          event,
          {
            ...properties,
            is_logged_in: !!customer,
            currency,
          },
          { context: additonal_context }
        )
      )
    }
  }

  const tikTokTrack = (event: TikTokEvent, properties: object) => {
    if (isTrackingEnabled) {
      window.ttq.track(event, {
        ...properties,
      })
    }
  }

  const trackPage = (properties?: object) => {
    if (isTrackingEnabled) {
      withWindow(() =>
        window.analytics.page(
          location.pathname,
          {
            ...properties,
            is_logged_in: !!customer,
            currency,
          },
          { context: additonal_context }
        )
      )
    }
  }

  return {
    identify,
    track,
    trackPage,
    tikTokTrack,
  }
}
