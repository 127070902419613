import { FC, useEffect } from "react"
import styled from "styled-components"
import { useRouter } from "next/router"
import { useCustomer, useTracking, useUI } from "@hooks/index"
import dayjs from "dayjs"
import { Container, BackLink } from "@components/ui"
import { MetafieldEdge, Metafield } from "shopify-storefront-api-typings"

interface Props {
  backLink?: {
    title: string
    href: string
  }
  title?: string
  subtitle?: string
}

export const AuthContainer: FC<Props> = ({
  children,
  title,
  subtitle,
  backLink,
}) => {
  const router = useRouter()
  const { customer } = useCustomer()
  const { openLoginModal } = useUI()
  const metafields = (customer?.metafields?.edges || []).map(metafield => metafield.node)
  const loyaltyPoints = metafields.find((metafield: Metafield) => metafield.key === 'points_approved')
  const loyaltyTier = metafields.find((metafield: Metafield) => metafield.key === 'loyalty_tier')
  const { track, trackPage } = useTracking()
  useEffect(() => {
    trackPage({ category: "Account" })
  }, [router])

  useEffect(() => {
    if (!customer || customer?.customerAccessToken.accessToken === '') {
      openLoginModal()
    }
  }, [customer])

  useEffect(() => {
    if (loyaltyPoints) {
      track("Loyalty Lion", {
        points: loyaltyPoints.value,
        tier: loyaltyTier?.value,
      })
    }
  }, [loyaltyPoints])
  return (
    <>
      {customer && (
        <>
          <Header>
            <Container>
              {backLink && (
                <MobileBackLink title={backLink.title} href={backLink.href} />
              )}
              <Title>{title ? title : `Salam, ${customer?.firstName}`}</Title>
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
              {loyaltyPoints && (
                <>
                  <LoyaltyPoints>
                    You have {loyaltyPoints?.value} rewards points!
                  </LoyaltyPoints>
                  {Boolean(loyaltyTier) === true && (
                    <MemberSince>
                      <i>
                        {loyaltyTier?.value}{" "}
                        rewards member since{" "}
                        {dayjs(loyaltyPoints.createdAt).format(
                          "MMMM YYYY"
                        )}
                      </i>
                    </MemberSince>
                  )}
                </>
              )}
            </Container>
          </Header>
          <Container>{children}</Container>
        </>
      )}
    </>
  )
}

const LoyaltyPoints = styled.h3`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  line-height: 2.4rem;
  text-align: center;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.5rem;
    line-height: 3rem;
  }
`

const MemberSince = styled.div`
  text-align: center;
  font-size: 1.2rem;
  line-height: 2.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.3rem;
    line-height: 3rem;
  }
`

const Header = styled.div`
  background: ${({ theme }) => theme.color.beige};
  padding: 4rem 2rem 4.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 4rem 0 4.4rem;
  }
`
const Title = styled.h1`
  margin: 0 0 1rem;
  font-size: 3rem;
  line-height: 3.6rem;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 4rem;
    line-height: 4.8rem;
  }
`

const MobileBackLink = styled(BackLink)`
  margin: 0 auto 1.9rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const Subtitle = styled.div`
  font-size: 1.5rem;
  line-height: 2.8rem;
  text-align: center;
`
