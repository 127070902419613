import axios from "axios"
import retry from "async-retry"

import { shopifyConfig, preparePayload } from "@lib/config"

import { CUSTOMER_QUERY } from "@lib/fragments"

import {
  CUSTOMER_CREATE,
  CUSTOMER_ACCESS_TOKEN_CREATE,
  CUSTOMER_ACCESS_TOKEN_DELETE,
  CUSTOMER_RESET,
  CUSTOMER_RECOVER,
  CUSTOMER_UPDATE,
  CUSTOMER_ADDRESS_CREATE,
  CUSTOMER_ADDRESS_UPDATE,
  CUSTOMER_ADDRESS_DELETE,
} from "@lib/mutations"
import { CustomerAccessTokenCreatePayload, CustomerAccessToken, Customer, CustomerCreatePayload, CustomerRecoverPayload, CustomerResetPayload, CustomerUpdatePayload, CustomerUserError, CustomerAddressDeletePayload, CustomerAddressUpdatePayload, CustomerAddressCreatePayload } from "shopify-storefront-api-typings"

async function sendShopifyRequest(payload: object): Promise<any> {
  return retry(
    async (bail: Function) => {
      return axios({
        url: `https://secure.${process.env.NEXT_PUBLIC_SHOPIFY_STORE}.com/api/2021-07/graphql.json`,
        method: "POST",
        headers: shopifyConfig,
        data: JSON.stringify(payload),
      }).then(res => res.data.data)
  }, { retries: 5 })
}

export const customerCreate = async ({
  firstName,
  lastName,
  email,
  password,
}: {
  firstName: string
  lastName: string
  email: string
  password: string
}): Promise<CustomerCreatePayload> => {
  const payload = preparePayload(CUSTOMER_CREATE, {
    input: {
      firstName,
      lastName,
      email,
      password,
    },
  })

  const { customerCreate } = await sendShopifyRequest(payload)
  return customerCreate
}

export const customerAccessTokenCreate = async ({
  email,
  password,
}: {
  email: string
  password: string
}): Promise<CustomerAccessTokenCreatePayload> => {
  const payload = preparePayload(CUSTOMER_ACCESS_TOKEN_CREATE, {
    input: {
      email,
      password,
    }
  })

  const { customerAccessTokenCreate } = await sendShopifyRequest(payload)
  return customerAccessTokenCreate
}

export const customerAccessTokenDelete = async ({
  customerAccessToken,
}: {
  customerAccessToken: string
}): Promise<CustomerAccessTokenCreatePayload> => {
  const payload = preparePayload(CUSTOMER_ACCESS_TOKEN_DELETE, {
    customerAccessToken,
  })

  const { customerAccessTokenDelete } = await sendShopifyRequest(payload)
  return customerAccessTokenDelete
}

export type CustomerWithAccessToken = Customer & {customerAccessToken: CustomerAccessToken, updatedAt: number}

export const getCustomer = async (customerAccessToken: {
  accessToken: string
  expiresAt: string
}): Promise<CustomerWithAccessToken> => {

  const payload = preparePayload(CUSTOMER_QUERY, {
    customerAccessToken: customerAccessToken.accessToken,
  })

  const { customer }: { customer: Customer} = await sendShopifyRequest(payload)

  return {
    ...customer,
    customerAccessToken,
    updatedAt: Date.now()
  }
}

export type LoginCustomerResponse = { 
  customerAccessToken?: CustomerAccessToken, 
  customer?: Customer, 
  customerUserErrors?: CustomerUserError[],
  updatedAt?: number
}

export const loginCustomer = async ({
  email,
  password,
}: {
  email: string
  password: string
}): Promise<LoginCustomerResponse> => {
  const { customerAccessToken, customerUserErrors } = await customerAccessTokenCreate({ email, password })

  if (customerUserErrors.length) {
    return { customerUserErrors }
  }

  const customer = await getCustomer(customerAccessToken as CustomerAccessToken)

  return {
    customerAccessToken,
    customer,
    updatedAt: Date.now()
  } as LoginCustomerResponse
}

export const customerRecover = async ({ email }: { email: string }): Promise<CustomerRecoverPayload> => {
  const payload = preparePayload(CUSTOMER_RECOVER, {
    email,
  })
  const { customerRecover } = await sendShopifyRequest(payload)
  return customerRecover
}

export const customerReset = async ({
  id,
  customerResetInput,
}: {
  id: string
  customerResetInput: {
    password: string
    resetToken: string
  }
}): Promise<CustomerResetPayload> => {
  const payload = preparePayload(CUSTOMER_RESET, {
    id,
    input: customerResetInput,
  })
  const { customerReset } = await sendShopifyRequest(payload)
  return customerReset
}

export const customerUpdate = async ({
  customerAccessToken,
  customer,
}: {
  customerAccessToken: string
  customer: {
    acceptsMarketing?: boolean
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    phone?: string
  }
}): Promise<CustomerUpdatePayload> => {
  const payload = preparePayload(CUSTOMER_UPDATE, {
    customerAccessToken,
    customer,
  })
  const { customerUpdate } = await sendShopifyRequest(payload)
  return customerUpdate
}

export const customerAddressCreate = async ({
  customerAccessToken,
  address,
}: {
  customerAccessToken: string
  address: {
    address1?: string
    address2?: string
    city?: string
    company?: string
    country?: string
    firstName?: string
    lastName?: string
    phone?: string
    province?: string
    zip?: string
  }
}): Promise<CustomerAddressCreatePayload> => {
  const payload = preparePayload(CUSTOMER_ADDRESS_CREATE, {
    customerAccessToken,
    address,
  })
  const { customerAddressCreate } = await sendShopifyRequest(payload)
  return customerAddressCreate
}

export const customerAddressUpdate = async ({
  customerAccessToken,
  id,
  address,
}: {
  customerAccessToken: string
  id: string
  address: {
    address1?: string
    address2?: string
    city?: string
    company?: string
    country?: string
    province?: string
    firstName?: string
    lastName?: string
    phone?: string
    zip?: string
  }
}): Promise<CustomerAddressUpdatePayload> => {
  const payload = preparePayload(CUSTOMER_ADDRESS_UPDATE, {
    customerAccessToken,
    id,
    address,
  })
  const { customerAddressUpdate } = await sendShopifyRequest(payload)
  return customerAddressUpdate
}

export const customerAddressDelete = async ({
  customerAccessToken,
  id,
}: {
  customerAccessToken: string
  id: string
}): Promise<CustomerAddressDeletePayload> => {
  const payload = preparePayload(CUSTOMER_ADDRESS_DELETE, {
    customerAccessToken,
    id,
  })

  const { customerAddressDelete } = await sendShopifyRequest(payload)

  return customerAddressDelete
}
