import React from "react"
import NextLink from "next/link"

import { useUI, useTracking } from "@hooks/index"
import { ContentfulLink } from "@lib/types"

import styled from 'styled-components'

export const Link = ({
  link,
  trackingProperties,
}: {
  link: ContentfulLink
  trackingProperties?: {}
}) => {
  const { closeNavSidebar } = useUI()
  const { track } = useTracking()
  const { title, boldTitle, entry, href } = link
  if (entry) {
    if (entry?.__typename === "InfoPage") {
      return (
        <NextLink href={`/pages/${entry.slug}`} passHref>
          <StyledLink
            onClick={() => {
              closeNavSidebar()
              track("Link Clicked", {
                url: `https://www.hautehijab.com/pages/${entry.slug}`,
                label: title,
                ...trackingProperties,
              })
            }}
          >
            {boldTitle ? <b>{title}</b> : <>{title}</>}
          </StyledLink>
        </NextLink>
      )
    }
    if (entry?.__typename === "Product") {
      return (
        <NextLink href={`/products/${entry.slug}`} passHref>
          <StyledLink
            onClick={() => {
              closeNavSidebar()
              track("Link Clicked", {
                url: `https://www.hautehijab.com/products/${entry.slug}`,
                label: title,
                ...trackingProperties,
              })
            }}
          >
            {boldTitle ? <b>{title}</b> : <>{title}</>}
          </StyledLink>
        </NextLink>
      )
    }

    return (
      <NextLink href={`/${entry.slug}`} passHref>
        <StyledLink
          onClick={() => {
            closeNavSidebar()
            track("Link Clicked", {
              url: `https://www.hautehijab.com/${entry.slug}`,
              label: title,
              ...trackingProperties,
            })
          }}
        >
          {boldTitle ? <b>{title}</b> : <>{title}</>}
        </StyledLink>
      </NextLink>
    )
  }

  if (href) {
    return (
      <StyledLink
        href={href}
        onClick={() => {
          track("Link Clicked", {
            url: href,
            label: title,
            ...trackingProperties,
          })
        }}
      >
        {boldTitle ? <b>{title}</b> : <>{title}</>}
      </StyledLink>
    )
  }

  return (
    <StyledLink
      href={"#"}
      onClick={() => {
        track("Link Clicked", {
          url: href,
          label: title,
          ...trackingProperties,
        })
      }}
    >
      {boldTitle ? <b>{title}</b> : <>{title}</>}
    </StyledLink>
  )
}

const StyledLink = styled.a`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`