import { FC } from "react"
import styled from "styled-components"

import Image from "next/image"
import { ContentfulImage } from "@lib/types"

interface Props {
  data: {
    name: string
    slug: string
    excerpt: string
    featuredImage: ContentfulImage
  }
  showExcerpt: boolean
}

export const CollectionThumbnail: FC<Props> = ({ data, showExcerpt }) => {
  const { name, slug, excerpt, featuredImage } = data
  return (
    <Article>
      <ImageWrapper>
        {featuredImage?.url && (
          <Image
            src={featuredImage.url}
            alt={featuredImage.description}
            layout="fill"
          />
        )}
        <TextWrapper>
          <Title>{name}</Title>
          <Excerpt $display={showExcerpt}>{excerpt}</Excerpt>
        </TextWrapper>
      </ImageWrapper>
    </Article>
  )
}

interface ExcerptProps {
  $display: boolean
}

const Article = styled.article``

const Excerpt = styled.p<ExcerptProps>`
  opacity: ${({ $display }) => ($display ? 1 : 0)};
  height: ${({ $display }) => ($display ? "auto" : 0)};
  font-size: 1.2rem;
  line-height: 2rem;
  transition: opacity 0.6s ease-out;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 1.5rem;
    line-height: 2.8rem;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20rem;
  background: ${({ theme }) => theme.color.grey};
  cursor: pointer;

  img {
    object-fit: cover;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    height: 31rem;

    &:hover {
      ${Excerpt} {
        opacity: 1;
        height: auto;
      }
    }
  }
`

const TextWrapper = styled.div`
  position: absolute;
  text-align: center;
  max-width: 15.4rem;
  color: ${({ theme }) => theme.color.white};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    max-width: 24.3rem;
  }
`

const Title = styled.h1`
  margin: 0 0 0.5rem;
  text-transform: uppercase;
  font-size: 1.8rem;
  line-height: 2.3rem;
  letter-spacing: 0.12em;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 1.4rem;
    font-size: 2.4rem;
    line-height: 3.1rem;
  }
`
