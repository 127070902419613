import { FC, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { LazyMotion, m } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

import { motion } from "framer-motion"
import { decode } from "shopify-gid"
import { SpinningLoader } from "@components/ui"

import type {
  ProductVariant,
  ProductVariantEdge,
} from "shopify-storefront-api-typings"

import { useDictionary, useCheckout, useUI, useTracking, useCustomer } from "@hooks/index"
import { getOptionValue } from "@lib/utils"

interface Props {
  variants: ProductVariantEdge[]
  trackingInfo: {
    name: string
    category: string
    url: string
  }
}

const container = {
  hidden: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
  visible: {
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
}

const item = {
  hidden: {
    y: 0,
    opacity: 0,
    transition: {
      delay: 0.2,
    },
  },
  visible: (i: number) => ({
    y: i * -50,
    opacity: 1,
  }),
}

export const AddToBagButton: FC<Props> = ({ trackingInfo, variants }) => {
  
  const { id, addLineItem } = useCheckout()
  const dictionary = useDictionary()
  const { track, tikTokTrack } = useTracking()
  const {currency} = useCustomer()
  const {openCart, clickAddToBag} = useUI()

  // Create array of states for loading based on how many variants are provided, 
  // defaulting to false (i.e. If given an array of 3 variants, default state is [false, false, false])
  const [loadingArray, setLoadingArray] = useState(variants.map(variant => false))

  const [hover, setHover] = useState(false)
  
  const addToBag = async (node: ProductVariant, i: any) => {
    const newLoadingArray = loadingArray
    newLoadingArray[i] = true
    setLoadingArray(newLoadingArray)
    await addLineItem({ quantity: 1, variantId: node.id })
    newLoadingArray[i] = false
    setLoadingArray(newLoadingArray)
    openCart()
    clickAddToBag()

    track("Product Added", {
      ...trackingInfo,
      cart_id: decode(id).id,
      product_id: decode(node?.id).id,
      variant: node?.title,
      sku: node?.sku,
      price: parseFloat(node?.priceV2?.amount),
      brand: "Haute Hijab",
      quantity: 1,
      image_url: node?.image?.transformedSrc,
    })

    const currencyAdjustedPrice = node?.presentmentPrices.edges.find((edge: {node: {price: {currencyCode: string}}}) => edge.node.price.currencyCode === currency)?.node.price.amount || null;
    tikTokTrack("AddToCart", {
      content_type: "product",
      content_id: decode(node?.id).id,
      content_name: node?.title,
      currency: currency,
      price: currencyAdjustedPrice,
      value: currencyAdjustedPrice,
      quantity: 1
    })
  }
  const isProductSoldOut = variants.every((edge: any) => !edge.node.availableForSale)

  return (
    <LazyMotion strict features={loadFeatures}>
      <ButtonContainer
        variants={container}
        $height={variants.length * 50}
        onMouseOver={() => isProductSoldOut || setHover(true)}
        onMouseOut={() => isProductSoldOut || setHover(false)}
        onTouchStart={() => isProductSoldOut || setHover(hover => !hover)}
      >
        {variants.map(({ node }, i) => {
          const text = !node.availableForSale ? (
            <>
              {getOptionValue(node.selectedOptions)}
              <small>(Sold out)</small>
            </>
          ) : (
            getOptionValue(node.selectedOptions)
          )

          return i === 0 ? (
            <Button
              key={i}
              onClick={() => node.availableForSale && addToBag(node, i)}
              disabled={isProductSoldOut || loadingArray[i]}
            >
              {!loadingArray[i] && <MainText $hover={variants.length > 1 ? hover : false}>{isProductSoldOut ? "Sold out" : dictionary.addToBag}</MainText>}
              {!loadingArray[i] && <HiddenText $hover={variants.length > 1 ? hover : false}>{text}</HiddenText>}
              {loadingArray[i] && <SpinningLoader/>}
            </Button>
          ) : (
            <HiddenButton
              key={i}
              variants={item}
              animate={hover ? item.visible(i) : "hidden"}
              onClick={() => node.availableForSale && addToBag(node, i)}
              disabled={loadingArray[i]}
            >
              {!loadingArray[i] && text}
              {loadingArray[i] && <SpinningLoader/>}
            </HiddenButton>
          )
        })}
      </ButtonContainer>
    </LazyMotion>
  )
}

interface StyleProps {
  $height: number
}

const MainText = styled.div<{ $hover?: boolean }>`
  transform: translate3d(0, ${({ $hover }) => $hover ? "-160%" : "0"}, 0);
  transition: transform ease-out 0.25s;  
`

const HiddenText = styled.div<{ $hover?: boolean }>`
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  transform: translate3d(0, ${({ $hover }) => $hover ? "-60%" : "120%"}, 0);
  transition: transform ease-out 0.25s;
  direction: initial;
`

const buttonStyles = css`
  position: relative;
  overflow: hidden;
  z-index: 10;
  display: inline-block;
  height: 4.5rem;
  padding: 0 0.5rem;
  width: 100%;
  border-radius: ${({ theme }) => theme.button.white.borderRadius}rem;
  border: 1px solid;
  border-color: ${({ theme }) => theme.button.white.borderColor};
  color: ${({ theme }) => theme.button.white.color};
  background: ${({ theme }) => theme.button.white.background};
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.185rem;
  line-height: 1.6rem;
  cursor: pointer;
  transition: ease-out 0.25s;

  b {
    font-weight: 700;
  }

  &:hover {
    border-color: ${({ theme }) => theme.button.white.borderColorHover};
    color: ${({ theme }) => theme.button.white.colorHover};
    background: ${({ theme }) => theme.button.white.backgroundHover};
  }
  
  &:disabled {
    background: ${({ theme }) => theme.color.greyLight};
    border-color: ${({ theme }) => theme.color.greyLight};
    color: ${({ theme }) => theme.color.grey};
    cursor: auto;
  }
`

const Button = styled(m.button)`
  ${buttonStyles}
`

const HiddenButton = styled(m.button)`
  ${buttonStyles}
  z-index: 9;
  position: absolute;
  left: 0;
  direction: initial;
`

const ButtonContainer = styled(m.div)<StyleProps>`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: ${({ $height }) => `${$height}px`};
`
