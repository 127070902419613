import { FC } from "react"
import styled from "styled-components"
import Star from "@svg/star.svg"

interface Props {
  rating: number
}

export const Rating: FC<Props> = ({ rating }) => {
  return (
    <StyledRating>
      {[...Array(5)].map((s, i) => {
        return <RatingStar key={i} $fill={i + 1 <= rating} />
      })}
    </StyledRating>
  )
}

interface SVGProps {
  $fill: boolean
}

const StyledRating = styled.div``

const RatingStar = styled(Star)<SVGProps>`
  margin: 0 0.4rem;

  path {
    fill: ${({ theme, $fill }) => ($fill ? "#070707" : theme.color.grey)};
  }
`
