import { FC, createContext, useMemo, useReducer } from "react"
import { useTracking } from "@hooks/index"
interface State {
  displayCart: boolean
  displayLoginModal: boolean
  displayAltLoginHeader: boolean
  displayNavSidebar: boolean
  displayCountryModal: boolean
  displaySearchModal: boolean
  cartInteracted: boolean
  addToBagClicked: boolean
}

const initialState = {
  displayCart: false,
  displayLoginModal: false,
  displayAltLoginHeader: false,
  displayNavSidebar: false,
  displayCountryModal: false,
  displaySearchModal: false,
  cartInteracted: false,
  addToBagClicked: false
}

type Action =
  | {
      type: "OPEN_CART"
    }
  | {
      type: "CLOSE_CART"
    }
  | {
      type: "OPEN_NAV_SIDEBAR"
    }
  | {
      type: "CLOSE_NAV_SIDEBAR"
    }
  | {
      type: "OPEN_LOGIN_MODAL"
      payload: boolean
    }
  | {
      type: "CLOSE_LOGIN_MODAL"
    }
  | {
      type: "OPEN_COUNTRY_MODAL"
    }
  | {
      type: "CLOSE_COUNTRY_MODAL"
    }
  | {
      type: "OPEN_SEARCH"
    }
  | {
      type: "CLOSE_SEARCH"
    }
  | {
      type: "INTERACT_CART"
    }
  | {
      type: "ADD_TO_BAG_CLICKED"
    }

export const UIContext = createContext<State | any>(initialState)

function uiReducer(state: State, action: Action) {
  switch (action.type) {
    case "OPEN_CART": {
      return {
        ...state,
        displayCart: true,
      }
    }
    case "CLOSE_CART": {
      return {
        ...state,
        displayCart: false,
        cartInteracted: false,
        addToBagClicked: false,
      }
    }
    case "OPEN_NAV_SIDEBAR": {
      return {
        ...state,
        displayNavSidebar: true,
      }
    }
    case "CLOSE_NAV_SIDEBAR": {
      return {
        ...state,
        displayNavSidebar: false,
      }
    }
    case "OPEN_LOGIN_MODAL": {
      return {
        ...state,
        displayAltLoginHeader: action.payload,
        displayLoginModal: true,
      }
    }
    case "CLOSE_LOGIN_MODAL": {
      return {
        ...state,
        displayLoginModal: false,
      }
    }
    case "OPEN_COUNTRY_MODAL": {
      return {
        ...state,

        displayCountryModal: true,
      }
    }
    case "CLOSE_COUNTRY_MODAL": {
      return {
        ...state,
        displayCountryModal: false,
      }
    }
    case "OPEN_SEARCH": {
      return {
        ...state,
        displaySearchModal: true,
      }
    }
    case "CLOSE_SEARCH": {
      return {
        ...state,
        displaySearchModal: false,
      }
    }
    case "INTERACT_CART": {
      return {
        ...state,
        cartInteracted: true,
      }
    }
    case "ADD_TO_BAG_CLICKED": {
      return {
        ...state,
        addToBagClicked: true,
      }
    }
  }
}

export const UIProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(uiReducer, initialState)
  const { track } = useTracking()

  const openCart = () => {
    dispatch({ type: "OPEN_CART" })
  }
  const closeCart = () => dispatch({ type: "CLOSE_CART" })

  const toggleCart = () => (state.displayCart ? closeCart() : closeCart())

  const openNavSidebar = () => dispatch({ type: "OPEN_NAV_SIDEBAR" })
  const closeNavSidebar = () => dispatch({ type: "CLOSE_NAV_SIDEBAR" })
  const toggleNavSidebar = () =>
    state.displayNavSidebar ? closeNavSidebar() : openNavSidebar()

  const openLoginModal = (displayAltLoginHeader = false) => {
    dispatch({
      type: "OPEN_LOGIN_MODAL",
      payload: displayAltLoginHeader,
    })
    track("Login Modal Open")
  }

  const closeLoginModal = () => {
    dispatch({ type: "CLOSE_LOGIN_MODAL" })
    track("Login Modal Closed")
  }
  const openSearchModal = () => dispatch({ type: "OPEN_SEARCH" })
  const closeSearchModal = () => dispatch({ type: "CLOSE_SEARCH" })

  const openCountryModal = () => {
    dispatch({
      type: "OPEN_COUNTRY_MODAL",
    })
    track("Country Modal Open")
  }

  const closeCountryModal = () => {
    dispatch({ type: "CLOSE_COUNTRY_MODAL" })
    track("Country Modal Closed")
  }

  const interactCart = () => {
    dispatch({type: "INTERACT_CART"})
  }

  const clickAddToBag = () => {
    dispatch({type: "ADD_TO_BAG_CLICKED"})
  }

  const value = useMemo(
    () => ({
      ...state,
      openCart,
      closeCart,
      toggleCart,
      openNavSidebar,
      closeNavSidebar,
      toggleNavSidebar,
      openLoginModal,
      closeLoginModal,
      openCountryModal,
      closeCountryModal,
      openSearchModal,
      closeSearchModal,
      interactCart,
      clickAddToBag
    }),
    [state]
  )

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>
}
