import { FC } from 'react';
import styled from 'styled-components';
import { ContentBlock, ContentfulProductWithShopifyData, Color } from '@lib/types';

import { ImageThumbnail, ProductThumbnail, SectionWithTitle } from '@components/ui';

interface Props {
  data: {
    __typename: string;
    preTitle: string;
    title: string;
    backgroundColor: Color;
    blocksCollection: {
      items: (ContentBlock | ContentfulProductWithShopifyData)[];
    };
  };
}

export const Products: FC<Props> = ({ data }) => {
  const {
    backgroundColor,
    preTitle,
    title,
    blocksCollection: { items },
  } = data;
  return (
    <SectionWithTitle title={title} preTitle={preTitle} backgroundColor={backgroundColor}>
      <ThumbnailList>
        {items.map((block, i) => {
          if (block.__typename === 'ContentBlock') {
            return <ImageThumbnail key={`${block.sys.id}${i}`} data={block} />;
          }

          if (block.__typename === 'Product') {
            return <ProductThumbnail key={`${block.sys.id}${i}`} product={block} />;
          }
        })}
      </ThumbnailList>
    </SectionWithTitle>
  );
};

const ThumbnailList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  grid-gap: 2.9rem 1.1rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 2.7rem;
  }
`;
