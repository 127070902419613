import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"
import { ContentBlock } from "@lib/types"

import { TextBlock, ThumbnailLink } from "@components/common"
import { Button, PreText } from "@components/ui"
import { InstagramSliderWrapper } from "@components/page"

interface Props {
  data: ContentBlock
}

export const CommunityThumbnail: FC<Props> = ({ data }) => {
  const { image, preText, textBlock, button, linkForImage } = data

  const textAlign = textBlock.textAlign

  const imageWrapper = (
    <ImageWrapper>
      <Image
        src={image.url}
        alt={image.description}
        width={320}
        height={320}
        layout="responsive"
      />
    </ImageWrapper>
  );

  return (
    <Article>
      {linkForImage ? <ThumbnailLink linkForImage={linkForImage} imageWrapper={imageWrapper}/> : imageWrapper}
      <PreText preText={preText} textAlign={textAlign} />
      <TextWrapper $textAlign={textAlign.toLowerCase()}>
        <TextBlock textBlock={textBlock} />
      </TextWrapper>
      {button && (
        <ButtonWrapper $align={textAlign}>
          <Button button={button} />
        </ButtonWrapper>
      )}
    </Article>
  )
}

interface TextProps {
  $textAlign: string
}

interface ButtonProps {
  $align: string
}

const Article = styled.article``

const ImageWrapper = styled.div`
  margin: 0 0 2.3rem;
`

const TextWrapper = styled.div<TextProps>`
  white-space: pre-wrap;
  text-align: ${({ $textAlign }) => $textAlign};

  h1,
  h2,
  h3,
  h4 {
    font-size: 2.4rem;
    line-height: 3.1rem;
    font-weight: 400;
  }

  p {
    margin: 0 0 1.9rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  ${InstagramSliderWrapper} & {
    p {
      color: ${({ theme }) => theme.color.greyBlack};

      &:last-child {
        a {
          font-size: 1.8rem;
          letter-spacing: 0.04em;
          font-family: ${({ theme }) => theme.font.heading};
        }
      }
    }
  }
`

const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  justify-content: ${({ $align }) =>
    $align === "left"
      ? "flex-start"
      : $align === "right"
      ? "flex-end"
      : "center"};
`
