import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"
import { useRouter } from "next/router"
import { useDictionary, useUI, useSite } from "@hooks/index"
import { CountryModal } from "@components/common"

export const CountryToggle: FC = () => {
  const router = useRouter()
  const dictionary = useDictionary()
  const { openCountryModal } = useUI()
  const {
    countriesCollection: { items: countries },
  } = useSite()

  const country = countries.find(
    ({ value }: { value: string }) => value === router?.locale
  )

  return (
    <>
      <Button type="button" onClick={openCountryModal}>
        <FlagWrapper>
          <Image
            src={country?.image?.url}
            width={22}
            height={22}
            alt={country?.image?.description}
            layout="fixed"
          />
        </FlagWrapper>
        {dictionary.change}
      </Button>
      <CountryModal />
    </>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 1.1rem 2rem 1.1rem 1.1rem;
  color: ${({ theme }) => theme.color.white};
  border: ${({ theme }) => `1px solid ${theme.color.greyDark}`};
  border-radius: 0.3rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;

  svg {
    margin: 0 1.4rem 0 0;
  }
`

const FlagWrapper = styled.div`
  margin-right: 1.4rem;
`
