import { FC, ChangeEvent } from "react"
import Link from "next/link"
import styled from "styled-components"
import { Color as C } from "@lib/types"

interface BaseProps {
  className?: string
  isActive: boolean
  color: C
}

interface Checkbox extends BaseProps {
  type: "checkbox"
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

interface Link extends BaseProps {
  type: "link"
  href: string
}

type Props = Checkbox | Link

export const ColorSwatch: FC<Props> = props => {
  const { className, type, color, isActive } = props
  const { onChange } = props as Checkbox
  const { href } = props as Link

  return (
    <ColorWrapper className={className} $isActive={isActive}>
      {type === "checkbox" && (
        <Color className="color">
          {color?.value && (
            <Background $background={color.value}>
              <Input
                type="checkbox"
                id={color.name}
                name="color"
                aria-label={color.name}
                value={color.name}
                onChange={e => onChange(e)}
              />
            </Background>
          )}
          {color?.image?.url && (
            <>
              <Input
                type="checkbox"
                id={color.name}
                name="color"
                aria-label={color.name}
                value={color.name}
                onChange={e => onChange(e)}
              />
              <img
                style={{ position: "absolute" }}
                src={color.image.url}
                alt={color.image.description}
              />
            </>
          )}
        </Color>
      )}
      {type === "link" && (
        <Link href={href}>
          <a>
            <Color className="color">
              {color?.value && <Background $background={color.value} />}
              {color?.image?.url && (
                <img src={color.image.url} alt={color.image.description} />
              )}
            </Color>
          </a>
        </Link>
      )}
    </ColorWrapper>
  )
}

interface WrapperProps {
  $isActive: boolean
}

const ColorWrapper = styled.div<WrapperProps>`
  display: grid;
  place-items: center;
  border-radius: 50%;
  width: 3.4rem;
  height: 3.4rem;
  border: 1px solid;
  border-color: ${({ $isActive, theme }) =>
    $isActive ? theme.color.grey : "transparent"};
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 3.8rem;
    height: 3.8rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    width: 3.8rem;
    height: 3.8rem;
  }
`

const Color = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0pt 1px #acacac;
  border-radius: 50%;
  width: 2.6rem;
  height: 2.6rem;

  img {
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 3rem;
    height: 3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    width: 3rem;
    height: 3rem;
  }
`

const Background = styled.div<{ $background: string }>`
  background: ${({ $background }) => $background};
  width: 100%;
  height: 100%;
`

const Input = styled.input`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
`
