import { CHECKOUT_QUERY } from "@lib/fragments"

export const CHECKOUT_ATTRIBUTES_UPDATE_V2 = `
  mutation checkoutAttributesUpdateV2($checkoutId: ID!, $input: CheckoutAttributesUpdateV2Input!) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkout {
        ${CHECKOUT_QUERY}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
