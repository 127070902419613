export const getLocalePaths = (
  slugs: string[],
  locales?: string[]
) => {
  const basePaths = slugs
    .filter((slug: string) => slug !== "/")
    .map((slug: string ) => ({
      params: { slug },
    }))

  const paths = locales
    ? locales.flatMap(locale =>
        slugs.map((slug: string ) => ({
          params: { slug },
          locale,
        }))
      )
    : basePaths

  return paths
}

export const countryCodeToLocale = {
  us: "en-US",
  uk: "en-GB",
} as { [key: string]: string }

export const mapLocaleToCurrencyCode = {
  "en-US": "USD",
  "en-GB": "GBP",
} as { [key: string]: string }
