import { FC } from "react"
import styled from "styled-components"
import { TextBlock } from "@lib/types"

import { RichText } from "@components/common"
import { Container } from "@components/ui"

interface Props {
  data: {
    __typename: string
    missionStatement: string
    textBlocksCollection: {
      items: TextBlock[]
    }
  }
}

export const ValueProposition: FC<Props> = ({ data }) => {
  const {
    missionStatement,
    textBlocksCollection: { items },
  } = data
  return (
    <StyledValueProposition>
      <Container>
        <ValuePropInner>
          <MissionStatement>
            <p>{missionStatement}</p>
          </MissionStatement>
          <TextBlocks>
            {items.map(({ sys, richText }) => (
              <TextWrapper key={sys.id}>
                <RichText richText={richText} />
              </TextWrapper>
            ))}
          </TextBlocks>
        </ValuePropInner>
      </Container>
    </StyledValueProposition>
  )
}

const StyledValueProposition = styled.section``

const ValuePropInner = styled.div`
  display: grid;
  grid-template-columns: auto;
  padding: 4.3rem 3rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    grid-template-columns: auto auto;
    gap: 0 6.8rem;
    padding: 8.6rem 2rem 7.2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.xl}px) {
    padding: 8.6rem 12rem 7.2rem;
  }
`

const MissionStatement = styled.div`
  text-align: center;
  white-space: pre-wrap;
  font-family: ${({ theme }) => theme.font.heading};
  font-size: 2.4rem;
  line-height: 3.1rem;
  font-weight: 400;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 1rem;
    font-size: 3rem;
    line-height: 3.9rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    text-align: left;
  }
`

const TextBlocks = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 6.8rem;
  }
`

const TextWrapper = styled.div`
  h2,
  h3,
  h4 {
    font-size: 2.2rem;
    line-height: 2.6rem;
    letter-spacing: 1px;
  }

  p {
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: ${({ theme }) => theme.color.grey};
  }
`
