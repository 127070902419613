import { BUTTON_QUERY, TEXT_BLOCK_QUERY } from "@lib/fragments"
import { CONTENTFUL_IMAGE_QUERY, LINK_QUERY, PAGE_SLUGS_QUERY } from "./common"

export const HEADER_QUERY = `
menusCollection(limit: 5) {
    items {
      link {
        ${LINK_QUERY}
      }
      menuItemsCollection(limit: 5) {
        items {
          category
          linksCollection(limit: 50) {
            items {
              ${LINK_QUERY}
            }
          }          
        }
      }
      image {
        ${CONTENTFUL_IMAGE_QUERY}
      }
      button {
        ${BUTTON_QUERY}
      }
    }
  }
`
export const ANNOUNCEMENTBARS_QUERY = `
  announcementsCollection(limit: 5) {
    items {
      ... on TextBlock {
        richText {
          json
          links {
            entries {
              inline {
                __typename
                ... on Link {
                  __typename
                  sys {
                    id
                  }
                  title
                  entry {
                   ${PAGE_SLUGS_QUERY}
                    ... on TextBlock {
                      ${TEXT_BLOCK_QUERY}
                    }
                  }
                }
              }
              hyperlink {
                __typename
                sys {
                  id
                }
                ${PAGE_SLUGS_QUERY}
              }
            }
          }
        }
      }
    }
  }
  `

export const ANNOUNCEMENTBAR_QUERY = `
  announcementBar {
    json
    links {
      entries {
        inline {
          __typename
          ... on Link {
            __typename
            sys {
              id
            }
            title
            entry {
             ${PAGE_SLUGS_QUERY}
              ... on TextBlock {
                ${TEXT_BLOCK_QUERY}
              }
            }
          }
        }
      }
    }
  }
`

export const FOOTER_QUERY = `
footerText {
    json
  }  
  footerMenusCollection(limit: 5) {
    items {
      category
      linksCollection {
        items {
         ${LINK_QUERY}
        }
      }
    }
  }
  footerContact {
    json
  }
  footerBottomLinksCollection(limit: 5) {
    items {
     ${LINK_QUERY}
    }
  }
  footerCopyright
`

export const SCRIPTS_QUERY = `
  scriptTags(first:50) {
    edges {
      node {
        id
        src
        displayScope
      }
    }
  }
`
