import { FC, createContext, useEffect, useState } from "react"

const GEOIP_REQUEST_URL =
  "https://api.ipstack.com/check?access_key=ac2ee23a92f61c39223e2da417762f65"

  interface IPStackResponse {
    ip: string
    country_code: string
    country_name: string
    region_name: string
    region_code: string
    city: string
    time_zone: {
      id: string
      code: string
    }
    currency: {
      code: string
      symbol: string
    }
    location: {
      languages: [{ code: string }]
    }
    locale: string
  }
  

export const GeoIpContext = createContext({})

function appendLocale(geoIp: IPStackResponse) {
  let locale = null
  if (geoIp.location?.languages?.length) {
      locale =`${geoIp.location.languages[0].code}-${geoIp.country_code}`
  }
  return { ...geoIp, locale }
}

export const GeoIpProvider: FC = ({ children }) => {
  const [result, setResult] = useState({} as any)
  useEffect(() => {
    fetch(GEOIP_REQUEST_URL)
      .then(res => res.json())
      .then(response => setResult(appendLocale(response)))
      .catch(e => {
        setResult({ error: e })
      })
  }, [])

  return (
    <GeoIpContext.Provider value={result}>{children}</GeoIpContext.Provider>
  )
}
