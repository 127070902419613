import { CHECKOUT_QUERY } from "@lib/fragments"

export const CHECKOUT_DISCOUNT_CODE_APPLY_V2 = `
  mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
      checkout {
        ${CHECKOUT_QUERY}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
