import { FC } from "react"
import styled from "styled-components"
import { RichText } from "@components/common"

interface Props {
  richText: any
}

export const Placeholder: FC<Props> = ({ richText }) => {
  return (
    <Container>
      <RichText richText={richText} />
    </Container>
  )
}


const Container = styled.div`
  p {
    font-size: 1.5rem;
    line-height: 2.5rem;

    &:first-child {
      margin: 0.9rem 0 3rem;
    }
  }

  .placeholder-thumbnail:nth-of-type(2n) {
    margin-right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    p:first-child {
      margin: 1.3rem 0 4rem;
    }

    .placeholder-thumbnail {
      &:nth-of-type(2n) {
        margin-right: 2rem;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
`