import { CHECKOUT_QUERY } from "@lib/fragments"

export const CHECKOUT_LINE_ITEMS_REMOVE = `
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
      checkout {
        ${CHECKOUT_QUERY}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
