import * as Sentry from "@sentry/react"

export const useLogToSentry = () => {
  const logToSentry = (message: any, tagValue: string, data: any) => {
    try {
      Sentry.captureException(message, {
        tags: {
          askphill: tagValue,
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return logToSentry
}
