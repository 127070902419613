import { FC } from "react"
import styled from "styled-components"
import { useDictionary } from "@hooks/index"
import { SpinningLoader } from "@components/ui"


import Plus from "@svg/plus.svg"

interface Props {
  addToCart: () => void
  loading: boolean
}

export const UpsellButton: FC<Props> = ({ addToCart, loading }) => {
  const dictionary = useDictionary()
  return (
    <Button disabled={loading} onClick={() => addToCart()}>
      {loading && <SpinningLoader />}
      <span>{dictionary.add}</span>
      <Plus />
    </Button>
  )
}

const Button = styled.button`
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.black};
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.3rem;
  transition: all ease-out 0.25s;
  cursor: pointer;

  span {
    display: none;
  }

  &:hover {
    background: ${({ theme }) => theme.color.white};

    span {
      color: ${({ theme }) => theme.color.black};
    }

    svg {
      path {
        stroke: ${({ theme }) => theme.color.black};
      }
    }
  }

  &:disabled {
    background: ${({ theme }) => theme.color.greyLight};
    border-color: ${({ theme }) => theme.color.greyLight};
    color: ${({ theme }) => theme.color.grey};
    cursor: auto;
    span, svg {
      visibility: hidden;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: auto;
    height: auto;
    padding: 1rem;

    span {
      font-family: ${({ theme }) => theme.font.primary};
      display: inline;
      font-size: 1.2rem;
      margin: 0.4rem 1rem 0 0;
      color: ${({ theme }) => theme.color.white};
      text-transform: uppercase;
    }
  }
`
