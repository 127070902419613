import { FC } from "react"
import styled from "styled-components"
import Image from "next/image"
import { ContentfulImage, ContentfulButton } from "@lib/types"

import { useTracking } from "@hooks/index"

import { Button } from "@components/ui"

interface Props {
  className?: string
  image: ContentfulImage
  button: ContentfulButton
}

export const Tile: FC<Props> = ({ className, image, button }) => {
  const { track } = useTracking()

  return (
    <StyledTile className={className}>
      {image?.url && (
        <Image
          src={image.url}
          alt={image.description}
          height={198}
          width={325}
          layout="responsive"
        />
      )}
      <ButtonWrapper
        onClick={() =>
          track("Nav Feature Clicked", { label: button?.link?.title })
        }
      >
        <Button button={button} />
      </ButtonWrapper>
    </StyledTile>
  )
}

const StyledTile = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 32.5rem;
`

const ButtonWrapper = styled.div`
  position: absolute;
  left: 2rem;
  bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    left: 2.8rem;
  }
`
