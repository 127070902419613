import { FC, useState, useContext } from "react"
import styled from "styled-components"
import Link from "next/link"
import { useRouter } from "next/router"

import { routes } from "@lib/config"
import { useCustomer, useTracking } from "@hooks/index"
import { SiteContext } from "@lib/contexts"

import { RichText } from "@components/common"
import { AccordionItem } from "@components/ui"

const NavList: FC = () => {
  const { logoutCustomer } = useCustomer()
  const { track } = useTracking()
  const router = useRouter()

  const logout = async () => {
    await router.push("/")
    logoutCustomer()
  }

  return (
    <ul>
      {routes.map(({ title, href }) => {
        return (
          <NavItem key={title} $isActive={href === router.route.substring(1)}>
            <Link href={`/${href}`} passHref>
              <a
                onClick={() =>
                  track("Link Clicked", {
                    url: `https://www.hautehijab.com/${href}`,
                    name: title,
                  })
                }
              >
                {title}
              </a>
            </Link>
          </NavItem>
        )
      })}
      <NavItem>
        <button
          onClick={() => {
            logout()
            track("Button Clicked", {
              type: "Button",
              label: "Logout",
            })
          }}
        >
          Logout
        </button>
      </NavItem>
    </ul>
  )
}

export const AccountNav: FC = () => {
  const [isActive, setIsActive] = useState(true)
  const site = useContext(SiteContext)

  return (
    <StyledSidebar>
      <Mobile>
        <AccordionItem
          label="My Account"
          isActive={isActive}
          displayChevron
          onClick={() => setIsActive(!isActive)}
        >
          <NavList />
        </AccordionItem>
      </Mobile>
      <Desktop>
        <Title>My Account</Title>
        <NavList />
        <Footer>
          {site?.accountSidebarFooter && (
            <RichText richText={site.accountSidebarFooter} />
          )}
        </Footer>
      </Desktop>
    </StyledSidebar>
  )
}

const StyledSidebar = styled.aside`
  flex-shrink: 0;

  .accordion-item__label {
    font-weight: 300;
  }

  .accordion-item__body {
    border-bottom: 1px solid ${({ theme }) => theme.color.greyLight};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 25rem;
    margin-right: 11rem;
  }
`

const Mobile = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const Desktop = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
  }
`

const Title = styled.h2`
  font-size: 2.4rem;
  line-height: 2.88rem;
  font-weight: 300;
`

const NavItem = styled.li<{ $isActive?: boolean }>`
  line-height: 3rem;
  font-size: 1.5rem;
  font-weight: ${({ $isActive }) => $isActive && 500};
  button {
    font-family: ${({ theme }) => theme.font.primary};
    font-size: 1.5rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &:hover {
    text-decoration: underline;
  }
`

const Footer = styled.div`
  margin: 5.5rem 0 0;
  font-size: 1.4rem;
  line-height: 2.3rem;
`
