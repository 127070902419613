const theme = {
  color: {
    black: "#000",
    white: "#FFF",
    greyLight: "#E6E6E6",
    grey: "#808080",
    greyDark: "#3b3735",
    greyBlack: "#25211E",
    beige: "#F3EDEA",
    error: "#FB7272",
    success: "#91b898",
  },
  breakpoint: {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1440,
  },
  font: {
    primary: "Gotham, sans-serif",
    heading: "Nobel, sans-serif",
    headingSerif: "Kumlien Pro, serif",
  },
  zIndex: {
    navbar: 980,
    overlay: 990,
    sidebar: 1000,
  },
  navbar: {
    sm: {
      height: 4.4,
    },
    md: {
      height: 6,
    },
  },
  announcementBar: {
    sm: {
      height: 5,
    },
  },
  productThumbnail: {
    textWrapper: {
      height: 8.6,
    },
  },
  button: {
    white: {
      background: "#FFF",
      backgroundHover: "#000",
      borderColor: "transparent",
      borderColorHover: "#000",
      borderRadius: 0.3,
      color: "#000",
      colorHover: "#FFF",
    },
    black: {
      background: "#000",
      backgroundHover: "#FFF",
      borderColor: "transparent",
      borderColorHover: "#FFF",
      borderRadius: 0.3,
      color: "#FFF",
      colorHover: "#000",
    },
    ghostWhite: {
      background: "transparent",
      backgroundHover: "#FFF",
      borderColor: "#FFF",
      borderColorHover: "#FFF",
      borderRadius: 0.3,
      color: "#FFF",
      colorHover: "#000",
    },
    ghostBlack: {
      background: "transparent",
      backgroundHover: "#000",
      borderColor: "#000",
      borderColorHover: "#000",
      borderRadius: 0.3,
      color: "#000",
      colorHover: "#FFF",
    },
    ghostBlackReverse: {
      background: "#000",
      backgroundHover: "transparent",
      borderColor: "#000",
      borderColorHover: "#000",
      borderRadius: 0.3,
      color: "#FFF",
      colorHover: "#000",
    },
    underlineWhite: {
      borderColor: "#FFF",
      borderRadius: 0,
      color: "#FFF",
      background: "transparent",
    },
    underlineBlack: {
      borderColor: "#000",
      borderRadius: 0,
      color: "#000",
      background: "transparent",
    },
  },
  hero: {
    NobelS: {
      title: {
        fontFamily: "Nobel, sans-serif",
        letterSpacing: "0.14em",
        mobile: {
          marginBottom: 1.5,
          fontSize: 2.8,
          lineHeight: 3.3,
        },
        desktop: {
          marginBottom: 1,
          fontSize: 4.4,
          lineHeight: 5.3,
        },
      },
      paragraph: {
        fontFamily: "Gotham, sans-serif",
        fontWeight: 300,
        mobile: {
          marginBottom: 2.6,
          fontSize: 1.5,
          lineHeight: 2.2,
        },
        desktop: {
          marginBottom: 2.4,
          fontSize: 1.8,
          lineHeight: 2.7,
        },
      },
    },
    NobelM: {
      title: {
        fontFamily: "Nobel, sans-serif",
        letterSpacing: "0.14em",
        mobile: {
          marginBottom: 1.5,
          fontSize: 2.8,
          lineHeight: 3.3,
        },
        desktop: {
          marginBottom: 1,
          fontSize: 4.8,
          lineHeight: 6,
        },
      },
      paragraph: {
        fontFamily: "Nobel, sans-serif",
        fontWeight: 300,
        mobile: {
          marginBottom: 2.6,
          fontSize: 2.4,
          lineHeight: 2.7,
        },
        desktop: {
          marginBottom: 2.4,
          fontSize: 2.4,
          lineHeight: 2.7,
        },
      },
    },
    NobelL: {
      title: {
        fontFamily: "Nobel, sans-serif",
        letterSpacing: "0.14em",
        mobile: {
          marginBottom: 1.5,
          fontSize: 4.2,
          lineHeight: 5,
        },
        desktop: {
          marginBottom: 3.2,
          fontSize: 6.8,
          lineHeight: 8.1,
        },
      },
      paragraph: {
        fontFamily: "Gotham, sans-serif",
        fontWeight: 300,
        mobile: {
          marginBottom: 2,
          fontSize: 2,
          lineHeight: 3,
        },
        desktop: {
          marginBottom: 3.3,
          fontSize: 2.4,
          lineHeight: 3.6,
        },
      },
    },
    NobelCard: {
      title: {
        fontFamily: "Nobel, sans-serif",
        letterSpacing: 0,
        mobile: {
          marginBottom: 2.1,
          fontSize: 4,
          lineHeight: 4.8,
        },
        desktop: {
          marginBottom: 1.8,
          fontSize: 5,
          lineHeight: 6,
        },
      },
      paragraph: {
        fontFamily: "Nobel, sans-serif",
        fontWeight: 300,
        mobile: {
          marginBottom: 2,
          fontSize: 2,
          lineHeight: 2.8,
        },
        desktop: {
          marginBottom: 2.6,
          fontSize: 2,
          lineHeight: 2.8,
        },
      },
    },
    KumlienProS: {
      title: {
        fontFamily: "Kumlien Pro, serif",
        letterSpacing: 0,
        mobile: {
          marginBottom: 0.9,
          fontSize: 3.6,
          lineHeight: 4.3,
        },
        desktop: {
          marginBottom: 1,
          fontSize: 4.2,
          lineHeight: 5,
        },
      },
      paragraph: {
        fontFamily: "Nobel, sans-serif",
        fontWeight: 300,
        mobile: {
          marginBottom: 2.6,
          fontSize: 1.8,
          lineHeight: 2.5,
        },
        desktop: {
          marginBottom: 2,
          fontSize: 1.8,
          lineHeight: 2.5,
        },
      },
    },
    KumlienProL: {
      title: {
        fontFamily: "Kumlien Pro, serif",
        letterSpacing: 0,
        mobile: {
          marginBottom: 1.5,
          fontSize: 5,
          lineHeight: 6,
        },
        desktop: {
          marginBottom: 2.3,
          fontSize: 6,
          lineHeight: 7.2,
        },
      },
      paragraph: {
        fontFamily: "Nobel, sans-serif",
        fontWeight: 300,
        mobile: {
          marginBottom: 3.1,
          fontSize: 2,
          lineHeight: 2.8,
        },
        desktop: {
          marginBottom: 3.5,
          fontSize: 2.4,
          lineHeight: 3.3,
        },
      },
    },
  },
}

export default theme
