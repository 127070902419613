import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDictionary } from '@hooks/index';

import { customerRecover } from '@lib/account';

import { Input, SubmitButton } from '@components/ui';

interface Props {
  showCheckEmail: () => void;
}
export const ResetPasswordModal: FC<Props> = ({ showCheckEmail }) => {
  const dictionary = useDictionary();
  const { register, handleSubmit, errors, clearErrors } = useForm({
    criteriaMode: 'firstError',
    mode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onSubmit = async (data: { email: string }) => {
    const { email } = data;

    setLoading(true);
    setError('');

    const res = await customerRecover({
      email,
    });

    setLoading(false);

    if (res?.customerUserErrors?.length > 0) {
      setError(res.customerUserErrors[0].message);
      return;
    }

    showCheckEmail();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="email"
        type="email"
        ref={register({
          required: 'Email is required',
          pattern: {
            value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
            message: 'Please enter a valid email',
          },
        })}
        placeholder="Email address"
        errors={errors}
        clearErrors={clearErrors}
      />
      <SubmitButton title={dictionary.sendInstructions} loading={loading} error={error} />
    </form>
  );
};
