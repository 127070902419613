import { FC } from "react"
import styled from "styled-components"

interface Props {
  preText: string | undefined
  textAlign: string
}
export const PreText: FC<Props> = ({ preText, textAlign }) => {
  if (!preText) return null
  return <StyledPreText $textAlign={textAlign}>{preText}</StyledPreText>
}

interface StyleProps {
  $textAlign: string
}
const StyledPreText = styled.p<StyleProps>`
  margin: 0 0 0.5rem;
  font-size: 1rem;
  line-height: 2.4rem;
  letter-spacing: 1.67px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.grey};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    text-align: ${({ $textAlign }) => $textAlign};
  }
`
