import { FC } from "react"
import Image from "next/image"
import styled from "styled-components"
import { ContentfulImage } from "@lib/types"
import { Container } from "@components/ui"
interface Props {
  data: {
    preTitle: string
    title: string
    image: ContentfulImage
  }
}

export const BannerSection: FC<Props> = ({ data }) => {
  const { preTitle, title, image } = data

  return (
    <StyledBannerSection>
      <Container>
        <ImageWrapper>
          <Image
            src={image.url}
            alt={image.description}
            width={1440}
            height={520}
            layout="responsive"
          />
          <TitleWrapper>
            {preTitle && <PreTitle>{preTitle}</PreTitle>}
            {title && <Title>{title}</Title>}
          </TitleWrapper>
        </ImageWrapper>
      </Container>
    </StyledBannerSection>
  )
}

const StyledBannerSection = styled.section``

const ImageWrapper = styled.div`
  position: relative;

  img {
    object-fit: cover;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0;
  }
`

const TitleWrapper = styled.div`
  position: absolute;
  padding: 0 2rem;
  bottom: 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0;
    left: 7.2rem;
    bottom: 5.4rem;
  }
`

const PreTitle = styled.p`
  margin: 0 0 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1.67px;
  font-size: 1rem;
  line-height: 2.4rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 0.8rem;
  }
`

const Title = styled.h2`
  margin: 0;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.font.headingSerif};
  font-size: 2.6rem;
  line-height: 3.3rem;
  letter-spacing: 0.12em;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    font-size: 3rem;
    line-height: 4.3rem;
  }
`
