import { FC } from "react"
import styled from "styled-components"
import { Review as R } from "@lib/types"
import Image from "next/image"

import { RichText } from "@components/common"
import { Rating } from "@components/ui"

interface Block extends R {
  preTitle: string
}

interface Props {
  data: Block
}

export const Review: FC<Props> = ({ data }) => {
  const { preTitle, rating, message, author, image } = data

  return (
    <Article>
      <ImageWrapper>
        <Image
          src={image.url}
          alt={image.description}
          width={428}
          height={396}
          layout="responsive"
        />
      </ImageWrapper>
      <Content>
        <PreTitle>{preTitle}</PreTitle>
        <RatingWrapper>
          <Rating rating={rating} />
        </RatingWrapper>
        <TextWrapper>
          <RichText richText={message} />
        </TextWrapper>
        <Footer>
          By <Author>{author}</Author>
        </Footer>
      </Content>
    </Article>
  )
}

const Article = styled.article`
  width: 100%;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    max-width: 100rem;
  }
`

const PreTitle = styled.h2`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
    text-align: center;
    margin: 0 0 1.9rem;
    font-size: 1.2rem;
    line-height: 2.4rem;
    letter-spacing: 0.167rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.grey};
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin: 0 0 3.9rem;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  margin: 0 0 2.8rem;

  img {
    object-fit: cover;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 0 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    max-width: 42.8rem;
  }
`

const Content = styled.div`
  flex: 1;
`

const TextWrapper = styled.div`
  white-space: pre-wrap;
  text-align: center;

  h1,
  h2,
  h3,
  h4 {
    font-size: 2.4rem;
    line-height: 3.1rem;
    font-weight: 400;
  }

  p {
    font-family: ${({ theme }) => theme.font.headingSerif};
    margin: 0 auto 1.6rem;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.3rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    p {
      font-size: 2.8rem;
      line-height: 3.6rem;
      max-width: 49rem;
    }
  }
`

const RatingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 1.6rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 2.6rem;
  }
`

const Footer = styled.footer`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 2.8rem;
  letter-spacing: 2px;
  text-align: center;
  color: ${({ theme }) => theme.color.grey};
`

const Author = styled.span`
  color: ${({ theme }) => theme.color.black};
`
