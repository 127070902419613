import { FC, useState, useEffect, useMemo, Dispatch, SetStateAction } from "react"
import styled from "styled-components"
import { ContentfulProductWithShopifyData } from "@lib/types"
import { CheckoutLineItemEdge } from "shopify-storefront-api-typings"

import { useDictionary } from "@hooks/index"
import { UpsellThumbnail } from "@components/ui"
import { getProductByHandle, getShopifyProductsInventoryByHandles } from "@lib/shopify"
import { useRouter } from "next/router"

interface Props {
  upsells: ContentfulProductWithShopifyData[]
  lineItems: CheckoutLineItemEdge[]
}

interface UpsellsInventory { key: string, inventory: number }

export const CartUpsell: FC<Props> = ({ upsells, lineItems }) => {
  const [upsellsToShow, setUpsellsToShow] = useState(upsells)
  const [upsellsInventory, setUpsellsInventory] = useState<UpsellsInventory[]>([]);
    
  const dictionary = useDictionary()
  const { locale } = useRouter();

  useEffect(() => {
    const shuffledUpsells = upsells.map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)

    setUpsellsToShow(shuffledUpsells);
  }, []);

  useEffect(() => {
    const newUpsells = upsellsToShow.filter(({ slug }) => {
      const relevantUpsellWithInventory = upsellsInventory.find(upsell => upsell.key === slug);
      if (relevantUpsellWithInventory)
        return lineItems.every(({ node }) => node?.variant?.product?.handle !== slug) && relevantUpsellWithInventory.inventory > 0
      
      return lineItems.every(({ node }) => node?.variant?.product?.handle !== slug)
    }
    )

    setUpsellsToShow(newUpsells)
  }, [lineItems, upsellsInventory, locale])

  useEffect(() => {
    const setUpsellsWithInventory = async () => {
      const productHandles = upsells.map(upsell => upsell.slug);
      const shopifyData = await getShopifyProductsInventoryByHandles(productHandles, locale);
      if (shopifyData) setUpsellsInventory(shopifyData);
    }

    setUpsellsWithInventory();
  }, [locale]);

  return (
    <>
      {upsellsToShow.length > 0 && (
        <StyledCartUpsell>
          <Title>{dictionary.youMightAlsoLike}</Title>
          <Products>
            {upsellsToShow.slice(0, 2).map(product => (
              <UpsellThumbnail key={product.sys.id} product={product} />
            ))}
          </Products>
        </StyledCartUpsell>
      )}
    </>
  )
}

const StyledCartUpsell = styled.section`
  background: ${({ theme }) => theme.color.beige};
  padding: 1.5rem 0rem 0;
`

const Title = styled.h4`
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.6rem;
  text-align: center;
  letter-spacing: 0.185rem;
  text-transform: uppercase;
`

const Products = styled.ul`
  overflow-x: hidden;
  padding-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding-bottom: 2.1rem;
  }
`
