import { FC } from "react"
import styled from "styled-components"
import { ContentfulImage } from "@lib/types"
import type { Document } from "@contentful/rich-text-types"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { RichText } from "@components/common"
import { Container, CollectionThumbnail } from "@components/ui"

import Arrow from "@svg/arrow.svg"

interface Props {
  title: string
  description: {
    json: Document
  }
  slides: [
    {
      name: string
      slug: string
      excerpt: string
      featuredImage: ContentfulImage
    }
  ]
  currentSlide: number
  setCurrentSlide: (slide: number) => void
}

export const CollectionSlider: FC<Props> = ({
  title,
  description,
  slides,
  currentSlide,
  setCurrentSlide,
}) => {
  const [ref, slider] = useKeenSlider<HTMLUListElement>({
    slidesPerView: 1.8,
    spacing: 20,
    centered: true,
    loop: true,
    breakpoints: {
      "(min-width: 768px)": {
        slidesPerView: 2.3,
      },
      "(min-width: 1280px)": {
        slidesPerView: 4.3,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
  })

  return (
    <Container>
      <SliderContainer>
        <Header>
          <ArrowLeft onClick={() => slider.prev()}>
            <Arrow />
          </ArrowLeft>
          <TextWrapper>
            <Title>{title}</Title>
            <Description>
              <RichText richText={description} />
            </Description>
          </TextWrapper>
          <ArrowRight onClick={() => slider.next()}>
            <Arrow />
          </ArrowRight>
        </Header>
        <StyledSlider ref={ref} className="keen-slider">
          {slides.map((thumbnail: any, i: number) => {
            return (
              <Slide
                className={`keen-slider__slide number-slide${i + 1}`}
                key={`${thumbnail.sys.id}${i}`}
                onClick={() => slider.moveToSlideRelative(i, true)}
              >
                <CollectionThumbnail
                  data={thumbnail}
                  showExcerpt={currentSlide === i}
                />
              </Slide>
            )
          })}
        </StyledSlider>
        {slider && (
          <Dots>
            {[...Array(slider.details().size).keys()].map(idx => {
              return (
                <Dot
                  key={idx}
                  onClick={() => {
                    slider.moveToSlideRelative(idx)
                  }}
                  $isActive={currentSlide === idx}
                />
              )
            })}
          </Dots>
        )}
      </SliderContainer>
    </Container>
  )
}

interface ButtonProps {
  $isActive: boolean
}

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 0 3rem;
  width: 100%;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 4rem;
    padding: 0;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  max-width: 53.8rem;
  margin: 0 auto;
`

const Title = styled.h1`
  font-size: 3rem;
  line-height: 3.6rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
`

const Description = styled.div`
  p {
    font-size: 1.5rem;
    line-height: 2.2rem;
    font-weight: 400;

    &:last-child {
      margin: 0;
    }
  }
`

const StyledSlider = styled.ul`
  margin: 0 0 3rem;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0;
  }
`

const Slide = styled.li`
  max-width: 31.6rem;
`

const Dots = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const Dot = styled.button<ButtonProps>`
  display: block;
  margin: 0 0.5rem;
  padding: 0;
  width: 1rem;
  height: 1rem;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.color.black : theme.color.grey};

  :focus {
    outline: none;
  }
`

const ArrowLeft = styled.button`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
    min-width: 6rem;
    min-height: 6rem;
    padding: 0;
    margin-left: 2rem;
    margin-right: auto;
    background: ${({ theme }) => theme.color.black};
    transition: background-color 0.2s linear;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-left: 6.3rem;
  }
`

const ArrowRight = styled(ArrowLeft)`
  margin-right: 2rem;
  margin-left: auto;

  svg {
    transform: rotate(180deg);
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-right: 2rem;
  }
`
