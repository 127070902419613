import { FC, useState } from "react"
import styled from "styled-components"
import { useCustomer } from "@hooks/index"
import { Button, Message } from "@components/ui"
import { AddressForm } from "@components/account"
import { Address as Addrs } from "@lib/types"

interface Props {
  address: Addrs
}

export const Address: FC<Props> = ({ address }) => {
  const { deleteAddress, loading } = useCustomer()
  const [activeModal, setActiveModal] = useState("")

  const [error, setError] = useState("")

  const handleDeleteAddress = async (id: string) => {
    setError("")

    const res = await deleteAddress({
      id,
    })

    if (res?.error) {
      setError(res.error)

      return
    }
  }

  return (
    <StyledAddress key={address.id}>
      <AddressInfo>
        <Name>{`${address?.firstName} ${address?.lastName}`}</Name>
        <AddressLine>{address?.address1}</AddressLine>
        <AddressLine>{address?.address2}</AddressLine>
        <AddressLine>{`${address?.city}, ${address?.province}`}</AddressLine>
        <AddressLine>{`${address?.country}, ${address?.zip}`}</AddressLine>
        <AddressLine>{address?.phone}</AddressLine>
      </AddressInfo>
      <ButtonContainer>
        <ButtonWrapper>
          <div style={{ marginRight: "2.6rem" }}>
            <Button
              boldTitle
              title="Edit"
              buttonTheme="ghostBlackReverse"
              onClick={() => setActiveModal(address.id)}
            />
          </div>
          <Button
            boldTitle
            title="Delete"
            buttonTheme="ghostBlackReverse"
            loading={loading}
            onClick={() => handleDeleteAddress(address.id)}
          />
        </ButtonWrapper>
        {error && <Message $error>{error}</Message>}
      </ButtonContainer>
      <AddressForm
        open={activeModal === address.id}
        onClose={() => setActiveModal("")}
        address={address}
      />
    </StyledAddress>
  )
}

const StyledAddress = styled.li`
  padding: 3.3rem 0 3.4rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.black};

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const AddressInfo = styled.div``

const Name = styled.h3`
  margin: 0;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 300;
`

const AddressLine = styled.div`
  color: ${({ theme }) => theme.color.grey};
  font-size: 1.5rem;
  line-height: 2.8rem;
`

const ButtonContainer = styled.div`
  margin: 2.1rem 0 0;
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0;
  }
`

const ButtonWrapper = styled.div`
  margin-right: 0 auto;
  display: flex;
  justify-content: center;
`
