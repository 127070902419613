import { FC } from "react"
import styled from "styled-components"
import { useCheckout, useDictionary } from "@hooks/index"

import Hamburger from "@svg/hamburger.svg"
import Bag from "@svg/bag.svg"

interface Props {
  onClick: () => void
  type: "menu" | "cart"
}

export const MenuButton: FC<Props> = ({ onClick, type }) => {
  const dictionary = useDictionary()
  const { lineItems } = useCheckout();
  const cartCount = lineItems?.edges
    ? lineItems.edges.reduce(
        (acc: number, val: { node: { quantity: number } }) =>
          acc + val.node.quantity,
        0
      )
    : 0
  const isMenu = type === "menu"
  return (
    <StyledMenuButton type="button" onClick={onClick}>
      {isMenu ? <HamburgerIcon /> : <> <BagIcon /> {cartCount > 0 && <FilledCartCircle/>} </>}
      <span>{isMenu ? dictionary.menu : dictionary.bag}</span>
    </StyledMenuButton>
  )
}

const FilledCartCircle = styled.div`
  width: 11px;
  height: 11px;
  right: 9px;
  top: 8px;
  position: absolute;
  background-color: white;
  border: 2px solid #25211E;
  border-radius: 50%;
`

const StyledMenuButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 6px;
  font-family: ${({ theme }) => theme.font.primary};
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.07rem;
  color: ${({ theme }) => theme.color.white};
  line-height: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`

const HamburgerIcon = styled(Hamburger)`
  margin: 0 0 0.57rem;
  max-width: 2.1rem;
`

const BagIcon = styled(Bag)`
  margin: 0 0 0.31rem;
  max-width: 1.74rem;
`
