import { FC, useState } from "react"
import styled from "styled-components"
import type { Document } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { LazyMotion, m } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

interface Props {
  label: string
  richText: {
    json: Document
    links?: {}
  }
}
export const AccordionItem: FC<Props> = ({ label, richText }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <StyledAccordionItem className="richtext-accordion-item">
      <Label onClick={() => setIsActive(!isActive)}>
        <Plus $isActive={isActive} />
        <LabelText>{label}</LabelText>
      </Label>
      <LazyMotion strict features={loadFeatures}>
        <Body
          initial={isActive}
          animate={{
            height: isActive ? "auto" : "0",
          }}
          transition={{ duration: 0.25 }}
        >
          {documentToReactComponents(richText.json)}
        </Body>
      </LazyMotion>
    </StyledAccordionItem>
  )
}

const StyledAccordionItem = styled.div`
  margin: 0 0 0.7rem;
  background: ${({ theme }) => theme.color.beige};
  border-radius: 0.5rem;
  overflow: hidden;

  + :not(.richtext-accordion-item) {
    margin-top: 3.6rem;
  }
`

const Label = styled.label`
  display: flex;
  align-items: center;
  padding: 1.2rem;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.3rem;
`

const LabelText = styled.span`
  margin-left: 2.9rem;
`

const Body = styled(m.div)`
  padding: 0 1.2rem 0 4rem;

  p:last-child {
    margin: 0 0 1.2rem;
  }
`

const Plus = styled.div<{ $isActive: boolean }>`
  position: absolute;
  width: 2rem;
  height: 2rem;
  margin: 0 0.9rem 0 0;

  &:before,
  &:after {
    content: "";
    position: absolute;
    background: ${({ theme }) => theme.color.black};
  }

  &:after {
    top: 4px;
    bottom: 4px;
    width: 2px;
    transform: rotate(90deg) translate(0px, -9px);
  }

  &:before {
    display: ${({ $isActive }) => ($isActive ? "none" : "block")};
    left: 50%;
    top: 4px;
    bottom: 4px;
    width: 2px;
    transform: translateX(-50%);
  }
`
