import { FC } from "react"
import styled from "styled-components"
import { TextBlock as T, ContentfulButton } from "@lib/types"

import { TextBlock } from "@components/common"
import { Container, Button, PreText } from "@components/ui"

interface Props {
  data: {
    preText: string
    textBlock: T
    button: ContentfulButton
  }
}

export const TextSection: FC<Props> = ({ data }) => {
  const { preText, textBlock, button } = data
  const textAlign = textBlock.textAlign.toLowerCase()

  return (
    <StyledTextSection>
      <Container>
        <TextSectionInner>
          <TextWrapper>
            <PreText preText={preText} textAlign={textAlign} />
            <TextBlock textBlock={textBlock} />
          </TextWrapper>
          {button && (
            <ButtonWrapper>
              <Button button={button} buttonAlign={textAlign} />
            </ButtonWrapper>
          )}
        </TextSectionInner>
      </Container>
    </StyledTextSection>
  )
}

const StyledTextSection = styled.section``

const TextSectionInner = styled.div`
  padding: 3.1rem 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 6.4rem 0;
    margin: 0 auto;
    max-width: 70rem;
  }
`

const TextWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.heading};
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 2.7rem;

  h1,
  h2,
  h3 {
    margin: 0;
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 4.3rem;
    letter-spacing: 0.12em;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    h1,
    h2,
    h3 {
      margin: 0 0 1.3rem;
    }
  }
`

const ButtonWrapper = styled.div`
  margin: 3.1rem 0 0;
`
