export const PAGE_SLUGS_QUERY = `
  ... on Page {
    __typename
    slug
  }
  ... on InfoPage {
    __typename
    slug
  }
  ... on ShopAll {
    __typename
    slug
  }
  ... on Collection {
    __typename
    slug
  }
  ... on Product {
    __typename
    slug
  }
`

export const CONTENTFUL_IMAGE_QUERY = `
  sys {
    id
  }
  contentType
  url (transform: {format: JPG_PROGRESSIVE})    
  description
  width
  height
`

export const LINK_QUERY = `
  sys {
    id
  }
  boldTitle
  title
  entry {
    ${PAGE_SLUGS_QUERY}
  }
  href
`

export const BUTTON_QUERY = `
    __typename
    sys {
       id 
    }
    theme 
    link {
      ${LINK_QUERY}
    }
    extraClassname
`

export const PRODUCT_QUERY = `
... on Product {
  __typename
  sys {
    id
    publishedAt
  }
  hideFromSite
  hideFromCollections
  title
  slug
  productBadge
  productBadgeImage {
    ${CONTENTFUL_IMAGE_QUERY}
  }
  category {
    name 
    slug
    category {
      name
      slug
    }
  }
  color {
    name
    value
    image {
      url (transform: {format: JPG_PROGRESSIVE})    
      description
    }
  }
  sizesCollection(limit: 10) {
    items {
      name
    }
  }
  fabricThickness {
    name
  }
  fabricTexture {
    name
  }
}
`

export const PRODUCT_SHOPALL_QUERY = `
  ... on Product {
    __typename
    sys {
      id
      publishedAt
    }
    hideFromSite
    hideFromCollections
    title
    slug
    productBadge
    productBadgeImage {
      contentType
      url (transform: {format: JPG_PROGRESSIVE})    
      description
      width
      height
    }
    category {
      name 
      slug
      category {
        name
        slug
      }
    }
    color {
      name
      value
      image {
        url (transform: {format: JPG_PROGRESSIVE})    
        description
      }
    }
    sizesCollection(limit: 10) {
      items {
        name
      }
    }
    fabricThickness {
      name
    }
    fabricTexture {
      name
    }
  }
`

export const TEXT_BLOCK_QUERY = `
    __typename
    sys {
        id
    }
    textAlign
    textColor {
        name
        value
    }
    richText {
        json
    }
`

export const TEXT_BLOCK_WITH_CUSTOM_HTML_QUERY = `
    __typename
    sys {
        id
    }
    textAlign
    textColor {
        name
        value
    }
    richText {
        json
        links {
          entries {
            block {
              ... on CustomHtml {
                __typename
                sys {
                  id
                }
                html
              }
            }
          }
        }
    }
`

export const CONTENT_BLOCK_QUERY = `
... on ContentBlock {
  sys {
    id
  }
  image {
    ${CONTENTFUL_IMAGE_QUERY}
  }
  wistiaId
  preText
  linkForImage {
    ${LINK_QUERY}
  }
  textBlock {
    ${TEXT_BLOCK_QUERY}
  }
  button {
    ${BUTTON_QUERY}
  }
}
`

export const IMAGE_WITH_RICH_TEXT_QUERY = `
  sys {
    id
  }
  image {
    ${CONTENTFUL_IMAGE_QUERY}
  }
  textColor {
    name
    value
  }
  richText {
    json
    links {
      entries {
        hyperlink {
          __typename
          sys {
            id
          }
          ${PAGE_SLUGS_QUERY}
        }
      }
    }
  }
`
