import { FC } from "react"
import styled from "styled-components"
import { LazyMotion, m } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

import { ContentfulLink } from "@lib/types"

import { Link } from "@components/common"
import ChevronThin from "@svg/chevron-thin.svg"

interface NavLink extends ContentfulLink {
  onClick?: () => void
}
interface Props {
  className?: string
  label: string
  items?: ContentfulLink[]
  displayChevron?: boolean
  isActive: boolean
  onClick: () => void
  trackingProperties?: {}
}

export const AccordionItem: FC<Props> = ({
  className,
  children,
  label,
  items,
  displayChevron,
  isActive,
  onClick,
  trackingProperties,
}) => {
  return (
    <StyledAccordionItem className={className}>
      <Label
        className="accordion-item__label"
        $isActive={isActive}
        onClick={onClick}
      >
        <span>{label}</span>
        {((items && items?.length > 0) || displayChevron) && (
          <AccordionItemChevron $isActive={isActive} />
        )}
      </Label>
      <LazyMotion strict features={loadFeatures}>
        <Body
          className="accordion-item__body"
          initial={isActive}
          animate={{
            height: isActive ? "auto" : "0",
          }}
          transition={{ duration: 0.25 }}
        >
          <BodyInner>
            {items &&
              items.map((link: NavLink) => {
                return link?.onClick ? (
                  <AccordionLink key={link.sys.id}>
                    <button onClick={link.onClick}>{link.title}</button>
                  </AccordionLink>
                ) : (
                  <AccordionLink key={link.sys.id}>
                    <Link link={link} />
                  </AccordionLink>
                )
              })}
            {children}
          </BodyInner>
        </Body>
      </LazyMotion>
    </StyledAccordionItem>
  )
}

interface StyleProps {
  readonly $isActive: boolean
}

const StyledAccordionItem = styled.div``

const BodyInner = styled.div`
  padding: 2.1rem;
`

const Label = styled.label<StyleProps>`
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.heading};
  font-weight: 400;
  font-size: 2rem;
  align-items: center;
  padding: 2rem;
  color: ${({ theme }) => theme.color.black};
  border-top: ${({ theme }) => `1px solid ${theme.color.greyLight}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.greyLight}`};
`

const Body = styled(m.div)`
  overflow: hidden;
  height: auto;
  margin-top: -1px;
`

export const AccordionItemChevron = styled(ChevronThin)<StyleProps>`
  transform: ${({ $isActive }) =>
    $isActive ? `rotate(0deg)` : `rotate(-90deg)`};
  transition: transform 0.3s ease-out;
  width: 1.26rem;

  path {
    stroke: ${({ theme }) => theme.color.grey};
  }
`

const AccordionLink = styled.div`
  font-size: 1.5rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.color.black};
`
