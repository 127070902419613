import { FC, useMemo, useState } from "react"
import styled from "styled-components"
import { ContentBlock, ContentfulImage } from "@lib/types"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import Arrow from "@svg/arrow.svg"
import { TOptionsEvents } from "keen-slider"

interface Slide extends ContentBlock {
  name: string
  slug: string
  excerpt: string
  featuredImage: ContentfulImage
}
interface Props {
  displayArrows?: boolean
  displayNavigation?: boolean
  SlideComponent: FC<any>
  slides: ContentBlock[]
  config?: {
    slidesPerView: number,
    breakpoints: {
      [key: string]: Omit<TOptionsEvents, string>
    }
  }
}

export default function Slider({
  displayArrows,
  displayNavigation = true,
  slides,
  SlideComponent,
  config,
}: Props): JSX.Element {
  const [mounted, setMounted] = useState(false)
  const [currentSlide, setCurrentSlide] = useState(0)

  const decideSpacing = () => {
    if (!config) return 0;
    if (config?.slidesPerView > 1) return 30;
    return 0;
  }

  const [ref, slider] = useKeenSlider<HTMLUListElement>({
    initial: 0,
    mounted: () => setMounted(true),
    slidesPerView: config?.slidesPerView || undefined,
    spacing: decideSpacing(),
    breakpoints: {
      "(max-width: 500px)": {
        slidesPerView: 1,
      },
      "(min-width: 768px)": {
        slidesPerView: config?.slidesPerView || 1,
      },
      "(min-width: 1280px)": {
        slidesPerView: config?.slidesPerView || 1,
      },
    },
    ...config,
  })
  const slidesPerView = slider && slider.details().slidesPerView
  const amountOfDots = useMemo(() => {
    if (!slider) return 1;
    if (slidesPerView === 1) return slider.details().size;
    return Math.ceil(slider.details().size / slidesPerView);
  }, [slider])

  const moveSliderRight = () => {
    if (slidesPerView === 1) {
      slider.next();
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(currentSlide + slidesPerView);
      slider.moveToSlideRelative(currentSlide + slidesPerView);
    } 
  }

  const moveSliderLeft = () => {
    if (slidesPerView === 1) {
      slider.prev();
      setCurrentSlide(currentSlide - 1);
    } else {
      setCurrentSlide(currentSlide - slidesPerView);
      slider.moveToSlideRelative(currentSlide - slidesPerView);
    } 
  }

  return (
    <StyledSlider $flex={displayArrows} $visible={mounted}>
      {displayArrows && slider && slidesPerView !== slides.length &&  (
        <ArrowLeft
          className="slider__arrow"
          onClick={moveSliderLeft}
          disabled={currentSlide === 0}
        >
          <Arrow />
        </ArrowLeft>
      )}
      <SliderWrapper className="slider__wrapper">
        <KeenSlider ref={ref} className="keen-slider">
          {slides.map((block: any, i: number) => {
            return (
              <Slide
                slidesPerView={slidesPerView}
                className={`keen-slider__slide number-slide${i + 1}`}
                key={`${block.sys.id}${i}`}
              >
                <SlideInner>
                  <SlideComponent data={block} />
                </SlideInner>
              </Slide>
            )
          })}
        </KeenSlider>
        {displayNavigation && slider && (
          <Dots className="keen-slider__dots">
            {[...Array(amountOfDots).keys()].map(idx => {
              const slideIndexRelativeToDot = idx * slidesPerView;
              return (
                <Dot
                  key={idx}
                  onClick={() => {
                    slider.moveToSlideRelative(slideIndexRelativeToDot)
                    setCurrentSlide(slideIndexRelativeToDot);
                  }}
                  $isActive={currentSlide === slideIndexRelativeToDot}
                />
              )
            })}
          </Dots>
        )}
      </SliderWrapper>
      {displayArrows && slider && slidesPerView !== slides.length && (
        <ArrowRight
          className="slider__arrow"
          onClick={moveSliderRight}
          disabled={
            slidesPerView > 1
              ? (currentSlide + slidesPerView) > slides.length
              : currentSlide === slides.length - 1
          }
        >
          <Arrow />
        </ArrowRight>
      )}
    </StyledSlider>
  )
}

interface SliderProps {
  $flex: boolean | undefined
  $visible: boolean
}
interface ButtonProps {
  $isActive: boolean
}

const StyledSlider = styled.div<SliderProps>`
  position: relative;
  visibility: ${({ $visible }) => ($visible ? "visible" : "hidden")};
  width: 100%;
  display: ${({ $flex }) => ($flex ? "flex" : "block")};
  align-items: center;
  justify-content: center;
`

const SliderWrapper = styled.div`
  width: 100%;
  max-width: 100rem;
`

const KeenSlider = styled.ul`
  margin: 0 0 3.6rem;
  max-width: 100rem;
`

interface SlideProps {
  slidesPerView: number
}

const Slide = styled.li<SlideProps>`
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding-left: ${({ slidesPerView }) => slidesPerView === 3 ? `3rem` : `0`};
  }
`

const SlideInner = styled.div`
  display: grid;
  place-items: center;
`

const Dots = styled.div`
  display: flex;
  justify-content: center;
`

const Dot = styled.button<ButtonProps>`
  display: block;
  margin: 0 0.5rem;
  padding: 0;
  width: 1rem;
  height: 1rem;
  background: ${({ theme, $isActive }) =>
    $isActive ? theme.color.black : theme.color.grey};

  :focus {
    outline: none;
  }
`

const ArrowLeft = styled.button`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: block;
    min-width: 6rem;
    min-height: 6rem;
    padding: 0;    
    margin-left: 6.2rem;
    margin-right: 8.7rem;
    background: ${({ theme }) => theme.color.black};
    transition: background-color 0.2s linear;

    &:disabled {
      background: ${({ theme }) => theme.color.grey};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-left: 6.2rem;
  }
`

const ArrowRight = styled(ArrowLeft)`
  margin-right: 6.3rem;
  margin-left: auto;

  svg {
    transform: rotate(180deg);
  }
  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin-right: 3.7rem;
    margin-left: 8.7rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.lg}px) {
    margin-right: 6.4rem;
  }
`
