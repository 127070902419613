import { FC } from "react"
import Link from "next/link"
import { ContentfulLink } from "@lib/types"
import { useTracking } from "@hooks/index"

interface Props {
  imageWrapper: any
  linkForImage?: ContentfulLink
}

export const ThumbnailLink: FC<Props> = ({imageWrapper, linkForImage}) => {
  const internalLink = linkForImage?.entry?.slug 
  const externalLink = linkForImage?.href
  const title = linkForImage?.title
  const { track } = useTracking()
  return (
    <>
      {internalLink ? (
        <Link href={`/${internalLink}`}>
          <a onClick={() =>
            track("Button Clicked", {
              type: "Link",
              url: `https://www.hautehijab.com/${internalLink}`,
              label: title,
            })
          }>
            {imageWrapper}
          </a>
        </Link>
      ) : externalLink ? (
        <a href={externalLink} target="_blank"
          onClick={() =>
            track("Button Clicked", {
              type: "Link",
              url: externalLink,
              label: title,
            })
          }
        >
          {imageWrapper}
        </a>
      ) : null}
    </>
  )
}
