import { FC } from "react"
import styled from "styled-components"
import { LazyMotion, m, AnimatePresence } from "framer-motion"
const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

import { Portal as ReactPortal } from "react-portal"

import { useBodyScrollLock } from "@hooks/index"

interface Props {
  open: boolean
  node?: HTMLElement
  onClose: () => void
}

export const Portal: FC<Props> = ({ children, open, onClose, node }) => {
  useBodyScrollLock(open)

  return (
    <LazyMotion strict features={loadFeatures}>
      <AnimatePresence>
        {open && (
          <ReactPortal node={node}>
            <PortalContainer>
              <Overlay
                initial={{ opacity: 0.5 }}
                animate={{
                  opacity: 1,
                }}
                exit={{ opacity: 0 }}
                onClick={onClose}
              />

              {children}
            </PortalContainer>
          </ReactPortal>
        )}
      </AnimatePresence>
    </LazyMotion>
  )
}

const PortalContainer = styled.div`
  z-index: 9999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Overlay = styled(m.div)`
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`
