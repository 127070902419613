import React from "react"
import styled, { keyframes } from "styled-components"

const rotate = keyframes`
 0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); }
 `

const LoaderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Loader = styled.div`
  height: 24px;
  width: 24px;
  color: ${props => props.color ? props.color : "#FFFFFF"};
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-radius: 50%;
  border-right-color: ${props => props.theme.color.greyDark};
  animation: ${rotate} 1s linear infinite;

  @media (min-width: ${props => props.theme.breakpoint.md}px) {
    height: 2.4rem;
    width: 2.4rem;
    border-width: 0.3rem;
  }
`

export const SpinningLoader = ({color}: {color?: string}) => {
  return (
    <LoaderWrapper>
      <Loader color={color}/>
    </LoaderWrapper>
  )
}

export default SpinningLoader
