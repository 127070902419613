import { FC, ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { LazyMotion, m, AnimatePresence } from 'framer-motion';
import Link from "next/link"

import type { CheckoutLineItem } from 'shopify-storefront-api-typings';
import { decode } from 'shopify-gid';
import { useUI, useCustomer, useTracking, useSite } from '@hooks/index';
import { Dropdown } from '@components/ui';
import { Price } from '@components/common';
import CloseIcon from '@svg/cross-thin.svg';
import router from 'next/router';

const loadFeatures = () => import('@lib/framer-motion').then(res => res.default);

interface Props {
  cart_id: string;
  lineItem: CheckoutLineItem;
  removeLineItem: (arg: {}) => void;
  updateLineItem: (arg: {}) => void;
}

export const LineItemThumbnail: FC<Props> = ({
  cart_id,
  updateLineItem,
  removeLineItem,
  lineItem,
}) => {
  const site = useSite();
  const [visible, setVisible] = useState(true);
  const { id, title, variant, quantity } = lineItem;
  const isGiftWrap = site.giftWrapping === lineItem?.variant?.id;
  const { getPricePair, currency, formatCurrencyPrice } = useCustomer();
  const [productUrl, setProductUrl] = useState<string | undefined>("");
  const { track, tikTokTrack } = useTracking();

  const selectedOptionsValues = variant?.selectedOptions.map(({ value }) => value);

  const quantityOptions = [...Array(51).keys()].map(val => ({
    name: val,
    value: val,
  }));

  quantityOptions.shift();

  const handleChange = async (e: ChangeEvent<HTMLSelectElement>) => {
    const originalQty = lineItem.quantity;
    const newQty = +e.target.value;
    const variantId = variant?.id;
    const qtyChange = newQty - originalQty;
    await updateLineItem({ id, variantId, quantity: newQty });

    if (qtyChange > 0) {
      track('Product Added', {
        cart_id,
        product_id: decode(variant?.id).id,
        quantity: qtyChange,
        variant: variant?.title,
        sku: variant?.sku,
        price: parseFloat(variant?.priceV2?.amount),
        brand: 'Haute Hijab',
        image_url: variant?.image?.transformedSrc,
      });
      const currencyAdjustedPrice = variant?.presentmentPrices.edges.find((edge: {node: {price: {currencyCode: string}}}) => edge.node.price.currencyCode === currency)?.node.price.amount || null;
      tikTokTrack("AddToCart", {
        content_type: "product",
        content_id: decode(variant?.id).id,
        content_name: variant?.title,
        currency: currency,
        price: currencyAdjustedPrice,
        value: parseFloat(currencyAdjustedPrice) * (qtyChange),
        quantity: qtyChange
      })
    }
  };

  const removeLineItemFromCart = () => {
    setVisible(false);

    setTimeout(() => {
      removeLineItem(lineItem);
      track('Product Removed', {
        cart_id,
        product_id: decode(variant?.id).id,
        quantity,
        variant: variant?.title,
        sku: variant?.sku,
        price: parseFloat(variant?.priceV2?.amount),
        brand: 'Haute Hijab',
        image_url: variant?.image?.transformedSrc,
      });
    }, 200);
  };

  const { closeCart } = useUI()

  const navigateToProductPage = async () => {
   if(productUrl) {
     await router.push(productUrl);
     closeCart();
   }
  }
  
  useEffect(() => {
    setProductUrl(lineItem?.variant?.product?.handle ? `/products/${lineItem?.variant?.product?.handle}` : undefined)
  }, [lineItem])

  return (
    <LazyMotion strict features={loadFeatures}>
      <AnimatePresence>
        {visible && (
          <Thumbnail
            initial={{ opacity: 0, x: -20 }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            exit={{ opacity: 0, x: -20 }}
          >
              <ImageWrapper productUrl={productUrl} onClick={navigateToProductPage}>
                <Image src={variant?.image?.transformedSrc} layout="fill" />
              </ImageWrapper>
            <Content>
              <Title productUrl={productUrl} onClick={navigateToProductPage}>{title}</Title>
              {!isGiftWrap && (
                <SelectedOptions>
                  {selectedOptionsValues?.map((value, i) => (
                    <span key={value}>
                      {i !== 0 ? ' | ' : ''}
                      {value}
                    </span>
                  ))}
                </SelectedOptions>
              )}
              <PriceWrapper>
                {getPricePair(variant)?.compareAtPrice === null ? (
                  <span>{formatCurrencyPrice(+getPricePair(variant).price.amount * quantity)}</span>
                ) : (
                  <>
                    <BoldSpan>
                      {formatCurrencyPrice(+getPricePair(variant).price.amount * quantity)}
                    </BoldSpan>
                    <s style={{ marginLeft: '0.25rem' }}>
                      {formatCurrencyPrice(+getPricePair(variant).compareAtPrice.amount * quantity)}
                    </s>
                  </>
                )}
              </PriceWrapper>
              {title && (
                <StyledDropdown
                  name={`${title.replace(/ /g, '')}-quantity`}
                  value={quantity}
                  disabled={isGiftWrap}
                  onChange={handleChange}
                  options={quantityOptions}
                />
              )}
              <CloseButton onClick={() => removeLineItemFromCart()}>
                <CloseIcon />
              </CloseButton>
            </Content>
          </Thumbnail>
        )}
      </AnimatePresence>
    </LazyMotion>
  );
};

const Thumbnail = styled(m.li)`
  display: flex;
  margin: 0 0 2rem;
  padding: 0 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 0 2rem 0 3rem;
  }
`;

const ImageWrapper = styled.div<any>`
  position: relative;
  width: 100%;
  max-width: 7.8rem;
  height: 12rem;
  background: ${({ theme }) => theme.color.greyLight};
  cursor: ${({ productUrl }) => productUrl ? 'pointer' : 'default'};

  img {
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  margin-left: 1.4rem;
  padding-right: 3rem;
`;

const Title = styled.div<any>`
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.font.heading};
  cursor: ${({ productUrl }) => productUrl ? 'pointer' : 'default'};
  font-size: 1.8rem;
  font-weight: 300;
`;

const SelectedOptions = styled.div`
  margin: 0 0 1rem;
  color: ${({ theme }) => theme.color.grey};
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

const PriceWrapper = styled.div`
  margin: 0 0 1rem;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.4rem;
`;

const BoldSpan = styled.span`
  font-weight: 500;
`;

const StyledDropdown = styled(Dropdown)`
  width: 6.8rem;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0;
`;
