import { CHECKOUT_QUERY } from "@lib/fragments"

export const CHECKOUT_CREATE_MUTATION = (country: string) => `
  mutation checkoutCreate($input: CheckoutCreateInput!) @inContext(country: ${country}) {
    checkoutCreate(input: $input) {
      checkout {
        ${CHECKOUT_QUERY}
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`
