import { FC } from "react"

import {
  Hero,
  ValueProposition,
  PageSection,
  TextSection,
  ContentSection,
  ProductsSection,
  BannerSection,
} from "@components/page"

interface Props {
  entry: {
    __typename: string
    sys: {
      id: string
    }
  }
}

type MapEntryToComponent = {
  [key: string]: FC<any>
}

const components: MapEntryToComponent = {
  HeroSection: Hero,
  ValuePropositionSection: ValueProposition,
  PageSection,
  TextSection,
  ContentSection,
  ProductsSection,
  BannerSection,
}

export const DynamicComponent: FC<Props> = ({ entry }) => {
  if (typeof components[entry.__typename] !== "undefined") {
    const Component = components[entry.__typename]

    return <Component data={entry} />
  }

  return <div>{`Can't find component: ${entry.__typename}`}</div>
}
