import { FC, useState, useEffect } from "react"
import styled from "styled-components"

export const Headroom: FC = ({ children }) => {
  const [scrollingDown, setScrollingDown] = useState(false)

  useEffect(() => {
    // threshold === navbar height
    const threshold = 60
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }

      setScrollingDown(scrollY > lastScrollY ? true : false)
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollingDown])

  return <StyledHeadRoom $hide={scrollingDown}>{children}</StyledHeadRoom>
}

const StyledHeadRoom = styled.div<{ $hide: boolean }>`
  z-index: ${({ theme }) => theme.zIndex.navbar};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transform: ${({ $hide }) => ($hide ? "translateY(-100%)" : "translateY(0)")};
  transition: transform 0.3s ease-out;
`
