import { useState, useEffect } from "react"
import { getProductRecommendations } from "@lib/shopify"
import { getProductsByEntryTitles } from "@lib/product"
import type { Product } from "shopify-storefront-api-typings"
import { ContentfulProduct } from "@lib/types"

export const useProductRecommendations = (productId: string) => {
  const [state, setState] = useState<any>({
    productRecommendations: null,
    error: "",
    loading: false,
  })

  const fetchRecommendations = async (productId: string) => {
    const productRecommendations = await getProductRecommendations(productId)

    const titles = productRecommendations.map(
      (product: Product) => product.title
    )

    const contentfulProducts = await getProductsByEntryTitles(titles)

    const products = productRecommendations
      .map((shopifyProduct: Product) => ({
        ...shopifyProduct,
        ...contentfulProducts.find(
          (contentfulProduct: Partial<ContentfulProduct>) =>
            contentfulProduct.entryTitle === shopifyProduct.title
        ),
      }))
      .filter((product) => !!product.slug)

    setState({
      productRecommendations: products,
      error: "",
      loading: false,
    })
  }

  useEffect(() => {
    if (!productId) {
      setState({
        productRecommendations: null,
        error: "Please provide a valid storefront product id",
        loading: false,
      })
      return
    }
    fetchRecommendations(productId)
  }, [productId])

  return state
}
