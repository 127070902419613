import styled from "styled-components"

export const Message = styled.p<{ $error?: boolean; $success?: boolean }>`
  margin: 1rem 0 !important;
  font-family: ${({ theme }) => theme.font.primary};
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme, $error, $success }) =>
    $success ? theme.color.success : $error ? theme.color.error : null};
`
