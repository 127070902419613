import { FC, useState } from "react"
import { MenuItem } from "@lib/types"

import { AccordionItem } from "./AccordionItem"

interface Props {
  items: MenuItem[]
  activeIndex?: number
  trackingProperties?: {}
}

export const Accordion: FC<Props> = ({
  items,
  activeIndex = -1,
  trackingProperties,
}) => {
  const [isActive, setIsActive] = useState(activeIndex)

  return (
    <div>
      {items.map(({ category, linksCollection }, i) => {
        return (
          <AccordionItem
            key={category}
            label={category}
            items={linksCollection.items}
            isActive={isActive === i}
            onClick={() => (isActive === i ? setIsActive(-1) : setIsActive(i))}
            trackingProperties={trackingProperties}
          />
        )
      })}
    </div>
  )
}
