export const routes = [
  {
    title: "Order history",
    href: "account",
  },
  {
    title: "Account information",
    href: "account/account-information",
  },
  {
    title: "Address book",
    href: "account/address-book",
  },
  {
    title: "Rewards",
    href: "account/rewards",
  },
  {
    title: "Wishlist",
    href: "account/wishlist",
  },
  {
    title: "Virtual Closet",
    href: "account/virtual-closet",
  },
]
