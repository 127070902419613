import { FC } from "react"
import styled from "styled-components"
import { useState } from "react"
import { useGeoIp, useTracking } from "@hooks/index"
import ArrowRight from "@svg/arrow-right.svg"
import { useRouter } from "next/router"

const Layout = styled.div`
  margin: 5rem 0 4rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  max-width: 55rem;
  width: 100%;
`

const Message = styled.div`
  color: #000;
  font-weight: 300;
  font-style: normal;
  font-family: Nobel;
  font-size: 2.2rem;
  text-align: center;
  padding: 0rem 1.5rem;
  line-height: 120%;
  letter-spacing: -1px;

  @media (min-width: ${({ theme }) => theme.breakpoint.sm}px) {
    font-size: 3rem;
  }
`

const Question = styled.div`
  font-family: Nobel;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  font-family: Nobel;
  font-weight: 300;
  font-size: 2rem;
  line-height: 1.875rem;
  color: #000000;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    font-size: 2rem;
  }
`

const RadioFieldsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
`

const RadioFieldWrap = styled.label`
    display: grid;
    grid-template-columns: min-content auto;
    grid-gap: 0.5em;
    font-size: 2.25rem;

    &:checked + .radio__control {
      background: radial-gradient(black 50%, rgba(255, 0, 0, 0) 51%);
    }
  }
`

interface RadioInputControlProps {
  checked: boolean
}

const RadioInputControl = styled.span<RadioInputControlProps>`
    display: block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    border: 0.1em solid ${({ checked }) => (checked ? "black" : "#DDDDDD")};
    
    background: ${({ checked }) =>
      checked ? "radial-gradient(black 50%, rgba(255, 0, 0, 0) 51%);" : ""}

    :focus {
        box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em black;
    }

`

const RadioInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`

const RadioInputWrap = styled.div`
  display: flex;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

const RadioLabelWrap = styled.span`
  font-size: 0.875rem;
  color: #000;
  margin-top: 0.25rem;
  font-family: Gotham;
  line-height: 1.8rem;
  transition: 180ms all ease-in-out;
  opacity: 0.8;
  font-size: 1.2rem;

  :focus-within {
    transform: scale(1.05);
    opacity: 1;
  }
`
const EmailFieldWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
  height: 4.5rem;
  font-size: 2rem;
  width: 33rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 42rem;
  }
`

const EmailInput = styled.input`
  color: #000;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  --border-opacity: 1;
  font-family: Gotham;
  min-width: min(300px, 60vw);
  border-width: 1px;
  padding: 0.5rem 0.75rem;
  font-size: 1.5rem;
  border-style: solid;
  height: 4.5rem;
`
const SubscribeButton = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: flex;
    width: 13rem;
    height: 4.5rem;
    position: relative;
    color: white;
    font-size: 1.5rem;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 1);
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    cursor: pointer;
    font-family: Gotham;
    justify-content: center;
    align-items: center;
  }
`

const SubscribeMobileButton = styled.div`
  display: flex;
  width: 4.5rem;
  height: 4.5rem;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  background-color: rgba(0, 0, 0, 1);
  color: white;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    display: none;
  }
`
const Arrow = styled(ArrowRight)``

const PrivacyPolicy = styled.div`
  font-family: Gotham;
  font-size: 0.8125rem;
  line-height: 1.875rem;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  margin-top: 0.75rem;

  a {
    margin-left: 2px;
    text-decoration: underline;
    cursor: pointer;
  }
`

const ErrorMessage = styled.div`
  height: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
  color: red;
`

export const Subscribe: FC = () => {
  const [selected_option, select] = useState("")
  const [email, setEmail] = useState("")
  const [error, setError] = useState("");
  const { track, tikTokTrack } = useTracking()

  const submitForm = (e: any) => {
    if(selected_option == "") {
      e.preventDefault();
      setError("Please select an option");
    }
  }

  const geoip = useGeoIp()
  const router = useRouter()
  return (
    <Layout>
      <Content>
        <Message>
          Subscribe to be the first to know about new product releases, styling
          ideas and more.
        </Message>
        <Question>What products are you interested in?</Question>
        <form
          id="blog-drip-form"
          action="https://www.getdrip.com/forms/853016518/submissions"
          method="post"
          data-drip-embedded-form="853016518"
          data-form-type="subscribe-form"
        >
          <input
            aria-hidden
            type="hidden"
            name="fields[preference]"
            value={String(selected_option).toLowerCase()}
          />
          <input type="hidden" name="fields[country]" value={geoip.country_code} />
          <input type="hidden" name="fields[locale]" value={router.locale} />
          <RadioFieldsWrap>
            {["Prints", "Solids", "Both"].map((choice, i) => {
              return (
                <RadioFieldWrap key={i} onClick={() => select(choice)}>
                  <RadioInputWrap>
                    <RadioInput
                      onChange={() => true}
                      type="radio"
                      name="radio"
                      checked={selected_option === choice}
                    />
                    <RadioInputControl checked={selected_option === choice} />
                  </RadioInputWrap>
                  <RadioLabelWrap>{choice}</RadioLabelWrap>
                </RadioFieldWrap>
              )
            })}
          </RadioFieldsWrap>
        <ErrorMessage>{error}</ErrorMessage>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <EmailFieldWrap>
              <EmailInput
                required={true}
                name="fields[email]"
                placeholder="Email Address"
                onChange={(event: any) => setEmail(event.target.value)}
              />
              <button>
                <SubscribeButton
                  aria-label={"Subscribe"}
                  onClick={(e) => {
                    track("Email Subscribed", {
                      email,
                      location: "Footer",
                    });
                    tikTokTrack("SubmitForm", {
                      content_category: "Footer Newsletter Subscribed",
                      content_id: email
                    })
                    submitForm(e);
                  }}
                >
                  Sign Me Up
                </SubscribeButton>
                <SubscribeMobileButton>
                  <Arrow />
                </SubscribeMobileButton>
              </button>
            </EmailFieldWrap>
          </div>
        </form>
        <PrivacyPolicy>
          Your email address is safe with us.
          <a
            href="https://www.hautehijab.com/pages/privacy-policy"
            target="_blank"
            onClick={() =>
              track("Link Clicked", {
                url: "https://www.hautehijab.com/pages/privacy-policy",
                label: "Privacy Policy",
              })
            }
          >
            Privacy Policy
          </a>
        </PrivacyPolicy>
      </Content>
    </Layout>
  )
}
