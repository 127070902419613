import { FC, useState } from 'react';
import styled from 'styled-components';

import { Input, FieldContainer, SubmitButton, Message } from '@components/ui';
import { useForm } from 'react-hook-form';
import { useCustomer } from '@hooks/index';
import { Title } from '../Title';

export const AccountForm: FC = () => {
  const { updateCustomer, loading } = useCustomer();
  const { register, handleSubmit, errors, clearErrors, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const { isDirty } = formState;

  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');

  const onSubmit = async (data: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
  }) => {
    const { firstName, lastName, email } = data;

    setSuccess('');
    setError('');

    const res = await updateCustomer({
      firstName,
      lastName,
      email,
    });

    if (res?.error) {
      setError(res.error);

      return;
    }

    setSuccess('Your details have succesfully been updated');
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <Title $marginBottom>Account information</Title>
      <FieldContainer>
        <Input
          name="firstName"
          ref={register({ required: 'First name is required' })}
          placeholder="First name"
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
        <Input
          name="lastName"
          ref={register({ required: 'Last name is required' })}
          placeholder="Last name"
          type="text"
          errors={errors}
          clearErrors={clearErrors}
        />
      </FieldContainer>
      <Input
        name="email"
        ref={register({
          required: 'Email is required',
          pattern: {
            value: /^[^\s@]+@([^\s@.,]+\.)+[^\s@.,]{2,}$/,
            message: 'Please enter a valid email',
          },
        })}
        placeholder="Email address"
        type="email"
        errors={errors}
        clearErrors={clearErrors}
      />
      <ButtonWrapper>
        <SubmitButton title="Save" loading={loading} disabled={!isDirty} />
      </ButtonWrapper>
      <MessageWrapper>
        {error && <Message $error>{error}</Message>}
        {success && <Message $success>{success}</Message>}
      </MessageWrapper>
    </StyledForm>
  );
};

const StyledForm = styled.form`
  width: 100%;
  margin: 0 0 5rem;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0 0 2rem;
  }
`;

const ButtonWrapper = styled.div`
  max-width: 22rem;
  margin: 0 auto;
`;

const MessageWrapper = styled.div`
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
`;
