import { FC, useState, useEffect } from "react"
import styled from "styled-components"
import { useRouter } from "next/router"
import { LazyMotion, m } from "framer-motion"
import { setChosenLocaleCookie } from '@lib/utils'

const loadFeatures = () => import("@lib/framer-motion").then(res => res.default)

import {
  useUI,
  useSite,
  useDictionary,
  useCustomer,
  useCheckout,
} from "@hooks/index"
import { Portal } from "@components/common"
import { Button, ImageDropdown } from "@components/ui"
import Cross from "@svg/cross.svg"

interface Props {}

export const CountryModal: FC<Props> = () => {
  const dictionary = useDictionary()
  const { displayCountryModal, closeCountryModal } = useUI()
  const router = useRouter()
  const { setCurrency, currency } = useCustomer()
  const { setNewCurrencyCheckout } = useCheckout()
  const [loading, setLoading] = useState(false)

  const {
    countriesCollection: { items: countries },
    currenciesCollection: { items: currencies },
  } = useSite()

  const localeCurrencies =
    router?.locale === "en-GB"
      ? currencies.filter(({ value }: { value: string }) => value === "GBP")
      : currencies.filter(({ value }: { value: string }) => value !== "GBP")

  const usCurrency = currencies.find(
    ({ value }: { value: string }) => value === "USD"
  )
  const gbCurrency = currencies.find(
    ({ value }: { value: string }) => value === "GBP"
  )

  const initialCountry = countries.filter(
    ({ value }: { value: string }) => value === router.locale
  )[0]

  // GBP is only available on UK if GBP is saved in user preference and user switches to US store then fallback to USD
  const initialCurrency =
    router?.locale === "en-GB"
      ? gbCurrency
      : currency === "GBP"
      ? usCurrency
      : currencies.find(({ value }: { value: string }) => value === currency)

  const [currencyList, setCurrencyList] = useState(localeCurrencies)
  const [selectedCountry, setSelectedCountry] = useState(initialCountry)
  const [selectedCurrency, setSelectedCurrency] = useState(initialCurrency)

  useEffect(() => {
    setSelectedCountry(initialCountry)
    setSelectedCurrency(initialCurrency)
    setCurrency(initialCurrency.value)
  }, [])

  useEffect(() => {
    const currenciesByCountry =
      selectedCountry.value === "en-GB"
        ? currencies.filter(({ value }: { value: string }) => value === "GBP")
        : currencies.filter(({ value }: { value: string }) => value !== "GBP")

    setCurrencyList(currenciesByCountry)
  }, [selectedCountry])

  const onClose = () => {
    setSelectedCountry(initialCountry)
    setSelectedCurrency(initialCurrency)
    closeCountryModal()
  }

  const handleCountry = (e: any) => {
    const country = countries.filter(
      ({ value }: { value: string }) => value === e.value
    )[0]

    e.value === "en-GB"
      ? setSelectedCurrency(gbCurrency)
      : setSelectedCurrency(usCurrency)
    setSelectedCountry(country)
  }

  const handleCurrency = (e: any) => {
    const cur = currencies.filter(
      ({ value }: { value: string }) => value === e.value
    )[0]

    setSelectedCurrency(cur)
  }

  const onSubmit = async () => {
    setLoading(true)
    // UK store only has 1 currency, we do not setNewCurrencyCheckout if they are changing to en-GB
    // Only setNewCurrencyCheckout if user is actually changing the currency
    // In case the currency doesn't have a countryCode filled in on Contentful, we default to "US"
    selectedCountry.value !== "en-GB" && initialCurrency.value !== selectedCurrency.value && await setNewCurrencyCheckout(selectedCurrency.countryCode ? selectedCurrency.countryCode : "US")
    setChosenLocaleCookie(selectedCountry.value)
    await router.push(router.asPath, "", { locale: selectedCountry.value })
    setCurrency(selectedCurrency.value)
    setLoading(false)
    closeCountryModal()
  }

  return (
    <Portal open={displayCountryModal} onClose={onClose}>
      <LazyMotion strict features={loadFeatures}>
        <Modal
          initial={{ opacity: 0.5 }}
          animate={{
            opacity: 1,
          }}
          exit={{ opacity: 0 }}
          role="dialog"
          aria-modal="true"
        >
          <Header>
            <Title>{dictionary.preferences}</Title>
            <CloseButton onClick={closeCountryModal}>
              <CloseIcon />
            </CloseButton>
          </Header>
          <Dropdowns>
            <DropdownContainer>
              <DropdownTitle>{dictionary.shopIn}</DropdownTitle>
              <ImageDropdown
                id="country-dropdown"
                options={countries}
                value={selectedCountry}
                defaultValue={selectedCountry}
                onChange={(e: any) => handleCountry(e)}
              />
            </DropdownContainer>
            <DropdownContainer>
              <DropdownTitle>{dictionary.currency}</DropdownTitle>
              <ImageDropdown
                id="currency-dropdown"
                options={currencyList}
                value={selectedCurrency}
                defaultValue={selectedCurrency}
                onChange={(e: any) => handleCurrency(e)}
              />
            </DropdownContainer>
          </Dropdowns>
          <Footer>
            <ButtonWrapper>
              <StyledButton
                buttonTheme="ghostBlackReverse"
                title={dictionary.updatePreferences}
                onClick={onSubmit}
                loading={loading}
              />
            </ButtonWrapper>
            <p>{dictionary.weOfferWorldwideShipping}</p>
          </Footer>
        </Modal>
      </LazyMotion>
    </Portal>
  )
}

const Modal = styled(m.div)`
  position: relative;
  z-index: 10000;
  background: ${({ theme }) => theme.color.white};
  padding: 2rem;
  width: 100%;
  margin: 0 2rem;
  max-width: 54rem;
  border-radius: 5px;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    margin: 0;
    padding: 3rem 4rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 2rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyLight};
`

const Title = styled.h1`
  margin: 0;
  font-weight: 300;
  font-size: 3rem;
  line-height: 4rem;
`

const CloseButton = styled.button`
  z-index: 1;
  top: 2.2rem;
  right: 2.2rem;

  &:hover {
    cursor: pointer;
  }
`

const Dropdowns = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 2rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyLight};

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 2rem 0 3rem;

    grid-template-columns: 1fr 1fr;
  }
`

const DropdownContainer = styled.div``

const DropdownTitle = styled.h2`
  margin: 0 0 1rem;
  font-weight: 300;
  font-size: 2rem;
  line-height: 4rem;
`

const CloseIcon = styled(Cross)`
  path {
    stroke: ${({ theme }) => theme.color.grey};
  }
`

const Footer = styled.div`
  padding: 2rem 0 0;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-align: center;
  color: ${({ theme }) => theme.color.grey};
`

const StyledButton = styled(Button)`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: auto;
  }
`

const ButtonWrapper = styled.div`
  margin: 0 0 1.8rem;
`
